import { createContext, mergeProps, splitProps, useContext } from 'solid-js';
import type { JSX, ParentProps } from 'solid-js';

const defaults: Partial<JSX.HTMLAttributes<HTMLElement>> = {
	tabindex: '0',
};

const Focusable = createContext(defaults);

export function FocusableProvider(inputProps: ParentProps & JSX.HTMLAttributes<HTMLElement>) {
	const merged = mergeProps(defaults, inputProps);
	const [props, context] = splitProps(merged, ['children']);

	return <Focusable.Provider value={context}>{props.children}</Focusable.Provider>;
}

export function useFocusable<P>() {
	return useContext(Focusable) as P;
}
