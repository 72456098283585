import { Button, Input, Label, Link, Separator, TextField } from '@troon/ui';
import { Show, createEffect, createMemo, createSignal } from 'solid-js';
import { Icon } from '@troon/icons';
import { isServer } from 'solid-js/web';
import { useTrackEvent } from '@troon/analytics';
import { useUser } from '../../providers/user';
import { UpsellAccessSection } from './access';
import type { ComponentProps, ParentProps } from 'solid-js';

type Props = ParentProps & ComponentProps<typeof UpsellAccessSection>;

export function AccessReferAFriend(props: Props) {
	const user = useUser();
	const [copied, setCopied] = createSignal(false);
	const trackEvent = useTrackEvent();

	let timeout: NodeJS.Timeout;
	createEffect(() => {
		if (copied()) {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				setCopied(false);
			}, 2500);
		}
	});

	const shareData = createMemo<ShareData>(() => ({
		title: 'Get $25 off Troon Access!',
		text: 'Get $25 off when you sign up for Troon Access!',
		url: `https://troon.com/access?promoCode=${user()?.me.referralCode}`,
	}));

	const canShare = createMemo(() => {
		return (
			isServer ||
			(typeof window !== 'undefined' &&
				typeof window.navigator.share === 'function' &&
				window.navigator.canShare(shareData()))
		);
	});

	return (
		<UpsellAccessSection
			product="troonAccessReferral"
			location={props.location}
			withLogo={props.withLogo}
			withPhoto={props.withPhoto}
			layout={props.layout}
		>
			{props.children}

			<div class="flex gap-4">
				<TextField name="code" class="grow">
					<Label class="sr-only">Your unique GolfPass+ redemption code</Label>
					<Input readonly value={shareData().url} />
				</TextField>
				<Button
					onClick={() => {
						window.navigator.clipboard.writeText(shareData().url!);
						setCopied(true);
					}}
					class="size-fit shrink-0 grow-0 overflow-hidden"
				>
					<Show when={copied()} fallback={null}>
						<span class="absolute flex items-center gap-2">
							<Icon name="check" />
							Copied
						</span>
					</Show>
					<span class="flex items-center gap-2" classList={{ 'opacity-0': copied() }}>
						<Icon name="copy" />
						Copy link
					</span>
				</Button>
			</div>
			<Show when={copied()}>
				<p aria-live="polite" class="sr-only">
					Link has been copied to your clipboard!
				</p>
			</Show>

			<Separator>or share via</Separator>

			<ul class="flex gap-3">
				<Show when={canShare()}>
					<li class="grow">
						<Button
							appearance="secondary-current"
							class="w-full grow"
							onClick={() => {
								navigator.share(shareData()).then(() => {
									trackEvent('shareAccessReferral', { shareType: 'native' });
								});
							}}
						>
							<Icon name="share" title="share" />
						</Button>
					</li>
				</Show>
				<li class="grow">
					<Button
						appearance="secondary-current"
						class="w-full grow"
						as={Link}
						href={`mailto:?subject=${shareData().title}&body=${shareData().text}!%0A%0A${shareData().url}`}
						onClick={() => {
							trackEvent('shareAccessReferral', { shareType: 'email' });
						}}
					>
						<Icon name="mail-open" title="Email" />
					</Button>
				</li>
				<li class="grow">
					<Button
						appearance="secondary-current"
						class="w-full grow"
						as={Link}
						href={`https://www.facebook.com/sharer/sharer.php?u=${shareData().url}`}
						onClick={() => {
							trackEvent('shareAccessReferral', { shareType: 'facebook' });
						}}
					>
						<Icon name="social-fb" title="Facebook" />
					</Button>
				</li>
				<li class="grow">
					<Button
						appearance="secondary-current"
						class="w-full grow"
						as={Link}
						href={`https://twitter.com/share?text=${shareData().text}!%0A%0A${shareData().url}`}
						onClick={() => {
							trackEvent('shareAccessReferral', { shareType: 'twitter' });
						}}
					>
						<Icon name="social-twitter" title="Twitter / X" />
					</Button>
				</li>
			</ul>
		</UpsellAccessSection>
	);
}
