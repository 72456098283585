import { onMount } from 'solid-js';
import { produce, unwrap } from 'solid-js/store';
import { usePersisted } from '../../providers/persistence-store';
import type { RouteSectionProps } from '@solidjs/router';

export default function Associates(props: RouteSectionProps) {
	const [, setPersisted] = usePersisted();
	onMount(() => {
		setPersisted(
			produce((s) => {
				for (const key of Object.keys(unwrap(s))) {
					delete s[key];
				}
			}),
		);
	});
	return <>{props.children}</>;
}

export const route = { info: { nav: { appearance: 'minimal' }, banner: { hide: true } } };
