import { Heading } from '@troon/ui';
import { Video } from '@troon/video';
import { NoHydration } from 'solid-js/web';
import { getConfigValue } from '../../../modules/config';
import type { RouteDefinition } from './_types';

export default function MunicipalServicesPage() {
	return (
		<>
			<NoHydration>
				<Heading as="h2">Finding the Ideal Structure for your Municipal Golf Operation</Heading>

				<ul class="flex list-disc flex-col gap-2 ps-8">
					<li>
						<strong class="font-semibold">Full or Hybrid Management Services:</strong> Based on your need, we can manage
						all, or parts, of your operations under a management fee format.
					</li>
					<li>
						<strong class="font-semibold">Outsourced Maintenance Solutions:</strong> We can evaluate your courses, and
						budget, and provide a fixed-fee maintenance solution effectively removing the city form all maintenance
						concerns and liability.
					</li>
					<li>
						<strong class="font-semibold">Fixed-Price Operations:</strong> We can also provide a fixed fee for all
						departments of your operation, limiting your risk exposure with all revenues going to the city.
					</li>
					<li>
						<strong class="font-semibold">Reimbursed Funding Capability:</strong> We can provide a one-month advance
						funding of all operations with city reimbursement, reducing liability to the city and allowing your
						operation to fully maximize our buying power.
					</li>
					<li>
						<strong class="font-semibold">Lease Alternatives:</strong> In some situations, we can lease the F&B
						operation or other aspects of your property.
					</li>
				</ul>
			</NoHydration>

			<Video
				src={`${getConfigValue('CDN')}/digital/video/management-services/municipal/index.m3u8`}
				poster={`${getConfigValue('IMAGE_HOST')}/digital/video/management-services/municipal/poster.jpg`}
			/>
		</>
	);
}

export const route = {
	info: {
		nav: { hero: true },
		hero: `${getConfigValue('IMAGE_HOST')}/digital/hero/municipal.jpg`,
		title: 'Municipal Properties',
		description: 'Improve your course conditions and reduce your costs.',
	},
} satisfies RouteDefinition;
