import { Show, createMemo, createSignal } from 'solid-js';
import { ActivityIndicator, Link } from '@troon/ui';
import { useTrackEvent } from '@troon/analytics';
import { useIsRouting } from '@solidjs/router';
import { Icon } from '@troon/icons';
import dayjs from '@troon/dayjs';
import { createNumberFormatter, holesFormatter } from '../modules/number-formatting';
import { dayTimeToDate, formatDateTimeAttribute } from '../modules/date-formatting';
import { createFragment } from '../graphql/create-fragment';
import { gql } from '../graphql';
import type { FragmentType } from '../graphql';

type Props = {
	players?: number;
	teeTime: FragmentType<typeof TeeTimeFragment>;
	showCourse?: boolean;
};

export function TeeTime(props: Props) {
	const trackEvent = useTrackEvent();
	const [clicked, setClicked] = createSignal(false);
	const isRouting = useIsRouting();
	const teeTime = createFragment(TeeTimeFragment, props, 'teeTime');

	const numberFormatter = createNumberFormatter();

	const dayTimeTime = createMemo(() => {
		const date = dayTimeToDate(teeTime.dayTime);
		const dayPeriod = dayjs(date).format('A');
		const time = dayjs(date).format('h:mm');
		return { dayPeriod, time };
	});

	const feeString = createMemo(() => {
		const currency = teeTime.minPrice.code ?? 'USD';
		const dollarFormatter = new Intl.NumberFormat('en', {
			style: 'currency',
			currency,
			currencyDisplay: 'narrowSymbol',
			trailingZeroDisplay: 'stripIfInteger',
		});

		if (teeTime.minPrice.value !== teeTime.maxPrice.value) {
			return dollarFormatter.formatRange(teeTime.minPrice.value, teeTime.maxPrice.value);
		}
		return dollarFormatter.format(teeTime.minPrice.value);
	});

	return (
		<div class="group relative p-4 transition-colors duration-200 hover:bg-neutral-100 md:p-6">
			<div class="mb-2 flex flex-row justify-between gap-8">
				<Link
					preload={false}
					class="after:absolute after:inset-0 after:z-0"
					href={`/course/${teeTime.course.facility?.slug ?? ''}/reserve-tee-time/${teeTime.id}?players=${props.players}`}
					rel="nofollow"
					onClick={() => {
						setClicked(true);
						trackEvent('didSelectTeeTime', {
							courseId: teeTime.courseId,
							facilitySlug: teeTime.course.facility?.slug,
							...teeTime.dayTime.day,
							...teeTime.dayTime.time,
						});
					}}
				>
					<span class="sr-only">Reserve</span>
					<time datetime={formatDateTimeAttribute(dayTimeToDate(teeTime.dayTime))}>
						<span class="text-2xl font-semibold">{dayTimeTime().time}</span>
						<span class="text-sm">{dayTimeTime().dayPeriod}</span>
					</time>
					<span class="sr-only">
						For{' '}
						{teeTime.minPlayers !== teeTime.maxPlayers
							? numberFormatter().formatRange(teeTime.minPlayers, teeTime.maxPlayers)
							: numberFormatter().format(teeTime.minPlayers)}{' '}
						golfers on {holesFormatter(numberFormatter(), teeTime.holesOption)} holes.
					</span>
				</Link>

				<div class="flex flex-row gap-4 font-semibold">
					<Show when={clicked() && isRouting()}>
						<ActivityIndicator class="text-neutral-600" />
					</Show>
					{feeString()}
				</div>
			</div>

			<div class="flex flex-row flex-wrap justify-between gap-x-8 gap-y-1">
				<Show when={props.showCourse}>
					<div class="grow">{teeTime.course.name}</div>
				</Show>
				<ul class="flex shrink flex-row gap-4 text-neutral-800">
					<li class="inline-flex items-center gap-x-1">
						<Icon name="users" />
						{teeTime.minPlayers !== teeTime.maxPlayers
							? numberFormatter().formatRange(teeTime.minPlayers, teeTime.maxPlayers)
							: numberFormatter().format(teeTime.minPlayers)}{' '}
						<span class="sr-only">golfer(s)</span>
					</li>
					<li class="inline-flex items-center gap-x-1">
						<Icon name="flag" />
						<span>{holesFormatter(numberFormatter(), teeTime.holesOption)} holes</span>
					</li>
					{teeTime.cartIncluded ? (
						<li class="inline-flex items-center gap-x-1">
							<Icon name="golf-cart" title="Cart" /> Included
						</li>
					) : null}
				</ul>
			</div>
		</div>
	);
}

export const TeeTimeFragment = gql(`fragment TeeTime on CourseTeeTime {
	id
	courseId
	course {
		name
		facility { slug }
		requiresCCAtBooking
	}
	dayTime { day { year, month, day }, time { hour, minute } }
	minPrice { code, value }
	maxPrice { code, value }
	minPlayers
	maxPlayers
	holesOption
	cartIncluded
}`);
