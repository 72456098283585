import { Show } from 'solid-js';
import { useUser } from '../../providers/user';
import { LoggedIn } from './_variants/logged-in';
import { LoggedOut } from './_variants/logged-out';
import type { RouteDefinition } from '@solidjs/router';

export default function Home() {
	const user = useUser();

	return (
		<Show when={user() !== null} fallback={<LoggedOut />}>
			<LoggedIn />
		</Show>
	);
}
export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
