import { gql } from '../graphql/__generated__';

export const loggedInUserQuery = gql(`query loggedInUser {
  me {
		id
    email
    firstName
    lastName
    referralCode
    troonRewardsId
    troonAccessProductType
    card: oldTroonCardGroup {
      id
      name
    }
    role
    zipcode
    phoneNumber
  }
  userRewards {
    availablePoints
    level
  }
  activeTroonCardSubscription {
    status
    name: subscriptionName
  }
}`);
