import { Dialog, DialogAction, DialogActions, DialogContent, Link } from '@troon/ui';
import { createSignal } from 'solid-js';
import dayjs from '@troon/dayjs';
import { usePersisted } from '../../../../../../providers/persistence-store';
import { useUser } from '../../../../../../providers/user';

export function AccessUpsellTakeover() {
	const [persisted, setPersisted] = usePersisted();
	const [showDialog, setShowDialog] = createSignal(true);
	const user = useUser();

	return (
		<Dialog
			open={showDialog() && !persisted.accessBookingTakeover}
			onOpenChange={(open) => {
				if (!open) {
					setPersisted('accessBookingTakeover', { value: '1', expires: dayjs().add(10, 'days').valueOf() });
				}
				setShowDialog(open);
			}}
			key="card-expired-booking-takeover"
		>
			<DialogContent header="Your Troon Card has Expired" headerLevel="h2">
				<div class="mb-8 flex flex-col gap-4">
					<p>
						Your <b>{user()?.me.card?.name}</b> benefits have expired. To continue saving on tee times at our 150+
						participating courses, sign up for Troon Access.
					</p>
				</div>
				<DialogActions>
					<DialogAction appearance="primary" as={Link} href="/access">
						Buy now & save
					</DialogAction>
					<DialogAction
						appearance="transparent"
						onClick={() => {
							setShowDialog(false);
							setPersisted('accessBookingTakeover', { value: '1', expires: dayjs().add(10, 'days').valueOf() });
						}}
					>
						Maybe Later
					</DialogAction>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
}
