import { Dialog, TroonAccess, DialogTrigger } from '@troon/ui';
import { createSignal, onMount } from 'solid-js';
import { useTrackEvent } from '@troon/analytics';
import { useLocation } from '@solidjs/router';
import { AccessProductDialogContent } from '../../../../../../components/upsells/dialog-content';

type Props = {
	location?: string;
	redirect?: string;
};

export function TeeTimeAccessUpsell(props: Props) {
	const [cardUpsellOpen, setCardUpsellOpen] = createSignal(false);
	const trackEvent = useTrackEvent();
	const location = useLocation();

	onMount(() => {
		trackEvent('upsellVisible', { location: props.location ?? 'course tee time list', productType: 'troonAccess' });
	});

	return (
		<div class="relative flex flex-wrap items-center justify-between gap-4 bg-gradient-to-r from-neutral-950 to-brand-700 p-6 text-white md:flex-nowrap md:gap-12">
			<div
				aria-hidden
				class="absolute inset-0 z-0 cursor-pointer"
				onClick={() => {
					setCardUpsellOpen(true);
					trackEvent('upsellClicked', {
						location: props.location ?? 'course tee time list',
						productType: 'troonAccess',
					});
				}}
			/>
			<div class="flex shrink flex-col items-start gap-4">
				<TroonAccess class="w-52 md:w-64" />
				<p>Save up to 50% on tee times at 150+ courses with the all-new Troon Access program.</p>
			</div>
			<Dialog
				key="access-products"
				open={cardUpsellOpen()}
				onOpenChange={(open) => {
					setCardUpsellOpen(open);
					if (open) {
						trackEvent('upsellClicked', {
							location: props.location ?? 'course tee time list',
							productType: 'troonAccess',
						});
					}
				}}
			>
				<DialogTrigger appearance="primary" class="size-fit shrink grow-0 text-nowrap">
					Buy now
				</DialogTrigger>
				<AccessProductDialogContent
					onCancel={() => setCardUpsellOpen(false)}
					redirect={props.redirect ?? `${location.pathname}${location.search}`}
				/>
			</Dialog>
		</div>
	);
}
