import { useLocation } from '@solidjs/router';
import {
	BreadcrumbItem,
	Breadcrumbs,
	Button,
	Container,
	Heading,
	HorizontalRule,
	Link,
	Page,
	Picture,
	Section,
	Tag,
} from '@troon/ui';
import { Show, For } from 'solid-js';
import { Icon } from '@troon/icons';
import { Meta, Title } from '@solidjs/meta';
import { useUser } from '../../providers/user';
import { PictureSection } from '../../components/layouts/picture-section';
import { FrequentlyAskedQuestions } from '../../components/faqs';

export default function GuestPassesPage() {
	const user = useUser();
	const loc = useLocation();

	return (
		<Container class="pt-4">
			<Breadcrumbs>
				<BreadcrumbItem href="/access">Troon Access</BreadcrumbItem>
				<BreadcrumbItem href={loc.pathname}>Guest Passes</BreadcrumbItem>
			</Breadcrumbs>
			<Title>Guest Passes | Troon Access | Troon</Title>
			<Meta
				name="description"
				content="Guest Passes allow your playing partners to play at the same discounted rate as you for a given round."
			/>
			<Page>
				<PictureSection src={imageUrl} mode="contain">
					<Tag>New</Tag>
					<Heading as="h1">Guest Passes</Heading>
					<p>
						Guest Passes allow your playing partners to play at the same discounted rate as you for a given round. All
						Troon Access members receive <b>3 Guest Passes</b> per/year with no restrictions on when they can be used.
					</p>
					<Show when={!user()?.me.troonAccessProductType}>
						<p>
							<Button as={Link} href="/access" class="size-fit">
								Join Troon Access <Icon name="arrow-right-md" />
							</Button>
						</p>
					</Show>
				</PictureSection>

				<HorizontalRule />

				<Section class="text-center">
					<Heading as="h2">How to share a Guest Pass</Heading>
					<ol class="grid grid-cols-1 gap-6 md:grid-cols-3">
						<For each={instructions}>
							{(instruction) => (
								<li class="flex flex-col items-center justify-start gap-3 rounded border border-neutral p-4 md:p-6">
									<Picture
										src={instruction.banner}
										alt=""
										width={544}
										height={306}
										sizes="(min-width: 768px) 33vw, 95vw"
										class="w-full"
									/>
									<Heading as="h3" size="h5">
										{instruction.title}
									</Heading>
									{instruction.content()}
								</li>
							)}
						</For>
					</ol>
				</Section>

				<HorizontalRule />

				<Section class="text-center">
					<Container size="small">
						<Heading as="h2">Frequently Asked Questions</Heading>
						<div class="overflow-hidden rounded border border-neutral text-start">
							<FrequentlyAskedQuestions id="guest-passes" />
						</div>
					</Container>
				</Section>
			</Page>
		</Container>
	);
}

const imageUrl =
	'https://images.ctfassets.net/rdsy7xf5c8dt/faJoHKXuvHokxO2SKrza2/22a74be3773f5a54cdd2f996283aa0a2/access-guest-pass.png';

const instructions = [
	{
		title: 'Book a Tee Time',
		banner:
			'https://images.ctfassets.net/rdsy7xf5c8dt/5WOKPqnqFpUlVkwiar3si8/67e1d0527818b5ff7a9d2e17166a8020/guest-pass-instructions-book-tee-time.png',
		content: () => <p>Book a tee time for more than one player on Troon.com or the Troon iOS app.</p>,
	},
	{
		title: 'Invite Players',
		banner:
			'https://images.ctfassets.net/rdsy7xf5c8dt/2X8CwKSfXhPd4gU0INXhGM/9f04896b01339983f5fd822d441e2ec7/guest-pass-instructions-invite-players.png',
		content: () => <p>Invite the other players in your group to join your tee time. </p>,
	},
	{
		title: 'Share Guest Pass',
		banner:
			'https://images.ctfassets.net/rdsy7xf5c8dt/6G7kko5AGJE10Cgo7WPKfg/ae5f77ec545b1b89a857aa253b1ba49d/guest-pass-instructions-share.png',
		content: () => <p>Once a player has joined your reservation you can share a Guest Pass.</p>,
	},
];
