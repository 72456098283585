import { Icon } from '@troon/icons';
import {
	Dialog,
	DialogTrigger,
	PillButton,
	DialogContent,
	CheckboxGroup,
	Label,
	DialogActions,
	DialogAction,
	Picture,
	HiddenCheckbox,
	ActivityIndicator,
} from '@troon/ui';
import { For, Show, createSignal } from 'solid-js';
import { action, useAction, useSubmission } from '@solidjs/router';
import { gql, mutationAction, useMutation } from '../graphql';
import { createFragment } from '../graphql/create-fragment';
import type { FragmentType, FavoriteFacilityFragment as FavoriteFacilityFragmentType } from '../graphql';

type Props = {
	facilities: Array<FragmentType<typeof FavoriteFacilityFragment>>;
	onFavorited?: () => void;
};

export function FavoritesDialog(props: Props) {
	const facilities = createFragment(FavoriteFacilityFragment, props, 'facilities');

	const mutation = useMutation(handleFavorite);
	const saveFavorite = useAction(mutation);

	// eslint-disable-next-line solid/reactivity
	const saveAll = action(async (data) => {
		const ids = data.getAll('favorites');
		await Promise.all(
			ids.map((id: string) => {
				const data = new FormData();
				data.set('facilityId', id);
				return saveFavorite(data);
			}),
		);
		props.onFavorited && props.onFavorited();
	});

	const sub = useSubmission(saveAll);

	return (
		<Dialog key="tee-time-filter-favorites">
			<DialogTrigger as={PillButton}>Favorites</DialogTrigger>
			<DialogContent noPadding header="Add Favorite Courses" headerLevel="h3">
				<form action={saveAll} method="post">
					<div class="flex flex-col gap-4 px-4 pb-4 md:px-6 md:pb-6">
						<p>
							You currently do not have any courses added to your favorites. Select your favorite courses to quickly
							filter your search results.
						</p>
						<CheckboxGroup name="favorites">
							<Label class="sr-only">Select your favorite courses</Label>
							<div class="flex flex-col gap-3">
								<For each={facilities}>
									{(facility) => (
										<Show when={facility.isBookable}>
											<FavoriteFacility facility={facility} />
										</Show>
									)}
								</For>
							</div>
						</CheckboxGroup>
					</div>
					<div class="sticky inset-x-0 bottom-0 border-t border-neutral bg-white px-6 py-3">
						<DialogActions>
							<DialogAction type="submit">
								<Show when={sub.pending}>
									<ActivityIndicator aria-label="Loading" class="me-2 size-4" />
								</Show>
								Save favorites
							</DialogAction>
						</DialogActions>
					</div>
				</form>
			</DialogContent>
		</Dialog>
	);
}

function FavoriteFacility(props: { facility: FavoriteFacilityFragmentType }) {
	const [checked, setChecked] = createSignal(false);
	return (
		<div
			class="flex cursor-pointer items-center gap-2 rounded border border-neutral bg-white p-3 hover:border-brand hover:bg-brand-100"
			onClick={() => setChecked((checked) => !checked)}
		>
			<div class="mr-2 box-content aspect-square w-14 shrink-0 overflow-hidden rounded-md border border-neutral bg-white p-1">
				<Picture
					src={props.facility.metadata?.logo?.url}
					alt=""
					width={56}
					height={56}
					sizes="3.5rem"
					mode="contain"
					class="size-14 object-cover"
					loading="lazy"
				/>
			</div>

			<HiddenCheckbox value={props.facility.id} class="flex grow basis-full flex-col sm:basis-auto" checked={checked()}>
				<Label class="grow basis-full sm:basis-auto">{props.facility.name}</Label>
				<span class="text-sm text-neutral-800">{Object.values(props.facility.metadata?.address ?? []).join(', ')}</span>
			</HiddenCheckbox>
			<Icon
				name={checked() ? 'star-filled' : 'star'}
				class="text-xl"
				classList={{
					'text-brand': checked(),
					'text-neutral-800': !checked(),
				}}
			/>
		</div>
	);
}

const FavoriteFacilityFragment = gql(`fragment FavoriteFacility on Facility {
	id
	name
	isBookable
	metadata {
		logo { url }
		address { city, state }
	}
}`);

const toggleFavorite = gql(`mutation addFavoriteFacility($facilityId: String!) {
	toggleFavoriteFacility(facilityId: $facilityId, isFavorite: true) {
		isFavorite
	}
}`);

const handleFavorite = mutationAction(toggleFavorite);
