import { createMemo, For, Match, Show, Suspense, Switch } from 'solid-js';
import { ActivityIndicator, Picture, TextLink, MessageBar } from '@troon/ui';
import { twJoin } from '@troon/tailwind-preset/merge';
import dayjs from '@troon/dayjs';
import { createNumberFormatter } from '../../../modules/number-formatting';
import { dayToDayJs } from '../../../modules/date-formatting';
import { formatClosure } from '../../../modules/format-closure';
import { FacilityTeeTimeFragmentDoc, gql } from '../../../graphql';
import { createFragment } from '../../../graphql/create-fragment';
import { NoTeeTimes } from './no-tee-times-bar';
import { SetAlert } from './set-alert';
import { PriceRange } from './price-range';
import { ShowAll } from './show-all';
import { FacilityTeeTime, FacilityTeeTimeSkeleton } from './facility-tee-time';
import { OutsideBookingWindow } from './outside-booking-window';
import { NonBookable } from './non-bookable';
import type { FacilityTeeTimeFragment } from './facility-tee-time';
import type { CalendarDay, CourseClosure, FragmentType } from '../../../graphql';
import type { Resource } from 'solid-js';

type Props = {
	facility: FragmentType<typeof TeeTimeFacilityFragment>;
	teeTimes: Resource<Record<string, Array<FragmentType<typeof FacilityTeeTimeFragment>>>>;
	players: number | undefined;
	date: string;
	startAt: number;
	endAt: number;
};

export const numTeeTimesToShow = 6;

export function TeeTimesFacility(props: Props) {
	const numberFormatter = createNumberFormatter();
	const facility = createFragment(TeeTimeFacilityFragment, props, 'facility');

	const maxBookingDate = createMemo(() =>
		facility.courses.reduce(
			(memo, c) =>
				dayToDayJs(c.bookingWindowDay as CalendarDay, facility.timezone).isAfter(memo)
					? dayToDayJs(c.bookingWindowDay as CalendarDay, facility.timezone)
					: memo,
			dayjs.tz(new Date(), facility.timezone),
		),
	);

	const closures = createMemo(() => {
		const date = props.date;
		const closures = facility.courses.reduce(
			(memo, course) => {
				const closure = course.closures.find((closure) => {
					return (
						dayToDayJs(closure.startDay, facility.timezone).isSameOrBefore(date, 'day') &&
						dayToDayJs(closure.endDay, facility.timezone).isSameOrAfter(date, 'day')
					);
				}) as CourseClosure;
				if (closure) {
					memo[course.id] = closure;
				}
				return memo;
			},
			{} as Record<string, CourseClosure>,
		);

		return closures;
	});

	return (
		// hack fix for Safari. grid needs to be in a grid otherwise child grid's rows get too tall
		<div class="grid gap-6">
			<div class="grid grid-cols-3 gap-4 border-b border-neutral pb-6 sm:grid-cols-4 md:pb-8 lg:grid-cols-9 lg:gap-6">
				<div class="col-span-1 lg:col-span-2 lg:row-span-2">
					<Picture
						src={facility.metadata?.hero?.url}
						alt=""
						loading="lazy"
						width={400}
						height={300}
						sizes="(min-width: 768px) 25vw, 33vw"
						// eslint-disable-next-line tailwindcss/no-arbitrary-value
						class={twJoin(
							'aspect-square w-full max-w-64 rounded md:max-w-none lg:aspect-[4/3]',
							maxBookingDate().isBefore(props.date) && 'opacity-60',
						)}
					/>
				</div>
				<div class="col-span-2 flex h-full flex-col justify-start gap-1 sm:col-span-3 lg:col-span-7">
					<h2 class="text-base font-semibold sm:text-xl">
						<TextLink href={`/course/${facility.slug}`} class="text-black" preload>
							{facility.name}
						</TextLink>
					</h2>
					<p class="text-sm text-neutral-800">
						{facility.metadata?.address?.city}, {facility.metadata?.address?.state}
					</p>
					<p class="text-sm text-neutral-800">
						<Suspense>
							<Show when={props.teeTimes()}>
								{(teeTimes) => {
									return (
										<PriceRange
											prices={Object.entries(teeTimes()).reduce(
												(memo, [courseId, teeTimes]) => {
													const resolved = createFragment(FacilityTeeTimeFragmentDoc, teeTimes);
													if (facility.courses.map((c) => c.id).includes(courseId)) {
														memo.push(
															...resolved.map((tt) => ({
																min: tt.minPrice.value,
																max: tt.maxPrice.value,
															})),
														);
													}
													return memo;
												},
												[] as Array<{ min: number; max: number }>,
											)}
										/>
									);
								}}
							</Show>
						</Suspense>
					</p>
				</div>

				<div class="relative col-span-3 flex flex-col items-start justify-start gap-2 overflow-hidden sm:col-span-4 lg:col-span-7 lg:col-start-3">
					<Switch>
						<Match when={!facility.isBookable}>
							<NonBookable phone={facility.metadata?.phone} />
						</Match>
						<Match
							when={Object.keys(closures() ?? {}).length === facility.courses.length && Object.values(closures())[0]}
						>
							{(closure) => (
								<MessageBar appearance="danger" icon="info">
									{formatClosure(closure(), facility.timezone)}
								</MessageBar>
							)}
						</Match>
						<Match when={maxBookingDate().isBefore(props.date)}>
							<OutsideBookingWindow />
						</Match>
						<Match when={!props.teeTimes.loading && Object.values(props.teeTimes.latest ?? {}).flat().length === 0}>
							<NoTeeTimes players={props.players} startAt={props.startAt} endAt={props.endAt} date={props.date} />
						</Match>
						<Match when={facility.courses}>
							{(courses) => (
								<For each={courses()}>
									{(course) => (
										<Show when={!closures()[course.id]} fallback={null}>
											<Show when={courses().length > 1}>
												<h3 class="text-sm font-semibold">{course.name}</h3>
											</Show>

											<Suspense
												fallback={
													<ul class="flex flex-row gap-4 pb-2">
														<For each={new Array(Math.ceil(Math.random() * 5)).fill(null)}>
															{() => (
																<li>
																	<FacilityTeeTimeSkeleton />
																</li>
															)}
														</For>
													</ul>
												}
											>
												<Show when={props.teeTimes()}>
													{(teeTimes) => (
														<div class={twJoin('w-full', teeTimes()[course.id]?.length && 'snap-x overflow-auto')}>
															<ul
																class={twJoin('flex flex-row gap-2 pb-2', teeTimes()[course.id]?.length && 'min-w-max')}
															>
																<Suspense
																	fallback={
																		<li>
																			<ActivityIndicator />
																		</li>
																	}
																>
																	<For
																		each={teeTimes()[course.id]?.slice(0, numTeeTimesToShow)}
																		fallback={
																			<li>
																				<NoTeeTimes
																					players={props.players}
																					startAt={props.startAt}
																					endAt={props.endAt}
																					date={props.date}
																				/>
																			</li>
																		}
																	>
																		{(teeTime) => (
																			<li class="snap-start">
																				<FacilityTeeTime
																					selectedPlayers={props.players}
																					numberFormatter={numberFormatter()}
																					teeTime={teeTime}
																					facilitySlug={facility.slug}
																				/>
																			</li>
																		)}
																	</For>
																	<Show when={(teeTimes()[course.id]?.length ?? 0) > numTeeTimesToShow}>
																		<li class="me-8 snap-end">
																			<ShowAll facilitySlug={facility.slug} name={`${facility.name} ${course.name}`} />
																		</li>
																	</Show>
																	<Show
																		when={
																			(teeTimes()[course.id]?.length ?? 0) > 0 &&
																			(teeTimes()[course.id]?.length ?? 0) < numTeeTimesToShow
																		}
																	>
																		<li>
																			<SetAlert
																				players={props.players}
																				startAt={props.startAt}
																				endAt={props.endAt}
																				date={props.date}
																			/>
																		</li>
																	</Show>
																</Suspense>
															</ul>
														</div>
													)}
												</Show>
											</Suspense>
										</Show>
									)}
								</For>
							)}
						</Match>
					</Switch>
				</div>
			</div>
		</div>
	);
}

const TeeTimeFacilityFragment = gql(`fragment MultiTeeTimesFacilityGroup on Facility {
	name
	slug
	isBookable
	metadata {
		hero { url }
		address { city, state }
		phone
	}
	timezone
	courses {
		id
		name
		bookingWindowDay { year, month, day }
		closures {
			reason
			startDay { year, month, day }
			endDay { year, month, day }
		}
	}
}`);
