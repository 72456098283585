import { createMemo, createSignal, For, Show, Suspense } from 'solid-js';
import { Heading, LinkButton, Dialog, DialogTrigger, DialogContent } from '@troon/ui';
import { useLocation } from '@solidjs/router';
import { Icon } from '@troon/icons';
import { FacilityListCard } from '../../../components/facility/list-card';
import { Grid, GridThreeQuarter, GridQuarter } from '../../../components/layouts/grid';
import { CourseFilters, createFilteredFacilities } from './filters';

export function SearchFacilitiesContent() {
	const facilities = createFilteredFacilities();
	const loc = useLocation();
	const [cursorHistory, setCursorHistory] = createSignal<Array<string>>([]);

	const nextPage = createMemo(() => {
		const cursor = facilities()?.facilities.cursor;
		if (!cursor) {
			return null;
		}
		const params = new URLSearchParams(loc.query as Record<string, string>);
		params.set('cursor', cursor);
		return [cursor, `${loc.pathname}?${params.toString()}`] as [string, string];
	});

	const prevPage = createMemo(() => {
		const hist = cursorHistory();
		if (!hist.length) {
			return null;
		}
		const params = new URLSearchParams(loc.query as Record<string, string>);
		params.set('cursor', hist[hist.length - 2] ?? '');
		return `${loc.pathname}?${params.toString()}`;
	});

	return (
		<Grid>
			<GridThreeQuarter class="order-2 flex flex-col gap-8">
				<div class="flex items-center justify-between gap-4 lg:justify-start">
					<Heading as="h2" size="h3">
						<Suspense>
							<Show when={facilities.latest?.facilities} fallback="Courses">
								{(res) => <>{formatNumber(res().totalCount)} courses</>}
							</Show>
						</Suspense>
					</Heading>
					<LinkButton
						href={`/tee-times/?facilities=${facilities.latest?.facilities.facilities.map((f) => f.slug).join(',')}`}
						class="hidden lg:flex"
					>
						Compare tee times <Icon name="arrow-up-right-md" />
					</LinkButton>
					<Dialog key="facility-search-filters">
						<DialogTrigger appearance="transparent" class="w-fit shrink grow-0 self-end lg:hidden">
							Filter <Icon name="filter" />
						</DialogTrigger>
						<DialogContent header="Filter courses" headerLevel="h3">
							<CourseFilters />
						</DialogContent>
					</Dialog>
				</div>
				<ul class="flex flex-col gap-6">
					<Suspense>
						<For each={facilities.latest?.facilities.facilities} fallback={<li>No courses found</li>}>
							{(facility) => (
								<li>
									<FacilityListCard facility={facility} />
								</li>
							)}
						</For>
					</Suspense>
				</ul>

				<ul class="flex justify-between">
					<li>
						<Show when={prevPage()}>
							{(prev) => (
								<LinkButton
									href={prev()}
									replace
									onClick={() => {
										setCursorHistory((s) => {
											s.pop();
											return s;
										});
									}}
								>
									<Icon name="arrow-left-md" /> Previous page
								</LinkButton>
							)}
						</Show>
					</li>
					<li>
						<Show when={nextPage()}>
							{(next) => (
								<LinkButton
									href={next()[1]}
									replace
									onClick={() => {
										setCursorHistory((s) => [...s, next()[0]]);
									}}
								>
									Next page <Icon name="arrow-right-md" />
								</LinkButton>
							)}
						</Show>
					</li>
				</ul>
			</GridThreeQuarter>
			<GridQuarter class="order-1 hidden lg:flex">
				<CourseFilters />
			</GridQuarter>
		</Grid>
	);
}

const formatNumber = new Intl.NumberFormat('en').format;
