import { Meta, Title } from '@solidjs/meta';
import { Button, Container, Heading, HorizontalRule, Link, Page, Section } from '@troon/ui';
import { NoHydration } from 'solid-js/web';
import { Hero } from '../../components/hero/photo';
import { Grid, GridFive, GridSeven } from '../../components/layouts/grid';
import { Carousel } from '../../components/carousel';
import type { RouteDefinition } from '@solidjs/router';

export default function TroonAccessLaunchParty() {
	return (
		<>
			<Title>Troon Access Launch Party &amp; Member/Guest | Troon</Title>
			<Meta
				name="description"
				content="Get ready for an unforgettable night at the official Troon Access Launch Party & Member/Guest at Grass Clippings Rolling Hills in Phoenix, AZ, on February 4th!"
			/>

			<Hero src="https://images.ctfassets.net/rdsy7xf5c8dt/5kBIAIs2mYeMvGE2LiRRWx/9ac12d6027b35a99d9f0ff6f363ff639/member-guestbackground.jpg?h=250">
				<div class="flex flex-col items-center gap-4 py-12">
					<Heading as="h1">
						<img
							src="https://images.ctfassets.net/rdsy7xf5c8dt/3v71xwisRqVNYbk9XRnup4/531da35e1804b0443e836117051ba2c7/member-guestlogo.svg"
							alt=""
							class="max-w-96"
						/>
						<span class="sr-only">Troon Access Launch Party &amp; Member/Guest</span>
					</Heading>
				</div>
			</Hero>

			<Container>
				<Page>
					<Grid>
						<GridSeven class="flex flex-col gap-8 md:gap-12">
							<NoHydration>
								<Section>
									<p>
										Get ready for an unforgettable night at the official Troon Access Launch Party & Member/Guest at
										Grass Clippings Rolling Hills in Phoenix, AZ, on February 4th! The first of four official events to
										be held in 2025, we’ve rented out Phoenix’s most popular nighttime golf destination during WM
										Phoenix Open week to celebrate Troon Access members and embark on an epic par-3 showdown.
									</p>

									<p>This member-exclusive event will feature:</p>

									<ul class="flex flex-col gap-4">
										<li>
											<b class="mb-2 block text-lg">5pm-8pm Launch Party</b>
											<ul class="flex list-disc flex-col gap-1 ps-8">
												<li>Complimentary food & drinks (each attendee will get 2 drink tickets)</li>
												<li>Q&A/meet up with a surprise guest (or two)</li>
												<li>Putting & Range challenges with awesome prizes</li>
												<li>Free entry for any Troon Access member and 1 guest</li>
											</ul>
										</li>
										<li>
											<b class="mb-2 block text-lg">7pm Shotgun Start Member/Guest</b>
											<ul class="flex list-disc flex-col gap-1 ps-8">
												<li>Green fees, cart, food & drinks included (each player will get 2 drink tickets)</li>
												<li>Tee Gifts for each player</li>
												<li>Hole-in-one and closest to the pin challenges for trips, golf gear, and of course $$$</li>
												<li>$600 per team ($300 per person)</li>
											</ul>
										</li>
									</ul>
								</Section>
							</NoHydration>

							<Carousel
								images={[
									{
										url: 'https://images.ctfassets.net/rdsy7xf5c8dt/3hN6Oa6yH7hKcvEqoup02H/0dac8e30eccd6e8d1e6e213fd6291c56/Grass_Clippings_-_2024_GL_Championship_-_Round_1-17.jpg?h=250',
									},
									{
										url: 'https://images.ctfassets.net/rdsy7xf5c8dt/1gt1rRScJT2CZth7AwaTFl/6ea56bf675043997e6106fe4feaae735/drone_photo_.jpg?h=250',
									},
								]}
							/>

							<NoHydration>
								<Section>
									<HorizontalRule />
									<Heading as="h2" size="h4">
										More about the Member/Guest:
									</Heading>
									<ul class="flex flex-col gap-4">
										<li>
											<b class="mb-2 block text-lg">Format:</b>
											<ul class="flex list-disc flex-col gap-1 ps-8">
												<li>2-person scramble</li>
												<li>One member & one guest (or you can bring another member)</li>
												<li>No handicaps (this is a par-3 course), so bring your best, best friend</li>
												<li>Max double to keep pace of play moving</li>
												<li>45 total teams competing for the top 4 spots which will pay out</li>
											</ul>
										</li>
										<li>
											<b class="mb-2 block text-lg">Selection:</b>
											<ul class="flex list-disc flex-col gap-1 ps-8">
												<li>We will randomly select participants to play in the event who fill out the form</li>
												<li>The lottery entry will close on January 20</li>
												<li>Participants will be selected and notified beginning on January 21</li>
												<li>
													If selected, participants will need to confirm guest information and pay the entry fee no
													later than January 24
												</li>
											</ul>
										</li>
									</ul>
								</Section>
							</NoHydration>
						</GridSeven>
						<GridFive>
							<NoHydration>
								<Section class="sticky top-24 rounded border border-neutral bg-neutral-100 p-4 md:p-8">
									<p>RSVP for the Launch Party now!</p>
									<Button as={Link} href="https://ea1dti1vxsj.typeform.com/to/Wxb8r63M">
										RSVP Now
									</Button>
								</Section>
							</NoHydration>
						</GridFive>
					</Grid>
				</Page>
			</Container>
		</>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
