import {
	ActivityIndicator,
	Button,
	Dialog,
	DialogAction,
	DialogActions,
	DialogContent,
	Errors,
	FieldDescription,
	Form,
	HiddenFields,
	Input,
	Label,
	LinkButton,
	TextField,
} from '@troon/ui';
import { useAction, useSubmission } from '@solidjs/router';
import { createEffect, createSignal, createUniqueId, Match, Show, Switch } from 'solid-js';
import { phone } from 'phone';
import { Icon } from '@troon/icons';
import { gql, mutationAction, useMutation } from '../graphql';
import { useUser } from '../providers/user';
import type { JSXElement } from 'solid-js';

type Props = {
	buttonLabel?: JSXElement;
	onSuccess?: (phoneNumber: string) => void;
};

export function RequestPhoneNumber(props: Props) {
	const user = useUser();
	const [form, setForm] = createSignal<HTMLFormElement>();
	const formId = createUniqueId();

	const updateAction = useMutation(requestUpdate);
	const resendAction = useMutation(requestUpdate);
	const resendRequest = useAction(resendAction);

	const confirmAction = useMutation(confirm);
	const [inputNumber, setInputNumber] = createSignal('');
	const [showConfirmation, setShowConfirmation] = createSignal(false);

	const updateSubmission = useSubmission(updateAction);
	const resendSubmission = useSubmission(resendAction, ([data]) => data.get('__formId') !== formId);
	const confirmSubmission = useSubmission(confirmAction);

	createEffect(() => {
		if (updateSubmission.result?.data) {
			setShowConfirmation(
				!!updateSubmission.result?.data?.updatePhoneNumber.ok && !confirmSubmission?.result?.data?.confirmPhoneNumber,
			);
		}
	});

	createEffect(() => {
		const phoneNumber = confirmSubmission?.result?.data?.confirmPhoneNumber.phoneNumber;
		if (phoneNumber && props.onSuccess) {
			props.onSuccess(phoneNumber);
		}
	});

	return (
		<>
			<Form action={updateAction} document={requestMutation} ref={setForm} id={formId}>
				<TextField name="input.phoneNumber">
					<Label>Phone number</Label>
					<Input
						readonly={!!confirmSubmission?.result?.data?.confirmPhoneNumber.phoneNumber}
						prefixElement={
							confirmSubmission.result?.data?.confirmPhoneNumber ? (
								<Icon name="circle-check" class="text-green-500" />
							) : (
								<Icon name="phone" />
							)
						}
						autocomplete="tel"
						inputMode="tel"
						class="ps-8"
						onInput={(e) => setInputNumber(e.target.value)}
						value={user()?.me.phoneNumber ?? ''}
					/>
				</TextField>

				<Errors />

				<Show when={!confirmSubmission?.result?.data?.confirmPhoneNumber.phoneNumber}>
					<Button type="submit" class="size-fit">
						{props.buttonLabel || 'Save'}
					</Button>
				</Show>
			</Form>
			<Dialog key="add-phone-number" open={showConfirmation()} onOpenChange={setShowConfirmation}>
				<DialogContent header="Confirm your phone number" headerLevel="h3">
					<Form action={confirmAction} document={confirmMutation} class="flex flex-col gap-4">
						<p>A confirmation code has been sent to {phone(inputNumber()).phoneNumber}.</p>
						<HiddenFields data={{ 'input.phoneNumber': phone(inputNumber()).phoneNumber }} />
						<TextField name="input.confirmationCode">
							<Label>Confirmation Code</Label>
							<Input inputMode="numeric" autocomplete="one-time-code" />
							<FieldDescription>
								<p>
									Didn’t receive a code?{' '}
									<LinkButton role="button" class="-mx-1.5" size="sm" onClick={() => setShowConfirmation(false)}>
										Change your phone number
									</LinkButton>{' '}
									or{' '}
									<LinkButton
										role="button"
										aria-disabled={resendSubmission.pending || !!resendSubmission.result?.data?.updatePhoneNumber}
										class="-mx-1.5"
										size="sm"
										onClick={() => {
											const data = new FormData(form());
											data.delete('__formId');
											resendRequest(data);
										}}
									>
										resend
									</LinkButton>
									<Switch>
										<Match when={resendSubmission.pending}>
											<span class="inline-flex">
												<ActivityIndicator class=" size-4" />
											</span>
										</Match>
										<Match when={resendSubmission.result?.data?.updatePhoneNumber}>
											<span>
												<Icon name="circle-check" class="size-4 text-green-500" />
												<span class="sr-only">Code resent</span>
											</span>
										</Match>
									</Switch>{' '}
									the confirmation code.
								</p>
							</FieldDescription>
						</TextField>

						<Errors />

						<DialogActions>
							<DialogAction type="submit">Confirm</DialogAction>
							<DialogAction type="button" appearance="transparent" onClick={() => setShowConfirmation(false)}>
								Cancel
							</DialogAction>
						</DialogActions>
					</Form>
				</DialogContent>
			</Dialog>
		</>
	);
}

const requestMutation = gql(`mutation updatePhoneNumber($input: UpdatePhoneNumberInput!) {
	updatePhoneNumber(input: $input) { ok }
}`);

const requestUpdate = mutationAction(requestMutation, {
	transform: (data) => ({
		input: {
			phoneNumber:
				phone(data.get('input.phoneNumber')! as string).phoneNumber ?? (data.get('input.phoneNumber') as string),
		},
	}),
	revalidates: [],
});

const confirmMutation = gql(`mutation confirmPhoneNumber($input: ConfirmPhoneNumberInput!) {
	confirmPhoneNumber(input: $input) { phoneNumber }
}`);

const confirm = mutationAction(confirmMutation, {
	revalidates: ['loggedInUser'],
});
