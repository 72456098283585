import { PlainDocument } from '../../../layouts/plain-document';

export default function RewardsDocPage() {
	return (
		<PlainDocument
			breadcrumbs={[{ href: '/events/experience-kapalua-sweepstakes', title: 'Experience Kapalua Sweepstakes' }]}
			slug="experience-kapalua-sweepstakes-terms-and-conditions"
		/>
	);
}
