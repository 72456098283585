import { Container, Picture } from '@troon/ui';
import { getConfigValue } from '../modules/config';
import type { ParentProps } from 'solid-js';
import type { RouteDefinition } from '@solidjs/router';

export default function AuthPages(props: ParentProps) {
	return (
		<div class="relative flex min-h-screen items-center justify-center pb-24">
			<Picture
				src={`https://${getConfigValue('MAP_HOST')}/assets/images/troon-north.jpg`}
				alt=""
				class="absolute inset-0 -z-10 h-screen w-screen self-stretch object-cover brightness-75"
				width={1200}
				height={1200}
				sizes="100vw"
			/>
			<Container size="small">
				<div class="mt-20 overflow-hidden rounded bg-white text-center shadow-xl">{props.children}</div>
			</Container>
		</div>
	);
}

export const route = { info: { nav: { appearance: 'minimal', hero: true } } } satisfies RouteDefinition;
