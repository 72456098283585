import { createSignal, Show, Suspense } from 'solid-js';
import { createAsync, useSearchParams } from '@solidjs/router';
import { gql } from '../../../graphql';
import { cachedQuery } from '../../../graphql/cached-get';
import { useUser } from '../../../providers/user';
import { RedeemWithCode } from './_pages/with-code';
import { EnterAccessCode } from './_pages/enter-code';
import type { CombinedError } from '@urql/core';

export default function RedeemAccessGift() {
	const [params] = useSearchParams<{ code?: string }>();
	const [errors, setErrors] = createSignal<CombinedError>();
	const user = useUser();

	const query = getAccess((err) => {
		setErrors(err);
	});

	const data = createAsync(
		async () => {
			if (!params.code) {
				return;
			}

			return await query({ code: params.code });
		},
		{ deferStream: true },
	);

	return (
		<Suspense>
			<Show when={!user()?.me.troonAccessProductType}>
				<Show when={data()?.gift?.product} fallback={<EnterAccessCode error={errors()} />}>
					{(gift) => <RedeemWithCode giftProduct={gift()} redemptionCode={data()!.gift.redemptionCode!} />}
				</Show>
			</Show>
		</Suspense>
	);
}

const getAccessQuery = gql(`query getRedemption($code: String!) {
  gift: accessGiftPurchaseByRedemptionCode(redemptionCode: $code) {
    redemptionCode
    product: troonCardSubscriptionProduct { ...GiftProduct }
  }
}
`);

const getAccess = (onError: (error: CombinedError) => void) =>
	cachedQuery(getAccessQuery, {
		onError,
		retry: { retryIf: () => false },
	});
