import { Meta, Title } from '@solidjs/meta';
import { Heading, Container, BreadcrumbItem, Section, Picture, Page } from '@troon/ui';
import { NoHydration } from 'solid-js/web';
import { useLocation } from '@solidjs/router';
import { getConfigValue } from '../../modules/config';
import { Hero } from '../../components/hero/photo';
import { VideoSection } from '../../components/layouts/video-section';
import type { RouteDefinition } from '@solidjs/router';

export default function DEIPage() {
	const loc = useLocation();

	return (
		<>
			<NoHydration>
				<Title>Founder & History | Troon</Title>
				<Meta
					name="description"
					content="The senior executives and leadership team Troon and Troon's affiliated brands."
				/>

				<Hero
					src={`${getConfigValue('IMAGE_HOST')}/digital/hero/founder-history-tee.jpg`}
					breadcrumbs={() => (
						<>
							<BreadcrumbItem href="/about">About Troon</BreadcrumbItem>
							<BreadcrumbItem href={loc.pathname}>Founder & History</BreadcrumbItem>
						</>
					)}
				>
					<Heading as="h1" class="text-center text-white">
						Founder & History
					</Heading>
				</Hero>
			</NoHydration>

			<Page>
				<Container>
					<VideoSection
						code="576928921"
						type="vimeo"
						poster={`${getConfigValue('IMAGE_HOST')}/digital/video/poster-founder.png`}
					>
						<Heading as="h2">Dana Garmany, Troon Founder & Board Member, honored as PGA Executive of the Year</Heading>
						<p>
							Dana Garmany is honored for performing outstanding services in an executive management position while
							possessing leadership, vision, and a substantial record of service to the Association and the game of
							golf.
						</p>
					</VideoSection>
				</Container>

				<NoHydration>
					<Section appearance="primary">
						<Container size="small" class="flex flex-col gap-6">
							<Heading as="h2">Founder & History of Troon</Heading>
							<p>
								When Troon Founder and now Board Member, Dana Garmany started Troon Golf 34 years ago, he never imagined
								he would revolutionize the golf business, let alone oversee a management company that would one day span
								the globe.
							</p>

							<p>
								Long before North Scottsdale, Ariz., became a golfer’s paradise and Troon became the gold standard for
								upscale course experiences, Troon was simply a payroll services company. At least that was the idea when
								Garmany helped build Troon North for developer Jerry Nelson and his Japanese partners.
							</p>

							<p>
								As the story goes, the Japanese trading company involved in the project was concerned about employer
								liability issues, so Garmany trademarked the Troon Golf name for the purpose of creating a company that
								could handle payroll for the Japanese partners and protect them from any management risks.
							</p>

							<p>
								Soon after Troon North opened in 1990 to rave reviews, Garmany’s payroll company quickly grew into
								something much bigger when he began selling a revolutionary concept to other parts of the desert and
								beyond. It was a business model focused on managing upscale public courses based on hospitality-caliber
								customer service principles and superior agronomic conditions.
							</p>

							<p>
								As history would show, the newfound business would flourish and the modern-day Troon Experience was
								born. Some described Garmany’s early vision as creating “country clubs for the day” — with Troon’s
								target audience being the traveling golfer accustomed to having a private club back home or just the
								average golfer seeking a better golf experience. However you describe it, the strategy proved to be
								prescient and profitable for decades to come, and all but created an entirely new golf course category:
								upscale daily fee.
							</p>

							<p>
								Today, Troon leads the industry in golf course and private club management, operating at 900+ locations
								throughout the world. The company used its foundation in golf facility management to extend its vision
								beyond the golf course with a diverse portfolio that includes thirteen distinct brands: Troon Golf,
								Troon Privé, Troon International, Indigo Sports, ICON Management, CADDIEMASTER, ClubUp, Cliff Drysdale
								Tennis, Peter Burwash International, True Club Solutions, RealFood Hospitality, Strategy and Design,
								Casa Verde Golf, and Eventive Sports.
							</p>

							<p>
								Headquartered in Scottsdale, Arizona, Troon maintains satellite offices in Alabama, California, Florida,
								Illinois, Massachusetts, Texas, Virginia, Washington and internationally.
							</p>
						</Container>
					</Section>

					<Section>
						<Container size="small" class="flex flex-row flex-wrap gap-12 sm:flex-nowrap md:gap-24">
							<div class="flex shrink grow flex-col gap-6">
								<Heading as="h2">Dana Garmany - Bio</Heading>
								<p>
									Dana Garmany has more than 40 years of industry experience developing and operating golf facilities
									worldwide. He has been named by Golf Inc., Golf Digest and Golfweek as one of the most powerful and
									influential people in golf. Garmany founded Troon in 1990, and today serves on the Board of Directors,
									post his retirement as Chairman & Chief Executive Officer. He has received numerous honors during his
									career including the DeKalb County Alabama Sports Hall of Fame, Southwest Section PGA Hall of Fame,
									the Arizona Golf Hall of Fame, the University of Alabama Hospitality Hall of Fame, and the Lifetime
									Achievement Award from the HSBC International Golf Forum. In addition, Garmany was recognized by the
									PGA of America as the very first recipient of the newly created PGA of America Executive of the Year
									Award for 2020. He also serves on the Advisory Board of McDowell Sonoran Conservancy, and holds a BS
									Degree in Restaurant and Hospitality Management from the University of Alabama. His leadership at
									Troon helped create a company that continues to lead and set standards for domestic and international
									daily fee, resort, and private golf courses.
								</p>
							</div>
							<div class="order-first min-w-48 max-w-48 shrink grow basis-48">
								<Picture
									src={`${getConfigValue('IMAGE_HOST')}/digital/execs/dana-garmany.jpg`}
									width={200}
									height={280}
									sizes="12rem"
									alt="Headshot of Troon Founder Dana Garmany"
									loading="lazy"
									class="w-full rounded"
									crop="face"
								/>
							</div>
						</Container>
					</Section>
				</NoHydration>
			</Page>
		</>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
