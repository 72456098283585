import { useParams } from '@solidjs/router';
import { For, Show, Switch, Match, createMemo, useContext, createSignal, Suspense, Index } from 'solid-js';
import {
	Button,
	DialogContent,
	DialogAction,
	DialogActions,
	Dialog,
	Errors,
	Form,
	Link,
	DialogTrigger,
	Container,
	Heading,
	Section,
} from '@troon/ui';
import { Title } from '@solidjs/meta';
import { useTrackEvent } from '@troon/analytics';
import { Icon } from '@troon/icons';
import { twJoin } from '@troon/tailwind-preset/merge';
import { ReservationState, ReservationUserState, gql, mutationAction, useMutation } from '../../../graphql';
import { Grid, GridFive, GridSeven } from '../../../components/layouts/grid';
import { PaymentInfo } from '../../../components/payment-info';
import { CancellationPolicy } from '../../../components/cancellation-policy';
import { useUser } from '../../../providers/user';
import { ReservationCtx } from '../../../providers/reservation';
import { FacilityLocation } from '../../../components/facility/location';
import { FacilityReservationHeader } from '../../../components/facility/reservation-header';
import { Hero } from '../../../components/hero/photo';
import { MessageBanner } from '../../../components/message-banner';
import { FrequentlyAskedQuestions } from '../../../components/faqs';
import { ReservationAccessUpsell } from '../../../components/upsells/access-reservation';
import { ReservationPlayerSlot } from './components/reservation-player';
import type { CalendarDayTime, Currency, Reservation as IReservation, ReservationUser } from '../../../graphql';

export default function Reservation() {
	const params = useParams();
	const user = useUser();
	const data = useContext(ReservationCtx)!;
	const track = useTrackEvent();

	const acceptAction = useMutation(handleAccept);
	const rejectAction = useMutation(handleReject);
	const leaveAction = useMutation(handleLeave);

	const [showCancelConfirm, setShowCancelConfirm] = createSignal(false);

	const reservationUser = createMemo(() => {
		return data()?.reservation.users.find((resUser) => resUser.user?.id === user()?.me.id);
	});

	const canModify = createMemo(() => {
		return !!(
			data.latest?.reservation.ownerId === user()?.me.id &&
			data.latest?.reservation.state !== ReservationState.Cancelled &&
			!data.latest?.reservation.isPastStartTime
		);
	});

	const reservationUsers = createMemo(() =>
		data.latest?.reservation.users
			.sort((a, b) => {
				if (a.user?.id === data.latest?.reservation.ownerId || b.state === ReservationUserState.Empty) {
					return -1;
				}
				if (b.user?.id === data.latest?.reservation.ownerId || a.state === ReservationUserState.Empty) {
					return 1;
				}
				return sort(
					`${a.user?.firstName ?? ''} ${a.user?.lastName ?? ''}`,
					`${b.user?.firstName ?? ''} ${b.user?.lastName ?? ''}`,
				);
			})
			.map((u, i) => [u, i] as [ReservationUser, number]),
	);

	return (
		<>
			<Hero src={data()?.reservation.facility?.metadata?.hero?.url}>
				<Show when={data()?.reservation.state === ReservationState.Cancelled}>
					<MessageBanner variant="subtle" class="absolute inset-x-0 bottom-5">
						<p>This tee time has been cancelled.</p>
					</MessageBanner>
				</Show>
			</Hero>

			<Container size={data()?.reservation.state === ReservationState.Cancelled ? 'small' : undefined}>
				<>
					<Title>
						Tee time reservation at{' '}
						{data.latest?.reservation.facility.name ?? data.latest?.reservation.course.name ?? ''} | Troon
					</Title>

					<FacilityReservationHeader bordered reservation={data.latest?.reservation}>
						<div class="flex flex-row flex-wrap items-stretch justify-end gap-4">
							<Switch>
								<Match
									when={
										data.latest?.reservation.isPastStartTime ||
										data.latest?.reservation.state === ReservationState.Cancelled
									}
								>
									{null}
								</Match>
								<Match when={reservationUser()?.state === ReservationUserState.Invited}>
									<Form action={acceptAction} document={acceptInvitationMutation} method="post">
										<input type="hidden" name="reservationUserId" value={reservationUser()?.id} />
										<Errors />
										<Button type="submit">Accept invitation</Button>
									</Form>
									<Form action={rejectAction} document={rejectInvitationMutation} method="post">
										<input type="hidden" name="reservationUserId" value={reservationUser()?.id} />
										<Errors />
										<Button appearance="secondary" action="danger" type="submit">
											Reject invitation
										</Button>
									</Form>
								</Match>
								<Match when={data.latest?.reservation && canModify()}>
									<Dialog key="reservation-cancel" open={showCancelConfirm()} onOpenChange={setShowCancelConfirm}>
										<DialogTrigger appearance="secondary" class="shrink md:grow-0">
											Cancel tee time
										</DialogTrigger>
										<DialogContent header="Cancel reservation" headerLevel="h2">
											<CancelReservation
												reservation={data.latest?.reservation as IReservation}
												onSuccess={() => {
													setShowCancelConfirm(false);
												}}
												onCancel={() => {
													setShowCancelConfirm(false);
												}}
											/>
										</DialogContent>
									</Dialog>
								</Match>
								<Match when={data.latest?.reservation?.state === ReservationState.Active}>
									<Dialog key="reservation-leave">
										<DialogTrigger
											appearance="secondary"
											disabled={data.latest?.reservation.isPastStartTime}
											class="shrink md:grow-0"
										>
											Leave reservation
										</DialogTrigger>
										<DialogContent header="Leave reservation" headerLevel="h2">
											<Form action={leaveAction} document={leaveReservationMutation} method="post">
												<input type="hidden" name="reservationUserId" value={reservationUser()?.id} />
												<input type="hidden" name="reservationId" value={params.reservationId} />
												<p>Are you sure you want to leave this reservation?</p>
												<Errors />

												<DialogActions>
													<DialogAction action="danger" type="submit">
														Leave reservation
													</DialogAction>
												</DialogActions>
											</Form>
										</DialogContent>
									</Dialog>
								</Match>
							</Switch>
							<Show
								when={
									reservationUser()?.state !== ReservationUserState.Invited &&
									data.latest?.reservation.state !== ReservationState.Cancelled &&
									!data.latest?.reservation.isPastStartTime
								}
							>
								<Dialog key="reservation-calendar-event">
									<DialogTrigger appearance="primary" class="shrink grow-0">
										<Icon name="calendar" /> <span class="sr-only">Calendar</span>
									</DialogTrigger>
									<DialogContent header="Add to calendar" headerLevel="h2">
										<div class="grid grid-cols-2 gap-4">
											<For each={Object.entries(data.latest?.reservation.calendarEvent ?? {})}>
												{([key, href]) => (
													<Button
														appearance={calendarImage[key] ? 'secondary' : 'primary'}
														as={Link}
														href={href}
														rel="noopener noreferer"
														onClick={() => {
															track('addToCalendar', { calendarType: key });
														}}
														class={calendarImage[key] ? 'flex flex-col items-center gap-2 normal-case' : 'col-span-2'}
													>
														<Show when={calendarImage[key]}>
															{(src) => <img width="84" height="84" src={src()} alt="" />}
														</Show>
														{calendarTitle[key] ?? ''}
													</Button>
												)}
											</For>
										</div>
									</DialogContent>
								</Dialog>
							</Show>
						</div>
					</FacilityReservationHeader>

					<Grid>
						<GridSeven
							class={twJoin(
								'flex flex-col gap-12',
								data()?.reservation.state === ReservationState.Cancelled && 'lg:col-span-12',
							)}
						>
							<Suspense>
								<Show when={data()?.accessUpsell}>{(upsell) => <ReservationAccessUpsell upsell={upsell()} />}</Show>
							</Suspense>
							<Section layout="tight">
								<Heading as="h2" size="h4">
									Invite & Manage Players
								</Heading>
								<p>
									Invite the other players in your group to join this reservation. We’ll send you a notification when
									they join.
								</p>

								<ul class="flex flex-col gap-2">
									<Index each={reservationUsers()}>
										{(reservationUser) => (
											<li class="rounded border border-neutral p-4 md:py-6">
												<ReservationPlayerSlot
													userSlot={reservationUser()[1] + 1}
													canModify={canModify()}
													user={reservationUser()[0]}
													isHost={reservationUser()[0].user?.id === data.latest?.reservation.ownerId}
													availableGuestPass={data()?.guestPasses.available[0]}
													guestPassDiscount={data()?.reservation.guestPassDiscount?.displayValue}
												/>
											</li>
										)}
									</Index>
								</ul>
							</Section>

							<Section layout="tight">
								<Heading as="h2" size="h4">
									Frequently Asked Questions
								</Heading>
								<div class="rounded border border-neutral">
									<FrequentlyAskedQuestions
										id={
											(data()?.reservation.playerCount ?? 0) === 1
												? 'individual-booking-faqs'
												: 'reservation-details-multiple-player-faqs'
										}
									/>
								</div>
							</Section>

							<Section layout="tight">
								<Heading as="h2" size="h4">
									Facility info
								</Heading>
								<Show when={data.latest?.reservation.facility}>
									{(facility) => <FacilityLocation facility={facility()} />}
								</Show>
							</Section>
						</GridSeven>

						<Show when={data.latest?.reservation.state !== ReservationState.Cancelled}>
							<GridFive>
								<div class="sticky top-24 flex flex-col gap-y-8">
									<div class="flex flex-col gap-y-4 rounded border border-neutral-500 p-4">
										<div>
											<Heading as="h2" size="h5">
												Payment Info
											</Heading>
											<PaymentInfo receipt={data()?.reservationPaymentInfo.receipt} />

											<Suspense>
												<Show when={data()?.reservationPaymentInfo.rewardPointsEarned}>
													{(points) => (
														<p class="flex items-center gap-4 rounded bg-brand-100 p-4 text-sm text-brand-600">
															<Icon name="logo-square" class="size-8 shrink-0 text-brand" />
															<span>
																You’ll earn <b>{points()} Troon Rewards</b> points when you pay for this round.
															</span>
														</p>
													)}
												</Show>
											</Suspense>
										</div>
									</div>

									<div class="flex flex-col gap-2">
										<Heading as="h3" size="h5">
											<Icon name="square-warning" class="text-2xl text-brand" /> Cancellation policy
										</Heading>
										<p>
											<CancellationPolicy
												cancelFee={data.latest?.reservation.cancelFee as Currency}
												cancelDayTime={data.latest?.reservation.cancelDayTime as CalendarDayTime}
											/>
										</p>
									</div>
								</div>
							</GridFive>
						</Show>
					</Grid>
				</>
			</Container>
		</>
	);
}

function CancelReservation(props: { reservation: IReservation; onSuccess: () => void; onCancel: () => void }) {
	const handleCancel = useMutation(
		mutationAction(cancelReservationMutation, {
			revalidates: ['reservationInformation'],
			toast: 'Your tee time has been cancelled.',
			// eslint-disable-next-line solid/reactivity
			onSuccess: props.onSuccess,
			track: {
				event: 'cancelReservation',
				// eslint-disable-next-line solid/reactivity
				data: { id: props.reservation.id },
			},
		}),
	);

	return (
		<Switch
			fallback={
				<div class="flex flex-col gap-8">
					<p>
						It is too late to cancel this tee time according to the cancellation policy. Please call the golf shop
						instead
						<Show when={props.reservation.facility.metadata?.phone}>
							{(phone) => (
								<>
									{' '}
									at <span class="font-semibold">{phone()}</span>
								</>
							)}
						</Show>
						.
					</p>
					<Button as={Link} href={`tel:${props.reservation.facility.metadata?.phone}`}>
						Call golf shop
					</Button>
				</div>
			}
		>
			<Match when={props.reservation.isCancelWindow}>
				<Form action={handleCancel} document={cancelReservationMutation} method="post">
					<p>Are you sure you want to cancel this reservation? There is no guarantee you’ll be able to rebook.</p>
					<Show when={props.reservation.cancelDayTime}>
						<p>
							<CancellationPolicy
								cancelFee={props.reservation.cancelFee as Currency}
								cancelDayTime={props.reservation.cancelDayTime as CalendarDayTime}
							/>
						</p>
					</Show>
					<input type="hidden" name="id" value={props.reservation.id} />
					<Errors />
					<DialogActions>
						<DialogAction type="submit" action="danger">
							Confirm cancellation
						</DialogAction>
						<DialogAction appearance="transparent" type="button" onClick={props.onCancel}>
							Nevermind
						</DialogAction>
					</DialogActions>
				</Form>
			</Match>
		</Switch>
	);
}

export const route = { info: { nav: { hero: true } } };

const sort = new Intl.Collator('en').compare;

const cancelReservationMutation = gql(`
mutation cancelReservation($id: String!) {
	cancelReservation(id: $id) {
		id
	}
}`);

const acceptInvitationMutation = gql(`mutation acceptReservationInvite($reservationUserId: String!) {
	acceptReservationInvite(reservationUserId: $reservationUserId) { id }
}`);

const handleAccept = mutationAction(acceptInvitationMutation, {
	revalidates: ['reservationInformation'],
	track: {
		event: 'acceptInvitation',
		transform: (data) => ({ reservationUserId: data.get('reservationUserId') as string }),
	},
});

const rejectInvitationMutation = gql(`mutation rejectReservationInvite($reservationUserId: String!) {
	rejectReservationInvite(reservationUserId: $reservationUserId) { ok }
}`);

const handleReject = mutationAction(rejectInvitationMutation, {
	redirect: '/',
	toast: 'You have declined this invitation.',
	track: {
		event: 'rejectInvitation',
		transform: (data) => ({ reservationId: data.get('reservationId') as string }),
	},
});

const leaveReservationMutation = gql(`
mutation leaveReservation($reservationUserId: String!) {
	leaveReservation(reservationUserId: $reservationUserId) {
		ok
	}
}`);

const handleLeave = mutationAction(leaveReservationMutation, {
	redirect: '/',
	toast: 'You have left this reservation.',
	track: {
		event: 'leaveReservation',
		transform: (data) => ({ reservationId: data.get('reservationId') as string }),
	},
});

const calendarImage: Record<string, string> = {
	google: '/assets/calendar/google-calendar.svg',
	office365: '/assets/calendar/office-365.svg',
	outlook: '/assets/calendar/outlook.svg',
	yahoo: '/assets/calendar/yahoo.svg',
};

const calendarTitle: Record<string, string> = {
	google: 'Google',
	office365: 'Office 365',
	outlook: 'Outlook',
	yahoo: 'Yahoo',
	ics: 'Download .ics',
};
