import { FileRoutes } from '@solidjs/start/router';
import { Icon } from '@troon/icons';
import { BreadcrumbItem, Breadcrumbs, Heading, Page, Section, TextLink } from '@troon/ui';
import { For, Match, Switch, Show } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import type { RouteModule } from 'vinxi/routes';
import type { JSX, ParentProps } from 'solid-js';
import type { RouteDefinition } from '@solidjs/router';

type PageRoute = RouteModule & { children?: Array<PageRoute>; info?: RouteDefinition['info']; filePath: string };

export default function RoutesPage() {
	const compare = new Intl.Collator('en').compare;

	return (
		<div class="px-4 pt-4 md:px-8 lg:px-12 xl:px-16">
			<Breadcrumbs>
				<BreadcrumbItem href="/_/storybook">Storybook</BreadcrumbItem>
				<BreadcrumbItem href="/_/storybook/routes">Page routes</BreadcrumbItem>
			</Breadcrumbs>
			<Page>
				<Section>
					<Heading as="h2">Page routes</Heading>
					<div class="relative overflow-x-auto">
						<table class="w-full">
							<thead>
								<tr>
									<Th rowSpan={2} class="sticky left-0">
										Path
									</Th>
									<Th colSpan={3}>Nav</Th>
									<Th rowSpan={2}>Banner</Th>
									<Th rowSpan={2}>File path</Th>
								</tr>
								<tr>
									<Th>Hero</Th>
									<Th>Appearance</Th>
									<Th>Sticky</Th>
								</tr>
							</thead>
							<tbody>
								<For each={(FileRoutes() as Array<PageRoute>).sort((a, b) => compare(a.path, b.path))}>
									{(route) => <Route route={route} />}
								</For>
							</tbody>
						</table>
					</div>
				</Section>
			</Page>
		</div>
	);
}

export const route = {
	info: { nav: { appearance: 'extra-minimal' }, banner: { hide: true } },
} satisfies RouteDefinition;

function Route(props: { route: PageRoute }) {
	return (
		<>
			<Row route={props.route} />
			<For each={props.route.children as Array<PageRoute>}>
				{(child) => (
					<Route
						route={{ ...child, path: `${props.route.path}${child.path}`, info: { ...props.route.info, ...child.info } }}
					/>
				)}
			</For>
		</>
	);
}

function Row(props: { route: PageRoute }) {
	return (
		<tr>
			<th scope="row" class="sticky left-0 border border-neutral bg-white px-4 py-2 text-start">
				<Show
					when={!props.route.path.includes(':') && !props.route.path.includes('*')}
					fallback={<span class="text-sm font-normal italic text-neutral-700">{props.route.path}</span>}
				>
					<TextLink class="text-sm" href={props.route.path || '/'}>
						{props.route.path || '/'}
					</TextLink>
				</Show>
			</th>
			<Td>
				<Show when={props.route.info?.nav?.hero}>
					<Icon name="circle-check" class="text-green-600" />
				</Show>
			</Td>
			<Td>
				<span class="text-nowrap text-sm">{props.route.info?.nav?.appearance}</span>
			</Td>
			<Td>
				<Show when={props.route.info?.nav?.sticky}>
					<Icon name="circle-check" class="text-green-600" />
				</Show>
			</Td>
			<Td>
				<Switch fallback={<span class="text-nowrap text-sm">troon-access-upsell</span>}>
					<Match when={props.route.info?.banner?.hide}>
						<Icon name="close-circle" class="text-red-600" />
					</Match>
					<Match when={props.route.info?.banner?.slug}>
						{(banner) => <span class="text-nowrap text-sm">{banner() as string}</span>}
					</Match>
				</Switch>
			</Td>
			<Td>
				<code class="font-mono text-sm">{props.route.filePath.split('src/routes').at(-1)}</code>
			</Td>
		</tr>
	);
}

function Th(props: JSX.ThHTMLAttributes<HTMLTableCellElement> & { class?: string }) {
	return (
		<th
			{...props}
			class={twJoin('text-nowrap border border-white bg-brand-700 px-4 py-2 text-start text-white', props.class)}
		/>
	);
}

function Td(props: ParentProps) {
	return <td class="border border-neutral px-4 py-2">{props.children}</td>;
}
