import {
	Field,
	Label,
	Select,
	RadioGroup,
	RadioBar,
	RadioBarButton,
	Option,
	DialogAction,
	DialogActions,
	Errors,
	Form,
} from '@troon/ui';
import dayjs from '@troon/dayjs';
import { Switch, Match, For, createMemo, createEffect, Show } from 'solid-js';
import { useSubmission } from '@solidjs/router';
import { timeframes } from '../modules/date-formatting';
import { gql, mutationAction, useMutation } from '../graphql';
import { createFragment } from '../graphql/create-fragment';
import type { DayFragment, FragmentType } from '../graphql';

type Props = {
	facilities: Array<FragmentType<typeof TeeTimeAlertFormFragment>>;
	selectedDay?: Date;
	selectedTime?: [number, number];
	selectedPlayers?: number;
};
export function TeeTimeAlertFields(props: Props) {
	const facilities = createFragment(TeeTimeAlertFormFragment, props, 'facilities');
	const today = createMemo(() =>
		dayjs()
			.tz(facilities[0]?.timezone ?? 'UTC', true)
			.startOf('day'),
	);

	const maxDate = createMemo(() => {
		const timezone = facilities[0]?.timezone ?? 'UTC';
		return facilities
			.flatMap((facility) => facility.courses)
			.reduce((max, course) => {
				const day = course.bookingWindowDay as DayFragment;
				const date = dayjs.tz(`${day.year}-${day.month}-${day.day}`, timezone).endOf('day');
				return max.isBefore(date) ? date : max;
			}, today());
	});

	return (
		<>
			<Switch>
				<Match when={facilities.length === 1 && facilities[0]!.courses.length === 1 && facilities[0]!.courses[0]}>
					{(course) => <input type="hidden" name="courseIds" value={course().id} />}
				</Match>
				<Match when>
					<Field name="courseIds" required>
						<Label suppressRequired>Course</Label>
						<Select>
							<Switch>
								<Match when={facilities.length === 1 && facilities[0]}>
									{(facility) => (
										<>
											<Option
												value={facility()
													.courses.map(({ id }) => id)
													.join(',')}
												selected
											>
												All {facility().name} courses
											</Option>
											<optgroup label="Courses">
												<For each={facility().courses}>
													{(course) => <Option value={course.id}>{course.name}</Option>}
												</For>
											</optgroup>
										</>
									)}
								</Match>
								<Match when>
									<Option disabled selected>
										Select a course
									</Option>
									<For each={Array.from(facilities)}>
										{(facility) => (
											<Option value={facility.courses.map(({ id }) => id).join(',')}>{facility.name}</Option>
										)}
									</For>
								</Match>
							</Switch>
						</Select>
					</Field>
				</Match>
			</Switch>

			<Field name="__date">
				<Label>Date</Label>
				<Select>
					<For each={new Array((maxDate()?.diff(today(), 'days') ?? 13) + 1).fill(0).map((a, i) => i)}>
						{(i) => (
							<Option
								value={today().add(i, 'days').format('YYYY-MM-DD')}
								selected={dayjs(props.selectedDay).isSame(today().add(i, 'days'), 'day')}
							>
								{today().add(i, 'days').format('dddd, MMMM D')}
							</Option>
						)}
					</For>
				</Select>
			</Field>

			<Field name="__time">
				<Label>Time</Label>
				<Select>
					<For each={Object.entries(timeframes)}>
						{([label, [start, end]], i) => (
							<Option
								selected={
									props.selectedTime ? props.selectedTime[0] === start && props.selectedTime[1] === end : i() === 0
								}
							>
								{label}
							</Option>
						)}
					</For>
				</Select>
			</Field>

			<RadioGroup name="players">
				<Label>Players</Label>
				<RadioBar>
					<RadioBarButton checked={!props.selectedPlayers} value="-1">
						<Label>Any</Label>
					</RadioBarButton>
					<RadioBarButton value="1" checked={props.selectedPlayers === 1}>
						<Label>1</Label>
					</RadioBarButton>
					<RadioBarButton value="2" checked={props.selectedPlayers === 2}>
						<Label>2</Label>
					</RadioBarButton>
					<RadioBarButton value="3" checked={props.selectedPlayers === 3}>
						<Label>3</Label>
					</RadioBarButton>
					<RadioBarButton value="4" checked={props.selectedPlayers === 4}>
						<Label>4</Label>
					</RadioBarButton>
				</RadioBar>
			</RadioGroup>
		</>
	);
}

const TeeTimeAlertFormFragment = gql(`fragment TeeTimeAlertForm on Facility {
	name
	timezone
	courses {
		id
		name
		bookingWindowDay { year, month, day }
	}
}`);

export function TeeTimeAlertForm(props: Props & { onCancel?: () => void; onSaved?: () => void }) {
	const action = useMutation(mutation);

	const submission = useSubmission(action);

	createEffect(() => {
		if (submission.result?.data) {
			props.onSaved && props.onSaved();
			submission.clear();
		}
	});

	return (
		<Form document={createMutation} action={action}>
			<TeeTimeAlertFields
				selectedDay={props.selectedDay}
				selectedTime={props.selectedTime}
				selectedPlayers={props.selectedPlayers}
				facilities={props.facilities}
			/>

			<Errors />

			<DialogActions>
				<DialogAction type="submit">Save alert</DialogAction>
				<Show when={props.onCancel}>
					{(onCancel) => (
						<DialogAction type="button" appearance="transparent" onClick={onCancel()}>
							Cancel
						</DialogAction>
					)}
				</Show>
			</DialogActions>
		</Form>
	);
}

const createMutation = gql(`
mutation createFacilityTeeTimeAlert(
  $year: Int!
  $month: Int!
  $day: Int!
  $startAt: Int!
  $endAt: Int!
  $players: Int
  $courseIds: [String!]!
  $pushEnabled: Boolean
  $emailEnabled: Boolean
) {
  createFacilityTeeTimeAlert(
    input: {
      day: { year: $year, month: $month, day: $day }
      startAt: { hour: $startAt, minute: 0 }
      endAt: { hour: $endAt, minute: 0 }
      players: $players
      courseIds: $courseIds
      pushEnabled: $pushEnabled
      emailEnabled: $emailEnabled
    }
  ) {
    id
    state
  }
}
`);

const mutation = mutationAction(createMutation, {
	toast:
		'Your new tee time alert has been added! You’ll be notified when a tee time becomes available that matches your preferences.',
	transform: (data) => {
		const date = dayjs(data.get('__date') as string);
		const [startAt, endAt] = timeframes[data.get('__time') as keyof typeof timeframes] as [number, number];

		data.delete('__date');
		data.delete('__time');

		return {
			courseIds: (data.get('courseIds') as string)?.split(','),
			players: (data.get('players') as string) === '-1' ? undefined : parseInt(data.get('players') as string, 10),
			year: date.year(),
			month: date.month() + 1,
			day: date.date(),
			startAt,
			endAt,
			emailEnabled: true,
		};
	},
	track: {
		event: 'createTeeTimeAlert',
		transform: (data) => ({
			courseIds: (data.get('courseIds') as string)?.split(','),
			type: 'email',
			players: (data.get('players') as string) === '-1' ? undefined : parseInt(data.get('players') as string, 10),
		}),
	},
});
