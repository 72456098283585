import type { ParentProps } from 'solid-js';

export function Table(props: ParentProps) {
	return <table>{props.children}</table>;
}

export function Thead(props: ParentProps) {
	return <thead>{props.children}</thead>;
}

export function Tbody(props: ParentProps) {
	return <tbody>{props.children}</tbody>;
}

export function Tr(props: ParentProps) {
	return <tr>{props.children}</tr>;
}

export function Th(props: ParentProps) {
	return <th class="border border-white bg-brand-700 px-4 py-2 text-start text-white">{props.children}</th>;
}

export function Td(props: ParentProps) {
	return <td class="border border-neutral px-4 py-2">{props.children}</td>;
}
