import { Heading } from '@troon/ui';
import { TroonGolfLandingPages } from './_components/layout';
import type { RouteDefinition } from '@solidjs/router';
import type { ComponentProps } from 'solid-js';

export default function TroonGolfArizonaLandingPage() {
	return (
		<TroonGolfLandingPages
			title="Troon Golf Southeast"
			image="troon-golf-southeast-white.png"
			slugs={slugs}
			location="Southeast"
		>
			<Heading as="h2">Experience the best golf in the Southeast with Troon.</Heading>
			<p>
				Experience Troon Golf in the sunny Southeast. Troon Golf's unique collection of private and daily fee facilities
				in the Southeast are among the best in the region.
			</p>
		</TroonGolfLandingPages>
	);
}

const slugs: ComponentProps<typeof TroonGolfLandingPages>['slugs'] = {
	'arlington-ridge-golf-club': { tel: '352.728.4660' },
	'bent-creek-golf-course': { tel: '904.779.2100' },
	'brentwood-golf-course': { tel: '904.924.0401' },
	'burnt-store-marina-country-club': { tel: '941.637.4151' },
	'cape-royal-golf-club': { tel: '239.283.5522' },
	'chateau-elan-golf-club': { tel: '470.499.0193' },
	'eagle-landing-golf-club': { tel: '904.291.5600' },
	'fairways-country-club': { tel: '407.282.1059' },
	'gateway-park-golf-course': { tel: '334.213.9370' },
	'golf-club-of-ocala': { tel: '352.401.6917' },
	'highland-park-golf-course': { tel: '800.492.6159' },
	'ibis-landing-golf-club': { tel: '239.790.4361' },
	'king-and-bear-at-world-golf-village-resort': { tel: '800.515.1627' },
	'knoxville-municipal-golf-course': { tel: '865.691.7143' },
	'lagoon-park-golf-course': { tel: '334.240.4050' },
	'maggie-valley-club-and-resort': { tel: '800.496.3985' },
	'mystic-creek-golf-club': { tel: '870.315.8965' },
	'the-national-golf-and-country-club-at-ave-maria': { tel: '239.260.0053' },
	'ocean-club-at-hutchinson-island': { tel: '800.775.5936' },
	'pga-national-resort': { tel: '800.863.2819' },
	'saddlebrook-golf-and-tennis-resort': { tel: '813.973.1111' },
	'slammer-and-squire-at-world-golf-village-resort': { tel: '800.515.1627' },
	'st-johns-golf-country-club': { tel: '904.940.3200' },
	'stonebridge-golf-club': { tel: '706.236.5046' },
	'sun-n-lakes-golf-club': { tel: '863.385.4830' },
	'the-refuge-golf-course': { tel: '601.941.6116' },
	'the-westin-savannah-harbor-golf-resort-and-spa': { tel: '800.513.0792' },
	'tiburon-golf-club': { tel: '800.515.7881' },
	'tobacco-road-golf-course': { tel: '919.297.2257' },
	'webbs-reserve-golf-club': { tel: '941.220.4152' },
	'wellen-park-golf-and-country-club': { tel: '941.202.2539' },
	'whittle-springs-golf-course': { tel: '865.825.1022' },
};

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
