import { HttpStatusCode } from '@solidjs/start';
import { Button, Container, Heading, Link, Page, Picture, Section } from '@troon/ui';
import { createAsync } from '@solidjs/router';
import { For, Show, Suspense } from 'solid-js';
import { Title } from '@solidjs/meta';
import { Icon } from '@troon/icons';
import { NoHydration } from 'solid-js/web';
import { createContentfulListRequest } from '../../api/content/[model]/_client';
import { getConfigValue } from '../../../modules/config';
import { Hero } from '../../../components/hero/photo';
import { RichText } from '../../../components/rich-text';
import type { RouteDefinition } from '@solidjs/router';

export default function IndustryInsights() {
	const releases = createAsync(async () => getArticles(), { deferStream: true });

	return (
		<>
			<Title>Industry Insights | Management Services | Troon</Title>

			<NoHydration>
				<Hero src={`${getConfigValue('IMAGE_HOST')}/digital/hero/management-services.jpg`}>
					<Heading as="h1" class="text-center ">
						Industry Insights
					</Heading>
					<p class="text-center">Insights from industry leaders at Troon.</p>
					<Button type="button" as={Link} href="/management-services#contact" class="mx-auto size-fit grow-0">
						Get in touch <Icon name="arrow-right-md" />
					</Button>
				</Hero>
			</NoHydration>
			<Container>
				<Page>
					<Section>
						<div class="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
							<Suspense>
								<For
									each={releases()?.items}
									fallback={
										<div class="flex flex-col gap-8">
											<HttpStatusCode code={404} />
											<p>No articles found.</p>
											<Button class="w-fit" as={Link} href="/management-services">
												<Icon name="arrow-left-md" />
												Back to Management Services
											</Button>
										</div>
									}
								>
									{(article, i) => (
										<article>
											<div class="group relative flex flex-col gap-2">
												<div class="overflow-hidden rounded">
													<Show when={article.fields.heroImage?.fields}>
														{(image) => (
															<Picture
																preload={i() < 3}
																src={image().file?.url}
																alt={image().description ?? image().title ?? ''}
																width={480}
																height={270}
																sizes="(min-width: 1280px) 33vw, (min-width: 768px) 50vw, 95vw"
																class="size-full group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
																crop="face"
																loading={i() < 6 ? 'eager' : 'lazy'}
															/>
														)}
													</Show>
												</div>
												<Link
													href={`/management-services/industry-insights/${article.fields.slug}`}
													class="block text-xl font-semibold after:absolute after:inset-0 hover:underline"
												>
													{article.fields.title}
												</Link>
												<div class="[&_p]:line-clamp-2">
													<RichText document={article.fields.summary} />
												</div>
											</div>
										</article>
									)}
								</For>
							</Suspense>
						</div>
					</Section>
				</Page>
			</Container>
		</>
	);
}

const getArticles = createContentfulListRequest('articles', { 'fields.articleType': 'Industry Insights' });

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
