import { clientOnly } from '@solidjs/start';
import { Meta, Title } from '@solidjs/meta';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

const StripeProvider = clientOnly(async () => ({
	default: (await import('../../../../providers/stripe')).StripeProvider,
}));

export default function Checkout(props: RouteSectionProps) {
	return (
		<>
			<Meta name="robots" content="noindex" />
			<Title>Checkout | Troon Rewards</Title>
			<StripeProvider>{props.children}</StripeProvider>
		</>
	);
}

export const route = { info: { nav: { appearance: 'minimal' } } } satisfies RouteDefinition;
