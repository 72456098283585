import { createStore, produce } from 'solid-js/store';
import { For, Match, Show, Switch, createEffect, createSignal, onCleanup } from 'solid-js';
import { Navigate, useLocation } from '@solidjs/router';
import { isServer } from 'solid-js/web';
import { ActivityIndicator, CheckList, CheckListItem } from '@troon/ui';
import { twJoin } from '@troon/tailwind-preset/merge';
import { useUser } from '../../providers/user';
import { ErrorBoundary } from '../../components/error-boundary';
import { Login } from './flow/01-login';
import { UserExists } from './flow/00-user-exists';
import { CreatePassword } from './flow/01-create-password';
import { Name } from './flow/02-name';
import { PostalCode } from './flow/03-postalcode';
import { ForgotPassword } from './flow/02-forgot-code';
import { AuthFlowContext, defaultStore, flowFromStep, flows } from './flow';
import { ResetNewPassword } from './flow/03-new-password';
import { MagicLink } from './flow/02-magic-link';
import { Join } from './flow/02-join';
import { StepHeader } from './step-header';
import type { JSX } from 'solid-js';
import type { FlowStep, StoreState } from './flow';

export { useAuthStore } from './flow';

type Props = {
	data?: Record<string, string | undefined>;
	descriptions?: Record<FlowStep, () => JSX.Element>;
	headers?: Record<FlowStep, () => JSX.Element>;
	headings?: Record<FlowStep, string>;
	onComplete?: () => void;
	redirect?: string;
	inline?: boolean;
	isPage?: boolean;
	showSteps?: boolean;
};

export function AuthFlow(props: Props) {
	const user = useUser();
	const location = useLocation();
	const [authStore, setAuthStore] = createStore<StoreState>({
		...defaultStore,
		// eslint-disable-next-line solid/reactivity
		data: { ...defaultStore.data, ...props.data } as Record<string, string>,
		flow: flowFromStep(removeTrailingSlash(location.pathname)) ?? defaultStore.flow,
		redirect:
			// eslint-disable-next-line solid/reactivity
			props.redirect ?? (removeTrailingSlash(location.pathname.startsWith('/auth/') ? '/' : location.pathname) || '/'),
		step: location.pathname.startsWith('/auth/') ? removeTrailingSlash(location.pathname) : '/auth',
	});
	const [content, setContentRef] = createSignal<HTMLDivElement>();

	function handleComplete() {
		!props.inline && history.replaceState({}, '', authStore.redirect);
		if (props.onComplete) {
			props.onComplete();
		}
	}

	createEffect(() => {
		if (!isServer) {
			!props.inline && history.replaceState({}, '', authStore.step);
			setAuthStore(
				produce((state) => {
					state.highestStepIndex = Object.keys(flows[state.flow]).indexOf(state.step);
				}),
			);
		}
	});

	onCleanup(() => {
		if (!isServer && typeof history !== 'undefined') {
			!props.inline && history.replaceState({}, '', authStore.redirect ?? '/');
			setAuthStore({ flow: 'login', step: '/auth', data: {} });
		}
	});

	createEffect(() => {
		const contentEl = content();
		if (contentEl && authStore.step) {
			const autofocus = contentEl.querySelector('[autofocus]');
			const [firstFocusable] = contentEl.querySelectorAll('input:not([type=hidden]),textarea,button,a') ?? [];
			((autofocus ?? firstFocusable) as HTMLInputElement | undefined)?.focus();
		}
	});

	return (
		<div class={twJoin('grid w-full grid-cols-12', !props.isPage && 'max-w-4xl')}>
			<div
				ref={setContentRef}
				class={twJoin('col-span-12 flex flex-col', !props.inline ? 'gap-8 p-8 md:col-span-7 md:p-12' : 'gap-4')}
			>
				<AuthFlowContext.Provider value={[authStore, setAuthStore]}>
					<ErrorBoundary>
						<Show
							when={!user()}
							fallback={
								<>
									<ActivityIndicator>Logging in…</ActivityIndicator>
									<Navigate href={authStore.redirect.startsWith('/auth') ? '/' : authStore.redirect} />
								</>
							}
						>
							<StepHeader
								header={props.headers && props.headers[authStore.step]}
								heading={props.headings && props.headings[authStore.step]}
								description={props.descriptions && props.descriptions[authStore.step]}
							/>
							<div class="flex flex-col gap-4">
								<Switch fallback={<Navigate href="/" />}>
									<Match when={authStore.step === '/auth'}>
										<UserExists
											nextNewUser={props.inline ? '/auth/join' : '/auth/join/password'}
											nextReturningUser={props.inline ? '/auth/login' : '/auth/login'}
										/>
									</Match>
									<Match when={authStore.step === '/auth/login'}>
										<Login onComplete={handleComplete} />
									</Match>
									<Match when={authStore.step === '/auth/magic-link'}>
										<MagicLink onComplete={handleComplete} />
									</Match>
									<Match when={authStore.step === '/auth/reset'}>
										<ForgotPassword />
									</Match>
									<Match when={authStore.step === '/auth/reset/password'}>
										<ResetNewPassword />
									</Match>
									<Match when={authStore.step === '/auth/join/password'}>
										<CreatePassword />
									</Match>
									<Match when={authStore.step === '/auth/join/name'}>
										<Name />
									</Match>
									<Match when={authStore.step === '/auth/join/postal-code'}>
										<PostalCode onComplete={handleComplete} />
									</Match>
									<Match when={authStore.step === '/auth/join'}>
										<Join onComplete={handleComplete} />
									</Match>
								</Switch>
							</div>
						</Show>

						<Show when={props.showSteps !== false && authStore.step !== '/auth' && authStore.step !== '/auth/login/'}>
							<ul aria-label="Progress" class="flex flex-row justify-center gap-2">
								<For each={Object.entries(flows[authStore.flow])}>
									{([key, label], index) => (
										<li
											aria-current={
												Object.keys(flows[authStore.flow]).indexOf(authStore.step) === index() ? 'step' : undefined
											}
											class="text-brand-100 aria-current-step:text-brand"
										>
											<button
												class="group size-8"
												onClick={() => {
													setAuthStore({ step: key });
												}}
												disabled={index() >= authStore.highestStepIndex}
											>
												<span class="block h-1 bg-current group-hover:enabled:bg-brand-700" />
												<span class="sr-only">
													{/* @ts-expect-error this is okay */}
													Step {index() + 1}: {label.heading}
												</span>
											</button>
										</li>
									)}
								</For>
							</ul>
						</Show>
					</ErrorBoundary>
				</AuthFlowContext.Provider>
			</div>
			<Show when={!props.inline}>
				<div class="relative col-span-5 hidden flex-col justify-center gap-8 bg-gradient-to-r from-neutral-950 to-brand-700 px-12 py-8 text-white md:flex">
					<img src="/assets/images/topo-bg-bottom.svg" alt="" class="absolute inset-x-0 bottom-0" loading="lazy" />
					<h3 class="sr-only">Benefits</h3>
					<CheckList color="current">
						<CheckListItem>Book tee times at 150+ premier courses around the world.</CheckListItem>
						<CheckListItem>Set tee time alerts for desired courses and times.</CheckListItem>
						<CheckListItem>Invite your friends to join your tee times.</CheckListItem>
						<CheckListItem>Automatically earn Troon Rewards points that you can redeem for free rounds.</CheckListItem>
					</CheckList>
				</div>
			</Show>
		</div>
	);
}

function removeTrailingSlash(input: string) {
	return input.endsWith('/') ? input.replace(/\/$/, '') : input;
}
