import { Button, Heading, Link, Picture } from '@troon/ui';
import { Show } from 'solid-js';
import { Icon } from '@troon/icons';
import { gql } from '../../graphql';
import { createFragment } from '../../graphql/create-fragment';
import { courseTypeString } from './type-strings';
import type { FragmentType } from '../../graphql';

type Props = { facility: FragmentType<typeof FacilityListCardFragment> };

export function FacilityListCard(props: Props) {
	const facility = createFragment(FacilityListCardFragment, props, 'facility');
	return (
		<div class="group relative flex items-start gap-4 rounded border border-neutral p-4 hover:bg-neutral-100 motion-safe:transition-colors motion-safe:duration-200 md:items-center md:gap-6">
			<Show when={facility.metadata?.hero?.url}>
				{(url) => (
					<div class="shrink-0 overflow-hidden rounded">
						<Picture
							src={url()}
							width={200}
							height={150}
							sizes="200px"
							class="size-24 object-cover group-hover:scale-110 motion-safe:transition-transform motion-safe:duration-200 md:size-full"
							alt=""
							loading="lazy"
						/>
					</div>
				)}
			</Show>
			<div class="flex grow flex-col gap-1.5 md:gap-2">
				<Heading as="h3" size="h5">
					<Link href={`/course/${facility.slug}`} class="after:absolute after:inset-0" preload>
						{facility.name}
					</Link>
				</Heading>
				<span class="order-first text-sm uppercase text-neutral-800">{courseTypeString[facility.type]}</span>
				<Show when={facility.metadata?.address}>
					{(address) => (
						<address aria-label={`Address for ${facility.name}`} class="text-sm not-italic">
							<Show when={address().street}>
								<span itemprop="streetAddress">{address().street}</span>,{' '}
							</Show>
							<Show when={address().city}>
								<span itemprop="addressLocality">{address().city}</span>,{' '}
							</Show>
							<Show when={address().state}>
								<span itemprop="addressRegion">{address().state}</span>{' '}
							</Show>
							<Show when={address().postalCode}>
								<span itemprop="postalCode">{address().postalCode}</span>
							</Show>
						</address>
					)}
				</Show>
			</div>
			<div class="hidden flex-row gap-3 xl:flex">
				<Button
					as={Link}
					appearance="secondary"
					href={`/course/${facility.slug}`}
					class="relative z-10 w-fit grow-0"
					preload
				>
					<Icon name="info" />
					<span class="sr-only">View {facility.name}</span>
				</Button>
			</div>
		</div>
	);
}

const FacilityListCardFragment = gql(`fragment FacilityListCard on Facility {
	slug
	name
	type
	isAvailable
	metadata {
		address { street, city, state, postalCode }
		hero { url }
	}
}`);
