import { useNavigate } from '@solidjs/router';
import { Button, ActivityIndicator } from '@troon/ui';
import { createSignal, Show } from 'solid-js';
import { withScope } from '@sentry/solidstart';
import { StripeClientSecretIntentType } from '../../graphql';
import { getBaseUrl } from '../../modules/schema/base-url';
import { useStripe } from '../../providers/stripe';
import type { ParentProps } from 'solid-js';
import type { StripeError } from '@stripe/stripe-js';

type SubmitProps = ParentProps<{
	disabled?: boolean;
	onError?: (error?: StripeError | undefined) => void;
	onSubmitting?: (submitting?: boolean) => void;
	productId: string;
	redirectPath: string;
	troonCardSubscriptionId?: string;
	stripeClientSecretIntentType?: StripeClientSecretIntentType;
	stripeClientSecret?: string;
}>;

export function StripeSubmit(props: SubmitProps) {
	const { elements, stripe } = useStripe();
	const navigate = useNavigate();
	const [submitting, setSubmitting] = createSignal(false);

	return (
		<Button
			type="button"
			disabled={!elements() || props.disabled || !props.troonCardSubscriptionId}
			onClick={(event) => {
				event.preventDefault();
				const fn = async () => {
					const { error: submitError } = await elements()!.submit();
					if (submitError) {
						props.onError && props.onError(submitError);
						return;
					}

					const returnUrl = new URL(props.redirectPath, getBaseUrl());
					returnUrl.searchParams.set('subscriptionId', props.troonCardSubscriptionId!);
					returnUrl.searchParams.set('productId', props.productId);

					props.onSubmitting && props.onSubmitting(true);
					setSubmitting(true);

					let error: StripeError | undefined;

					if (props.stripeClientSecretIntentType === StripeClientSecretIntentType.Setup) {
						const res = await stripe()!.confirmSetup({
							elements: elements()!,
							clientSecret: props.stripeClientSecret ?? '',
							confirmParams: {
								return_url: returnUrl.toString(),
							},
							redirect: 'if_required',
						});
						error = res.error;
					} else {
						const res = await stripe()!.confirmPayment({
							elements: elements()!,
							clientSecret: props.stripeClientSecret ?? '',
							confirmParams: {
								return_url: returnUrl.toString(),
							},
							redirect: 'if_required',
						});
						error = res.error;
					}

					props.onSubmitting && props.onSubmitting(false);
					setSubmitting(false);

					if (error) {
						withScope((scope) => {
							scope.setExtras({ ...error });
							scope.captureException(new Error('Stripe confirm error'));
						});
						props.onError && props.onError(error);
					} else {
						props.onError && props.onError();
						navigate(`${returnUrl.pathname}?${returnUrl.searchParams.toString()}`);
					}
				};

				fn();
			}}
		>
			<Show when={submitting()}>
				<ActivityIndicator aria-label="Loading" class="me-2 size-4" />
			</Show>
			{props.children}
		</Button>
	);
}
