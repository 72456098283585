import { For, createEffect, createSignal } from 'solid-js';
import { isServer } from 'solid-js/web';
import { Picture } from '@troon/ui';
import { twJoin } from '@troon/tailwind-preset/merge';
import type { ParentProps } from 'solid-js';

type Props = ParentProps & {
	images: Array<{ url: string; alt?: string }>;
};

export function Carousel(props: Props) {
	let timeout: NodeJS.Timeout;
	let prevTimeout: NodeJS.Timeout;

	const [running, setRunning] = createSignal(!isServer);
	const [current, setCurrent] = createSignal(0);
	// eslint-disable-next-line solid/reactivity
	const [prev, setPrev] = createSignal(props.images.length - 1);

	createEffect(() => {
		if (props.images.length && running()) {
			const curr = current();
			timeout = setTimeout(() => {
				clearTimeout(prevTimeout);
				setPrev(curr);
				setCurrent(curr + 1 >= props.images.length ? 0 : curr + 1);
			}, 6000);
		}
	});

	// Little hack to set the prewvious image to 0% opacity after it has faded out
	createEffect(() => {
		if (prev() >= 0) {
			prevTimeout = setTimeout(() => {
				setPrev(-1);
			}, 1500);
		}
	});

	return (
		<div class="relative flex aspect-video flex-col items-center overflow-hidden rounded">
			<For each={props.images}>
				{(img, index) => (
					<Picture
						src={img.url}
						alt={img.alt ?? ''}
						width={512}
						height={320}
						sizes="(min-width: 1024px) 50vw, (min-width: 768px) 75vw, 95vw"
						class={twJoin(
							'absolute size-full bg-center object-cover ease-out motion-safe:transition-all motion-safe:duration-500',
							index() !== current() && index() !== prev() && 'z-0 opacity-0',
							index() === prev() && 'delay-200',
							index() === current() ? 'z-20 opacity-100' : '',
						)}
						loading="lazy"
					/>
				)}
			</For>
			<ul
				aria-label="Photo list"
				class="absolute inset-x-0 bottom-2 z-40 flex flex-row justify-center gap-2"
				onMouseEnter={() => {
					clearTimeout(timeout);
					setRunning(false);
				}}
				onMouseLeave={() => {
					setRunning(true);
				}}
			>
				<For each={props.images}>
					{(img, index) => (
						<li
							aria-current={index() === current()}
							class="text-white/40 aria-current:text-white motion-safe:transition-colors motion-safe:duration-500"
						>
							<button
								class="group size-8"
								onClick={() => {
									clearTimeout(timeout);
									setPrev(current());
									setCurrent(index());
								}}
							>
								<span class="block h-1 rounded bg-current group-hover:bg-white" />
								<span class="sr-only">Photo {index() + 1}</span>
							</button>
						</li>
					)}
				</For>
			</ul>
		</div>
	);
}
