import { CheckboxInternal, DialogContent, Dialog, Input, PillButton } from '@troon/ui';
import { Popover } from '@kobalte/core/popover';
import { Combobox } from '@kobalte/core/combobox';
import { createMemo, Show } from 'solid-js';
import { createWindowSize } from '@solid-primitives/resize-observer';
import { Icon } from '@troon/icons';
import { gql } from '../../../graphql';
import { createFragment } from '../../../graphql/create-fragment';
import type { FragmentType } from '../../../graphql';

type MainProps = {
	facilities: Array<FragmentType<typeof FacilityFilterFragment>> | undefined;
	onSelect: (items: Array<string>) => void;
	selected?: Array<string>;
};

type Props = MainProps & {
	allSelected: boolean;
};

type Item = { label: string; value: string };
type Group = { label: string; group: Array<Item> };

export function FacilityFilterPill(props: MainProps) {
	const size = createWindowSize();
	const allSelected = createMemo(() => props.facilities?.length === props.selected?.length);

	return (
		<Show when={size.width > 720} fallback={<MobilePill {...props} allSelected={allSelected()} />}>
			<LargeScreenPill {...props} allSelected={allSelected()} />
		</Show>
	);
}

function LargeScreenPill(props: Props) {
	return (
		<Popover sameWidth placement="bottom-start">
			<Popover.Trigger as={PillButton} aria-pressed={!props.allSelected}>
				Courses<Show when={!props.allSelected}> ({props.selected?.length})</Show>
				<Icon name="caret-down-md" />
			</Popover.Trigger>
			<Popover.Portal>
				<Popover.Content class="z-50 flex w-full flex-col rounded border border-neutral-300 bg-white shadow-md animate-out fade-out zoom-out slide-out-to-top-16 ui-expanded:animate-in ui-expanded:fade-in ui-expanded:zoom-in ui-expanded:slide-in-from-top-16">
					<Selector {...props} />
				</Popover.Content>
			</Popover.Portal>
		</Popover>
	);
}

function MobilePill(props: Props) {
	return (
		<Dialog key="courses-filter">
			<Dialog.Trigger as={PillButton} aria-pressed={!props.allSelected}>
				Courses<Show when={!props.allSelected}> ({props.selected?.length})</Show>
			</Dialog.Trigger>
			<DialogContent header="Select courses" headerLevel="h3">
				<div class="-mx-6 -mt-4">
					<Selector {...props} />
				</div>
			</DialogContent>
		</Dialog>
	);
}

function Selector(props: Props) {
	const facilities = createFragment(FacilityFilterFragment, props, 'facilities');
	const options = createMemo(() => [
		{
			label: 'Courses',
			group: (facilities ?? []).map((facility) => {
				return {
					label: facility.name,
					value: facility.slug,
				};
			}),
		},
	]);
	return (
		<Combobox<Item, Group>
			value={(facilities ?? []).reduce((memo, facility) => {
				if (props.selected?.includes(facility.slug)) {
					memo.push({
						label: facility.name,
						value: facility.slug,
					});
				}
				return memo;
			}, [] as Array<Item>)}
			options={options()}
			triggerMode="focus"
			optionTextValue="label"
			optionLabel="label"
			optionValue="value"
			optionGroupChildren="group"
			open
			defaultOpen
			multiple
			onChange={(items) => props.onSelect(items.map((item) => item.value))}
			sectionComponent={(groupProps) => {
				// Important: reactivity breaks without this pulled out
				const allSelected = props.allSelected;
				return (
					<Combobox.Section class="flex justify-between pb-2 text-sm">
						<div>{groupProps.section.rawValue.label}</div>
						<button
							class="text-brand underline-offset-2 transition-colors duration-200 hover:underline hover:decoration-solid focus-visible:ring-1 focus-visible:ring-brand-500 focus-visible:ring-offset-2 active:text-brand-700"
							onClick={() => {
								props.onSelect(
									allSelected
										? []
										: (facilities?.map((facility) => {
												return facility.slug;
											}) ?? []),
								);
							}}
						>
							<Show when={allSelected} fallback="Select all">
								Deselect all
							</Show>
						</button>
					</Combobox.Section>
				);
			}}
			itemComponent={(itemProps) => (
				<Combobox.Item
					{...itemProps}
					class="flex cursor-pointer flex-row items-center justify-start gap-2 rounded p-2 ui-highlighted:bg-neutral-200"
				>
					<Combobox.ItemIndicator forceMount class="relative -top-1.5">
						<CheckboxInternal role="presentation" checked={props.selected?.includes(itemProps.item.rawValue.value)} />
					</Combobox.ItemIndicator>
					<Combobox.ItemLabel>{itemProps.item.rawValue.label}</Combobox.ItemLabel>
				</Combobox.Item>
			)}
		>
			<div class="border-b border-neutral p-2">
				<span class="sr-only">
					<Combobox.Label>Courses</Combobox.Label>
				</span>
				<Combobox.Control>
					<Combobox.Input
						placeholder="Search courses…"
						prefixElement={<Icon name="search-magnifying-glass" />}
						as={Input}
						class="rounded-none border-0 ps-8 focus-visible:ring-0"
					/>
				</Combobox.Control>
			</div>
			<Combobox.Listbox class="p-4" />
		</Combobox>
	);
}

const FacilityFilterFragment = gql(`fragment FacilityFilter on Facility {
	name
	slug
}`);
