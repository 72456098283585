import { Show } from 'solid-js';
import { useIsEnabled } from '@troon/analytics';
import { TeeTimeSearchPage as Control } from './_variants/control';
import { TeeTimeSearchPage as Redesign } from './_variants/redesign';
import type { RouteDefinition } from '@solidjs/router';

export default function TeeTimes() {
	const showRedesign = useIsEnabled('tee-times-redesign-202401', false, false);

	return (
		<Show when={showRedesign} fallback={<Control />}>
			<Redesign />
		</Show>
	);
}

export const route = { info: { nav: { sticky: false } } } satisfies RouteDefinition;
