import { gql } from '../../../graphql';
import { cachedQuery } from '../../../graphql/cached-get';

// TODO: when this data is fixed in the DB, get it from there
export const requestClubs: Record<string, Array<string>> = {
	'alpine-country-club-ut': ['dining', 'pool'],
	'bahia-beach-resort-and-golf-club': ['dining', 'pool', 'tennis'],
	'bayside-resort-golf-club': ['dining', 'tennis'],
	'blackstone-country-club-at-vistancia': ['dining'],
	'bookcliff-country-club': ['dining', 'fitness', 'pool', 'tennis'],
	'boulders-resort-and-spa-scottsdale': ['dining'],
	'calvert-crossing-golf-club': ['dining', 'pool', 'tennis'],
	'capital-canyon-club': [],
	'champion-hills': ['dining', 'fitness', 'pool', 'tennis'],
	'cimarron-hills-golf-and-country-club': ['dining'],
	'columbia-country-club-1': ['dining', 'pool'],
	'columbus-country-club-2': ['dining', 'pool', 'tennis'],
	'contra-costa-country-club': [],
	'the-dunes-of-naples': [],
	'el-macero-country-club': ['dining', 'pool', 'tennis'],
	'entrada-at-snow-canyon-country-club': [],
	'fairwood-golf-and-country-club': ['dining', 'fitness', 'pool'],
	'grande-dunes-ocean-club': ['dining'],
	'john-newcombe-country-club': ['fitness', 'pool', 'tennis'],
	'lake-of-isles': ['dining'],
	'lakeshore-yacht-and-country-club': ['dining', 'pool'],
	'manchester-country-club': [],
	'marin-country-club': ['dining', 'pool', 'tennis'],
	'ocean-edge-resort-and-golf-club': ['dining', 'fitness', 'pool', 'tennis'],
	'pinetop-country-club': ['dining', 'tennis'],
	'prescott-golf-club': [],
	'pronghorn-at-juniper-preserve': ['dining'],
	'salina-country-club': ['dining', 'fitness', 'pool', 'tennis'],
	'talking-rock': [],
	tetherow: ['fitness', 'pool'],
	'the-club-at-castlewood': [],
	'the-club-at-porto-cima': ['dining', 'pool'],
	'the-club-at-savannah-quarters': ['dining', 'fitness', 'pool', 'tennis'],
	'the-peninsula-golf-and-country-club': ['dining', 'fitness', 'pool', 'tennis'],
	'the-powder-horn-golf-club': ['dining', 'fitness', 'pool', 'tennis'],
	'the-ritz-carlton-key-biscayne': ['tennis'],
	'the-westin-la-paloma-resort-and-spa': ['dining', 'fitness', 'pool', 'tennis'],
	'tumble-brook-country-club': ['dining', 'pool', 'tennis'],
	'tyler-athletic-and-swim': ['fitness', 'pool', 'tennis'],
	'vanderbilt-legends-club': ['dining'],
	'westchester-hills-golf-club': ['dining', 'fitness', 'pool'],
	'westmoreland-country-club': ['dining', 'pool'],
};

const query = gql(`query amenitiesFacilities($slugs: [String!]) {
	facilities: facilitiesV3(idOrSlugs: $slugs, sortBy: name) {
		facilities {
			...FacilityByState
			name
			slug
			metadata {
		  	dining
		  	fitness
		  	tennis
		  	spa
		  	swimming
			}
		}
	}
}`);

export const getFacilities = () => cachedQuery(query)({ slugs: Object.keys(requestClubs) });
