import { clientOnly } from '@solidjs/start';
import {
	Container,
	Details,
	Heading,
	Page,
	Section,
	SectionStep,
	Summary,
	TextLink,
	TroonAccessPlusProduct,
	TroonAccessProduct,
} from '@troon/ui';
import { createAsync, useAction, useSubmission } from '@solidjs/router';
import { createEffect, createSignal, For, Match, Show, Switch } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Icon } from '@troon/icons';
import { Grid, GridFive, GridSeven } from '../../../../components/layouts/grid';
import { gql, TroonCardSubscriptionProductType, useMutation } from '../../../../graphql';
import { useUtmParams } from '../../../../providers/utm';
import { authHeaders, setupIntent } from '../../checkout/[id]';
import { useUser } from '../../../../providers/user';
import { objectToFormData } from '../../../../graphql/form-data';
import { useStripe } from '../../../../providers/stripe';
import { StripeSubmit } from '../../../../components/stripe/submit';
import { Elements } from '../../../../components/stripe/elements';
import { getBaseUrl } from '../../../../modules/schema/base-url';
import { AuthFlow } from '../../../../partials/auth/auth';
import { SupportButton } from '../../../../components/support';
import { createFragment } from '../../../../graphql/create-fragment';
import type { FragmentType, GiftProductFragment } from '../../../../graphql';
import type { StripeError } from '@stripe/stripe-js';

type Props = {
	giftProduct: FragmentType<typeof GiftProductFragment>;
	redemptionCode: string;
};

const StripeProvider = clientOnly(async () => ({
	default: (await import('../../../../providers/stripe')).StripeProvider,
}));

export function RedeemWithCode(props: Props) {
	return (
		<StripeProvider mode="subscription">
			<Internal {...props} />
		</StripeProvider>
	);
}

function Internal(props: Props) {
	const user = useUser();
	const utm = useUtmParams();
	const createIntent = useMutation(setupIntent);
	const runCreateIntent = useAction(createIntent);
	const intent = useSubmission(createIntent);
	const { setAmount } = useStripe();
	const [errors, setErrors] = createSignal<StripeError | undefined>();
	const giftProduct = createFragment(GiftProductFragment, props, 'giftProduct');

	createEffect(() => {
		const totalAmount = intent.result?.data?.subscription.totalAmount;
		if (totalAmount) {
			setAmount({ ...totalAmount, cents: totalAmount.cents! });
		}
	});

	createAsync(
		async () => {
			if (!user()?.me || intent.pending || intent.result?.data?.subscription) {
				return;
			}

			const data = objectToFormData({
				productId: giftProduct.id,
				promoCode: props.redemptionCode,
				utm: utm(),
			});

			const res = await runCreateIntent(data);
			return res;
		},
		{ deferStream: false },
	);

	return (
		<>
			<div class="overflow-hidden lg:hidden">
				<ProductInfo {...giftProduct} code={props.redemptionCode} />
			</div>
			<Container>
				<Page>
					<Grid>
						<GridSeven class="flex flex-col gap-12">
							<Section>
								<Heading as="h1">Redeem your gift</Heading>
								<p>Complete the steps below to set up your account and redeem your gift.</p>
							</Section>
							<Switch>
								<Match when={intent.result?.error}>
									{(error) => (
										<Section class="text-red-600">
											<Heading as="h2">
												<Icon name="square-warning" /> Error
											</Heading>
											<p>
												{(error().graphQLErrors[0]?.extensions.displayMessage as string) ||
													'An error occurred retrieving your gift. Please try again.'}
											</p>
											<SupportButton appearance="primary" class="size-fit" />
										</Section>
									)}
								</Match>
								<Match when>
									<Section>
										<SectionStep step={1} state={!user() ? 'current' : 'completed'}>
											<Show when={!user()} fallback="Account information">
												Log in or Sign up
											</Show>
										</SectionStep>

										<Show when={user()} fallback={<AuthFlow inline headers={authHeaders} showSteps={false} />}>
											{(user) => (
												<ul class="flex flex-col gap-1">
													<li>
														{user().me.firstName} {user().me.lastName}
													</li>
													<li>{user().me.email}</li>
													<li>{user().me.troonRewardsId}</li>
												</ul>
											)}
										</Show>
									</Section>
									<Section>
										<SectionStep step={2} state={!user() || !intent.result?.data?.subscription ? 'waiting' : 'current'}>
											Confirm
										</SectionStep>
										<Show when={user() && intent.result?.data?.subscription}>
											<p>
												Your card will not be charged. We use your payment method to make sure you’re eligible to use
												the gift card in your region.
											</p>
										</Show>
										<Elements />
										<Show when={user() && intent.result?.data?.subscription}>
											<Show when={errors()}>
												{(error) => (
													<p class="text-red-500" aria-live="assertive">
														There was a problem completing the request: {error().message}
													</p>
												)}
											</Show>

											<StripeSubmit
												productId={giftProduct.id!}
												redirectPath={'/access/checkout/confirm'}
												onError={(errors) => setErrors(errors)}
												stripeClientSecret={intent.result?.data?.subscription.stripeClientSecret}
												stripeClientSecretIntentType={intent.result?.data?.subscription.stripeClientSecretIntentType}
												troonCardSubscriptionId={intent.result?.data?.subscription.troonCardSubscriptionId}
											>
												Redeem
											</StripeSubmit>

											<p class="text-center text-sm">
												By clicking “Redeem”, you are agreeing to Troon’s{' '}
												<TextLink target="_blank" href="/access/terms">
													Terms of Service
												</TextLink>{' '}
												and{' '}
												<TextLink target="_blank" href="https://troon.com/privacy-policy/">
													Privacy Policy
												</TextLink>
												.
											</p>
										</Show>
									</Section>
								</Match>
							</Switch>
						</GridSeven>

						<GridFive>
							<div class="overflow-y-hidden border-neutral lg:rounded lg:border">
								<div class="hidden overflow-hidden lg:block">
									<ProductInfo {...giftProduct} code={props.redemptionCode} />
								</div>
								<Show when={giftProduct.disclosure}>
									{(disclosure) => <p class="text-sm font-normal">{disclosure()}</p>}
								</Show>
							</div>
						</GridFive>
					</Grid>
				</Page>
			</Container>
		</>
	);
}

function ProductInfo(props: GiftProductFragment & { code: string }) {
	return (
		<div
			class={twJoin(
				'relative overflow-hidden p-6 @container',
				props.type === TroonCardSubscriptionProductType.TroonAccessPlus &&
					'bg-gradient-to-r from-neutral-950 to-brand-700 text-white',
			)}
		>
			<Icon
				name="logo-square"
				class="absolute -right-8 -top-12 z-0 size-56 origin-center -rotate-12 text-brand opacity-20 gradient-mask-b-10"
			/>
			<img src={`${getBaseUrl()}/assets/images/gift.svg`} alt="" class="absolute right-0 start-1/3 top-0 z-0" />
			<div class="relative z-0 flex flex-col gap-6 text-base">
				<p>
					<Show
						when={props.type === TroonCardSubscriptionProductType.TroonAccessPlus}
						fallback={<TroonAccessProduct class="h-8" />}
					>
						<TroonAccessPlusProduct class="h-8" current />
					</Show>
					<span class="sr-only">{props.subscriptionName}</span>
				</p>
				<p class="text-2xl font-semibold @md:text-3xl @lg:text-4xl">
					<span>{props.code}</span>
				</p>
				<p>
					<Icon name="check" /> Gift code applied
				</p>

				<hr
					class={props.type === TroonCardSubscriptionProductType.TroonAccessPlus ? 'border-white/20' : 'border-neutral'}
				/>

				<Details>
					<Summary>Membership details</Summary>

					<div class="flex flex-col gap-4">
						<ul class="flex flex-col gap-2">
							<For each={props.valueProps}>
								{(value) => (
									<li class="flex flex-row items-center gap-2">
										<Icon name="check" class="shrink-0 text-xl text-brand" />
										{value}
									</li>
								)}
							</For>
						</ul>
					</div>
				</Details>
			</div>
		</div>
	);
}

const GiftProductFragment = gql(`fragment GiftProduct on TroonCardSubscriptionProduct {
  id
  subscriptionName
  description
  valueProps
  autoRenew
  disclosure
  type
}`);
