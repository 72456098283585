import { createEffect, createMemo, createSignal, Match, Show, Switch, useContext } from 'solid-js';
import {
	ActivityIndicator,
	TextLink,
	Container,
	HorizontalRule,
	SectionStep,
	Page,
	Heading,
	TextField,
	Label,
	Input,
	FieldDescription,
	Button,
	Form,
	HiddenFields,
	Section,
	LinkButton,
	Errors,
} from '@troon/ui';
import { useParams, useSubmissions } from '@solidjs/router';
import { Icon } from '@troon/icons';
import { clientOnly } from '@solidjs/start';
import { Title } from '@solidjs/meta';
import { produce } from 'solid-js/store';
import dayjs from '@troon/dayjs';
import { gql, mutationAction, StripeClientSecretIntentType, useMutation } from '../../../graphql';
import { Grid, GridFive, GridSeven } from '../../../components/layouts/grid';
import { AccessProductsCtx } from '../../../providers/card';
import { useStripe } from '../../../providers/stripe';
import { NotFoundContent } from '../../../partials/404';
import { usePersisted } from '../../../providers/persistence-store';
import { ErrorBoundary } from '../../../components/error-boundary';
import { useUtmParams } from '../../../providers/utm';
import { StripeSubmit } from '../../../components/stripe/submit';
import { Elements } from '../../../components/stripe/elements';
import { Receipt } from '../checkout/_components/receipt';
import { ProductInfo } from '../checkout/_components/product-info';
import { PromoCodeEntry } from '../checkout/_components/promo-code-entry';
import type { RouteDefinition } from '@solidjs/router';
import type { StripeError } from '@stripe/stripe-js';

const StripeProvider = clientOnly(async () => ({
	default: (await import('../../../providers/stripe')).StripeProvider,
}));

export default function GiftCheckout() {
	return (
		<StripeProvider mode="payment">
			<GiftCheckoutInternal />
		</StripeProvider>
	);
}

function GiftCheckoutInternal() {
	const params = useParams<{ id: string }>();
	const products = useContext(AccessProductsCtx);
	const [errors, setErrors] = createSignal<StripeError | undefined>();
	const [enteredCode, setEnteredCode] = createSignal(false);
	const [persisted, setPersisted] = usePersisted();
	const utm = useUtmParams();
	const [formRef, setFormRef] = createSignal<HTMLFormElement>();
	const [step, setStep] = createSignal(0);

	const { setAmount, stripe } = useStripe();

	const productInfo = createMemo(() => {
		return products()?.find((card) => card.id === params.id);
	});

	const createIntent = useMutation(setupIntent);
	// const runCreateIntent = useAction(createIntent);
	const subs = useSubmissions(createIntent);
	const currentSub = createMemo(() => subs[subs.length - 1]);
	const sub = createMemo(
		() => subs.findLast((sub) => !sub.pending && !sub.error && !sub.result?.error) ?? subs[subs.length - 1],
	);

	createEffect(() => {
		if (
			persisted.promoCode?.value &&
			currentSub()?.result?.error?.graphQLErrors?.find((e) => {
				return (
					typeof e.extensions?.displayMessage === 'string' &&
					e.extensions.displayMessage.toLowerCase().includes('promo code')
				);
			})
		) {
			setPersisted(
				produce((s) => {
					delete s.promoCode;
				}),
			);
		}
	});

	createEffect(() => {
		const total = currentSub()?.result?.data?.gift.totalAmount;
		if (total) {
			setStep(2);
			setAmount({ ...total, cents: total.cents! });
		}
	});

	return (
		<Page>
			<Show when={productInfo()} fallback={<NotFoundContent />}>
				{(productInfo) => (
					<>
						<Title>Checkout | Gift Troon Access | Troon</Title>
						<div class="-mb-6 -mt-8 md:-mb-12 lg:hidden">
							<ProductInfo product={productInfo()} isGift />
						</div>
						<Container>
							<Grid>
								<GridSeven class="flex flex-col gap-12">
									<Heading as="h1" size="h2" class="sr-only md:not-sr-only">
										Gift Troon Access
									</Heading>

									<Form ref={setFormRef} action={createIntent} document={query} class="flex flex-col gap-12">
										<HiddenFields
											data={{
												utm: utm(),
												id: sub()?.result?.data?.gift.id,
												productId: sub()?.result?.data?.gift.id ? undefined : params.id,
												promoCode: currentSub()?.result?.data?.gift.promoCode,
											}}
										/>

										<Section>
											<SectionStep
												step={1}
												state={step() === 0 ? 'current' : 'completed'}
												action={step() > 0 ? <LinkButton onClick={() => setStep(0)}>Edit</LinkButton> : null}
											>
												Gift recipient details
											</SectionStep>

											<Show
												when={
													step() > 0 &&
													(!currentSub() || !currentSub()?.result?.error) &&
													formRef() &&
													new FormData(formRef())
												}
											>
												{(data) => (
													<div class="flex flex-col gap-3 ps-14">
														<p>
															<b class="font-medium">{data().get('giftParams.recipientName') as string}</b>
															<br />
															{data().get('giftParams.recipientEmail') as string}
														</p>
														<HorizontalRule />
														<p>{(data().get('giftParams.message') as string) ?? 'No message'}</p>
														<HorizontalRule />
														<p>
															<Icon name="calendar" class="text-brand" /> Send on{' '}
															{dayjs(data().get('notificationDate') as string).format('MMMM D, YYYY')}
														</p>
													</div>
												)}
											</Show>

											<Section class={step() === 0 || (currentSub() && currentSub()?.result?.error) ? '' : 'hidden'}>
												<TextField name="giftParams.recipientName">
													<Label>Recipient’s Name</Label>
													<Input autocomplete="off" maxLength={100} />
												</TextField>

												<TextField name="giftParams.recipientEmail">
													<Label>Recipient’s Email Address</Label>
													<Input inputMode="email" type="email" autocomplete="off" maxLength={100} />
												</TextField>

												<TextField name="giftParams.message">
													<Label>Gift Message</Label>
													<Input multiline autocomplete="off" maxLength={255} />
												</TextField>

												<TextField name="notificationDate">
													<Label>Email Notification</Label>
													<FieldDescription>
														Your recipient will be notified immediately about their gift. If you'd like to delay the
														notification, please enter your preferred date below.
													</FieldDescription>
													<Input
														type="date"
														min={dayjs().format('YYYY-MM-DD')}
														max={dayjs().add(3, 'month').format('YYYY-MM-DD')}
														placeholder="Send immediately"
														value={dayjs().format('YYYY-MM-DD')}
														suffixElement={<Icon name="calendar" class="me-2 text-brand-600" />}
													/>
												</TextField>

												<Show when={!currentSub()?.result?.error}>
													<Button
														type="button"
														class="size-fit"
														onClick={() => {
															setStep((s) => s + 1);
														}}
													>
														Continue
													</Button>
												</Show>
											</Section>
										</Section>

										<Section class="flex flex-col gap-4">
											<SectionStep
												step={2}
												state={step() === 1 ? 'current' : step() === 0 ? 'waiting' : 'completed'}
												action={step() > 1 ? <LinkButton onClick={() => setStep(1)}>Edit</LinkButton> : null}
											>
												Your details
											</SectionStep>

											<Show
												when={
													step() >= 1 &&
													(!currentSub() || !currentSub()?.result?.error) &&
													formRef() &&
													new FormData(formRef())
												}
											>
												{(data) => (
													<div class="flex flex-col gap-3 ps-14">
														<p>
															<b class="font-medium">{data().get('giftParams.purchaserName') as string}</b>
															<br />
															{data().get('giftParams.purchaserEmail') as string}
														</p>
													</div>
												)}
											</Show>

											<Section class={step() === 1 || (currentSub() && currentSub()?.result?.error) ? '' : 'hidden'}>
												<TextField name="giftParams.purchaserName">
													<Label>Name</Label>
													<Input autocomplete="off" maxLength={100} />
												</TextField>

												<TextField name="giftParams.purchaserEmail">
													<Label>Email Address</Label>
													<Input inputMode="email" type="email" autocomplete="email" maxLength={100} />
												</TextField>

												<Errors />

												<Button class="size-fit" type="submit">
													Continue
												</Button>
											</Section>
										</Section>
									</Form>

									<section class="flex flex-col gap-4">
										<SectionStep step={3} state={step() === 2 ? 'current' : 'waiting'}>
											Payment information
										</SectionStep>

										<ErrorBoundary>
											<Show when={stripe.state !== 'errored'}>
												<div>
													<Elements />
												</div>
											</Show>
										</ErrorBoundary>
									</section>
								</GridSeven>

								<GridFive>
									<div class="overflow-y-hidden border-neutral lg:rounded lg:border">
										<div class="hidden overflow-hidden lg:block">
											<ProductInfo product={productInfo()} isGift />
										</div>

										<Show when={sub()?.result}>
											<div data-testId="receipt" class="flex flex-col gap-6 py-6 lg:p-6">
												<Switch fallback={<ActivityIndicator />}>
													{/* Important: don't show errors here. They'll show inline instead */}
													<Match when={sub()?.result?.error?.graphQLErrors}>{null}</Match>
													<Match when={sub()?.result?.data?.gift}>
														{(gift) => (
															<>
																<PromoCodeEntry
																	action={createIntent}
																	// @ts-expect-error TODO not sure why this is complaining
																	document={query}
																	promoCode={gift().promoCode as string | undefined}
																	intentData={{
																		id: gift().id,
																		giftParams: {
																			purchaserName: gift().purchaserName,
																			purchaserEmail: gift().purchaserEmail,
																			recipientName: gift().recipientName,
																			recipientEmail: gift().recipientEmail,
																			message: gift().message,
																			deliverAt: gift().deliverAt,
																		},
																	}}
																	onReceivePromoSubscription={() => {}}
																	onUpdatePromoCode={(code) => setEnteredCode(!!code)}
																/>
																<Receipt
																	discount={gift().discountAmount}
																	subtotal={gift().subtotalAmount}
																	tax={gift().taxAmount}
																	total={gift().totalAmount}
																	promoCode={gift().promoCode}
																/>
															</>
														)}
													</Match>
												</Switch>

												<HorizontalRule />

												<StripeSubmit
													productId={params.id!}
													redirectPath="/access/gift/confirm"
													disabled={
														step() < 2 ||
														(enteredCode() && !currentSub()?.result?.data?.gift.promoCode) ||
														currentSub()?.pending ||
														!!currentSub()?.result?.error
													}
													onError={(errors) => setErrors(errors)}
													stripeClientSecret={currentSub()?.result?.data?.gift.stripeClientSecret}
													stripeClientSecretIntentType={StripeClientSecretIntentType.Payment}
													troonCardSubscriptionId={currentSub()?.result?.data?.gift.id}
												>
													Purchase Gift
												</StripeSubmit>

												<Show when={errors()}>
													{(error) => (
														<p class="text-red-500" aria-live="assertive">
															There was a problem completing the request: {error().message}
														</p>
													)}
												</Show>

												<p class="text-center text-sm">
													By clicking “Purchase Gift”, you are agreeing to Troon’s{' '}
													<TextLink target="_blank" href="/access/terms">
														Terms of Service
													</TextLink>{' '}
													and{' '}
													<TextLink target="_blank" href="https://troon.com/privacy-policy/">
														Privacy Policy
													</TextLink>
													.
												</p>
											</div>
										</Show>
									</div>
								</GridFive>
							</Grid>
						</Container>
					</>
				)}
			</Show>
		</Page>
	);
}

const query = gql(`mutation createOrUpdateAccessGiftPurchase(
  $productId: String
  $id: String
	$giftParams: AccessGiftPurchaseInput!
) {
  gift: createOrUpdateAccessGiftPurchaseIntent(
    troonCardSubscriptionProductId: $productId
		id: $id
    giftParams: $giftParams
  ) {
  	id
  	status
  	purchaserName
  	purchaserEmail
  	recipientName
  	recipientEmail
  	message
  	promoCode
  	stripeClientSecret
   	totalAmount { displayValue, value, code, cents }
		subtotalAmount { displayValue, value }
		discountAmount { displayValue, value }
		taxAmount { displayValue, value }
  	deliverAt
  }
}`);

const setupIntent = mutationAction(query, {
	transform: (data) => {
		const notificationDate = data.get('notificationDate') as string;
		const ret = { giftParams: { promoCode: (data.get('promoCode') as string | undefined) || undefined } };
		if (notificationDate) {
			// @ts-ignore okay for now
			ret.giftParams.deliverAt = dayjs.tz(notificationDate, 'America/Phoenix').set('hour', 9).toISOString();
		}
		return ret;
	},
});

export const route = { info: { nav: { appearance: 'minimal' } } } satisfies RouteDefinition;
