import dayjs from '@troon/dayjs';
import { For, createEffect, createMemo, createSignal } from 'solid-js';
import { twMerge } from '@troon/tailwind-preset/merge';
import { Dynamic } from 'solid-js/web';
import { Icon } from '@troon/icons';
import type { Dayjs } from '@troon/dayjs';
import type { ParentProps } from 'solid-js';

type HTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type CalendarProps = ParentProps<{
	headingLevel?: HTag;
	minDate?: Dayjs;
	maxDate?: Dayjs;
	label?: string;
	dayLabel?: string;
	onSelect?: (date: Dayjs) => void;
	onSelectInvalid?: (date: Dayjs) => void;
	onFocus?: (date: Dayjs) => void;
	selectedDate?: Dayjs;
	timezone: string;
}>;

export function Calendar(props: CalendarProps) {
	// Needs to be run on initial mount before anything else
	// eslint-disable-next-line solid/reactivity
	dayjs.tz.setDefault(props.timezone);

	// eslint-disable-next-line solid/reactivity
	const [focusedDate, setFocusedDate] = createSignal<Dayjs>(props.selectedDate ?? dayjs.tz(new Date()));
	const [currentMonth, setCurrentMonth] = createSignal<Dayjs>(
		// eslint-disable-next-line solid/reactivity
		(props.selectedDate ?? dayjs.tz(new Date())).startOf('month'),
	);

	createEffect(() => {
		if (props.selectedDate) {
			setFocusedDate(props.selectedDate);
			setCurrentMonth(props.selectedDate.startOf('month'));
		}
	});

	let calendar: HTMLTableSectionElement;

	const days = createMemo<Array<Array<{ date: Dayjs; isSameMonth: boolean }>>>(() => {
		const daysInMonth = currentMonth().endOf('month').date();
		const startDate = currentMonth().startOf('week');
		const weeksInMonth = Math.ceil(daysInMonth / 7 + (startDate.date() > 1 ? 2 : 1));
		const month = currentMonth();

		const days = new Array(weeksInMonth).fill(0).reduce(
			(memo, _week, weekIndex) => {
				const firstDayOfWeek = startDate.addInTz(weekIndex * 7, 'day');
				if (weekIndex > 0 && !firstDayOfWeek.isSame(month, 'month')) {
					return memo;
				}
				memo.push(
					new Array(7).fill(0).map((_, dayIndex) => {
						const date = firstDayOfWeek.addInTz(dayIndex, 'day');
						return { date, isSameMonth: date.isSame(month, 'month') };
					}),
				);
				return memo;
			},
			[] as Array<Array<{ date: Dayjs; isSameMonth: boolean }>>,
		);
		return days;
	});

	createEffect(() => {
		props.onFocus && props.onFocus(focusedDate());
		if (!focusedDate().isSame(currentMonth(), 'month')) {
			setCurrentMonth(focusedDate().startOf('month'));
		}
	});

	function handleKeypress(event: KeyboardEvent) {
		let nextDate = focusedDate();
		switch (event.key) {
			case 'Enter':
				event.preventDefault();
				props.onSelect && props.onSelect(nextDate);
				return;
			case 'ArrowDown':
				event.preventDefault();
				nextDate = nextDate.addInTz(7, 'day').startOf('day');
				break;
			case 'ArrowUp':
				event.preventDefault();
				nextDate = nextDate.subtractInTz(7, 'day').startOf('day');
				break;
			case 'ArrowRight':
				event.preventDefault();
				nextDate = nextDate.addInTz(1, 'day').startOf('day');
				break;
			case 'ArrowLeft':
				event.preventDefault();
				nextDate = nextDate.subtractInTz(1, 'day').startOf('day');
				break;
			default:
				// no default
				return;
		}

		// Clamp to min/max
		if (props.minDate && nextDate.isBefore(props.minDate)) {
			nextDate = props.minDate;
		} else if (props.maxDate && nextDate.isAfter(props.maxDate)) {
			nextDate = props.maxDate;
		}

		setFocusedDate(clampDate(nextDate));

		setTimeout(() => {
			(calendar!.querySelector('[tabindex="0"]') as HTMLButtonElement | undefined)?.focus();
		}, 1);
	}

	function clampDate(nextDate: Dayjs) {
		if (props.minDate && nextDate.isBefore(props.minDate)) {
			nextDate = props.minDate;
		} else if (props.maxDate && nextDate.isAfter(props.maxDate)) {
			nextDate = props.maxDate;
		}
		return nextDate;
	}

	return (
		<div role="application" class="w-full">
			<header class="grid grid-cols-7 items-center">
				<button
					disabled={props.minDate && focusedDate().startOf('month').isSameOrBefore(props.minDate)}
					aria-label={`Go to ${focusedDate().subtractInTz(1, 'month').endOf('month').format('MMMM YYYY')}`}
					onClick={() => {
						setFocusedDate((f) => clampDate(f.subtractInTz(1, 'month').endOf('month')));
					}}
					class="col-span-1 size-9 cursor-pointer rounded text-center outline-none transition-colors duration-100 focus-visible:ring-2 focus-visible:ring-brand-700 enabled:hover:bg-brand-100 enabled:hover:text-black enabled:active:ring-2 enabled:active:ring-brand-700 disabled:cursor-default disabled:text-neutral-600"
				>
					<Icon name="chevron-left" />
				</button>
				<Dynamic component={props.headingLevel ?? 'h2'} class="col-span-5 text-center font-semibold">
					{focusedDate().format('MMMM YYYY')}
				</Dynamic>
				<button
					disabled={props.maxDate && focusedDate().endOf('month').isSameOrAfter(props.maxDate)}
					aria-label={`Go to ${focusedDate().addInTz(1, 'month').startOf('month').format('MMMM YYYY')}`}
					onClick={() => {
						setFocusedDate((f) => clampDate(f.addInTz(1, 'month').startOf('month')));
					}}
					class="col-span-1 size-9 cursor-pointer place-self-end rounded text-center outline-none transition-colors duration-100 focus-visible:ring-2 focus-visible:ring-brand-700 enabled:hover:bg-brand-100 enabled:hover:text-black enabled:active:ring-2 enabled:active:ring-brand-700 disabled:cursor-default disabled:text-neutral-600"
				>
					<Icon name="chevron-right" />
				</button>
			</header>
			<table
				class="w-full bg-white"
				role="grid"
				aria-label={`${(props.label ?? '{date}').replace('{date}', focusedDate().format('MMMM YYYY'))}`}
				onClick={() => {
					(calendar!.querySelector('[tabindex="0"]') as HTMLButtonElement | undefined)?.focus();
				}}
			>
				<thead aria-hidden>
					<tr>
						<For each={days()[0]}>{(day) => <th>{day.date.format('dd')}</th>}</For>
					</tr>
				</thead>
				<tbody ref={calendar!}>
					<For each={days()}>
						{(week) => (
							<tr>
								<For each={week}>
									{(day) => {
										const withinRange =
											day.date.isSameOrAfter(props.minDate ?? -8640000000000000, 'day') &&
											day.date.isSameOrBefore(props.maxDate ?? 8640000000000000, 'day');
										return (
											<td
												tabindex={focusedDate().isSame(day.date, 'day') ? 0 : -1}
												role="button"
												aria-label={`${(props.dayLabel ?? '{date}').replace('{date}', day.date.format('dddd, MMMM D, YYYY'))}`}
												class={twMerge(
													'size-9 cursor-pointer rounded text-center outline-none transition-colors duration-100',
													day.date.isSame(dayjs.tz(new Date())) &&
														!day.date.isSame(focusedDate()) &&
														'bg-neutral-500/50',
													day.date.isSame(focusedDate()) && 'bg-brand text-white',
													!withinRange && 'text-neutral-600',
													withinRange &&
														'hover:bg-brand-100 hover:text-black focus-visible:ring-2 focus-visible:ring-brand-700 active:ring-2 active:ring-brand-700',
												)}
												data-focused={focusedDate().toDate().getTimezoneOffset()}
												aria-disabled={!withinRange && !props.onSelectInvalid}
												onKeyDown={handleKeypress}
												onClick={() => {
													if (withinRange) {
														setFocusedDate(day.date);
														props.onSelect && props.onSelect(day.date);
													} else {
														props.onSelectInvalid && props.onSelectInvalid(day.date);
													}
												}}
											>
												{day.date.format('D')}
											</td>
										);
									}}
								</For>
							</tr>
						)}
					</For>
				</tbody>
			</table>
		</div>
	);
}
