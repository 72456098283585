import { DialogAction, DialogActions, Errors, Form } from '@troon/ui';
import { useContext, createEffect, Show } from 'solid-js';
import dayjs from '@troon/dayjs';
import { useSubmission } from '@solidjs/router';
import { gql, mutationAction, useMutation } from '../../../../../../graphql';
import { FacilityCtx } from '../../../../../../providers/facility';
import { timeframes } from '../../../../../../modules/date-formatting';
import { TeeTimeAlertFields } from '../../../../../../partials/tee-time-alerts';
import type { Facility } from '../../../../../../graphql';

export type Store = {
	includeCart: boolean;
	players: number | undefined;
	courseIds: Array<string>;
	startAt: number;
	endAt: number;
	year: number;
	month: number;
	day: number;
};

type Props = {
	onClose: () => void;
	onShowAppDownload?: () => void;
	selectedDay?: Date;
	selectedTime?: [number, number];
	selectedPlayers?: number;
};

export function CreateTeeTimeAlert(props: Props) {
	const action = useMutation(mutation);
	const facility = useContext(FacilityCtx);

	const submission = useSubmission(action);

	createEffect(() => {
		if (submission.result?.data) {
			props.onClose();
			submission.clear();
		}
	});

	return (
		<Form document={createMutation} action={action}>
			<Show when={facility()?.facility}>
				{(facility) => (
					<TeeTimeAlertFields
						selectedDay={props.selectedDay}
						selectedTime={props.selectedTime}
						selectedPlayers={props.selectedPlayers}
						// @ts-expect-error TODO
						facilities={[facility() as Facility]}
					/>
				)}
			</Show>

			<Errors />

			<DialogActions>
				<DialogAction type="submit">Save alert</DialogAction>
				<DialogAction type="button" appearance="transparent" onClick={() => props.onClose()}>
					Cancel
				</DialogAction>
			</DialogActions>
		</Form>
	);
}

const createMutation = gql(`
mutation createFacilityTeeTimeAlert(
  $year: Int!
  $month: Int!
  $day: Int!
  $startAt: Int!
  $endAt: Int!
  $players: Int
  $courseIds: [String!]!
  $pushEnabled: Boolean
  $emailEnabled: Boolean
) {
  createFacilityTeeTimeAlert(
    input: {
      day: { year: $year, month: $month, day: $day }
      startAt: { hour: $startAt, minute: 0 }
      endAt: { hour: $endAt, minute: 0 }
      players: $players
      courseIds: $courseIds
      pushEnabled: $pushEnabled
      emailEnabled: $emailEnabled
    }
  ) {
    id
    state
  }
}
`);

const mutation = mutationAction(createMutation, {
	toast:
		'Your new tee time alert has been added! You’ll be notified when a tee time becomes available that matches your preferences.',
	transform: (data) => {
		const date = dayjs(data.get('__date') as string);
		const [startAt, endAt] = timeframes[data.get('__time') as keyof typeof timeframes] as [number, number];

		data.delete('__date');
		data.delete('__time');

		return {
			courseIds: (data.get('courseIds') as string)?.split(','),
			players: (data.get('players') as string) === '-1' ? undefined : parseInt(data.get('players') as string, 10),
			year: date.year(),
			month: date.month() + 1,
			day: date.date(),
			startAt,
			endAt,
			emailEnabled: true,
		};
	},
	track: {
		event: 'createTeeTimeAlert',
		transform: (data) => ({
			courseIds: (data.get('courseIds') as string)?.split(','),
			type: 'email',
			players: (data.get('players') as string) === '-1' ? undefined : parseInt(data.get('players') as string, 10),
		}),
	},
});
