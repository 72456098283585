import { For } from 'solid-js';
import { Meta, Title } from '@solidjs/meta';
import { Heading, Container, BreadcrumbItem, Section, HorizontalRule, Page, Callout } from '@troon/ui';
import { NoHydration } from 'solid-js/web';
import { useLocation } from '@solidjs/router';
import { getConfigValue } from '../../modules/config';
import { Hero } from '../../components/hero/photo';
import { PictureSection } from '../../components/layouts/picture-section';
import type { RouteDefinition } from '@solidjs/router';

export default function DEIPage() {
	const loc = useLocation();

	return (
		<NoHydration>
			<Title>Mission, Vision, and Values | Troon</Title>
			<Meta
				name="description"
				content="The senior executives and leadership team Troon and Troon's affiliated brands."
			/>

			<Hero
				src={`${getConfigValue('IMAGE_HOST')}/digital/hero/mission-vision-values.jpg`}
				crop="center"
				breadcrumbs={() => (
					<>
						<BreadcrumbItem href="/about">About Troon</BreadcrumbItem>
						<BreadcrumbItem href={loc.pathname}>Mission, Vision, & Values</BreadcrumbItem>
					</>
				)}
			>
				<Heading as="h1" class="text-center text-white">
					Mission, Vision, & Values
				</Heading>
			</Hero>

			<Container>
				<Page>
					<PictureSection src={`${getConfigValue('IMAGE_HOST')}/digital/hero/career.jpeg`}>
						<Heading as="h2" size="p" class="text-brand-600">
							Our mission
						</Heading>
						<p class="text-3xl font-semibold md:text-4xl">
							Deliver memorable experiences that cultivate lasting connections.
						</p>
					</PictureSection>

					<Container>
						<HorizontalRule />
					</Container>

					<PictureSection reverse src={`${getConfigValue('IMAGE_HOST')}/digital/photo/vision.jpeg`} crop="center">
						<Heading as="h2" size="p" class="text-brand-600">
							Our vision
						</Heading>
						<p class="text-3xl font-semibold md:text-4xl">
							Create unparalleled experiences fueled by a passion for enabling fun.
						</p>
					</PictureSection>

					<Container>
						<HorizontalRule />
					</Container>

					<Section class="flex flex-col gap-8">
						<Container>
							<Heading as="h2">Our Values</Heading>
							<div class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:gap-10 lg:grid-cols-3">
								<For each={Object.entries(callouts)}>
									{([title, body]) => (
										<Callout>
											<Heading as="h3" size="h5">
												{title}
											</Heading>
											<p class="text-sm">{body}</p>
										</Callout>
									)}
								</For>
							</div>
						</Container>
					</Section>
				</Page>
			</Container>
		</NoHydration>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;

const callouts: Record<string, string> = {
	'Consciously Kind':
		'Believing in the power of honor for all people, places, properties and relationships built and maintained through goodwill.',
	'Attentively Inclusive':
		'Cultivating a culture of diversity and inclusion where we celebrate the unique passions and experiences for all.',
	'Infectiously Energetic': 'Embodying a lively spirit every day.',
	'Genuinely Meticulous':
		'Believing in the power of honor for all people, places, properties and relationships built and maintained through goodwill.',
	'Humbly Prosperous': 'Focusing on the results that bring meaningful growth.',
	'Relentlessly Dedicated':
		'Ensuring an unparalleled commitment to impeccable care for our members, clients and associates.',
	'Passionately Unified': 'Supporting each other, our clients, our partners and our community with a singular voice.',
};
