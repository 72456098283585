import { For, Suspense } from 'solid-js';
import { createAsync } from '@solidjs/router';
import { Title } from '@solidjs/meta';
import { Container, Page, Section } from '@troon/ui';
import { gql } from '../../graphql';
import { FacilityCard } from '../../components/facility/card';
import { PageHeader } from '../../components/page-header';
import { getSchemaString } from '../../modules/schema/schema';
import { breadcrumbs } from '../../modules/schema/breadcrumb-list';
import { cachedQuery } from '../../graphql/cached-get';

export default function FavoriteCourses() {
	const data = createAsync(() => getFacilities({}));

	return (
		<Container>
			<Title>Book tee times at your favorite golf courses | Troon Rewards</Title>
			<script
				type="application/ld+json"
				innerText={getSchemaString([
					breadcrumbs('/courses/favorites', [
						{ name: 'Home', pathname: '/' },
						{ name: 'Courses', pathname: '/courses' },
					]),
				])}
			/>

			<Page>
				<Section>
					<div class="border-b border-neutral-300 pb-8 md:pb-12">
						<PageHeader
							title="Favorite courses"
							subtext={<p>Book tee times online at your favorite Troon courses.</p>}
						/>
					</div>

					<ul class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
						<Suspense>
							<For each={data()?.favoriteFacilities}>
								{(facility, index) => (
									<li>
										<FacilityCard facility={facility} level="h2" loading={index() <= 15 ? 'eager' : 'lazy'} />
									</li>
								)}
							</For>
						</Suspense>
					</ul>
				</Section>
			</Page>
		</Container>
	);
}

const favoriteQuery = gql(`
query favoriteFacilities {
  favoriteFacilities {
    ...FacilityCard
  }
}`);
const getFacilities = cachedQuery(favoriteQuery, {});
