import { Show, mergeProps } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Dialog as KDialog } from '@kobalte/core/dialog';
import { Icon } from '@troon/icons';
import { Button } from '../forms/button';
import { useDialogContext } from '../providers/dialog';
import type { ParentProps, JSX, ComponentProps } from 'solid-js';
import type { LinkButton } from '../components';

type DialogProps = ComponentProps<typeof KDialog> & {
	key: string;
};

export function Dialog(props: DialogProps) {
	const ctx = useDialogContext();
	return (
		<KDialog
			modal
			{...props}
			onOpenChange={(open) => {
				props.onOpenChange && props.onOpenChange(open);
				if (open) {
					ctx.onDialogOpened(props.key);
				} else if (!open) {
					ctx.onDialogClosed(props.key);
				}
			}}
		/>
	);
}

Dialog.Trigger = KDialog.Trigger;

export function DialogTrigger(
	props: ComponentProps<(typeof KDialog)['Trigger']> & ComponentProps<typeof Button | typeof LinkButton>,
) {
	return (
		<KDialog.Trigger
			{...props}
			as={props.as ?? Button}
			onClick={
				props.as
					? (e) => {
							if (!e.metaKey) {
								e.preventDefault();
							}
						}
					: undefined
			}
		/>
	);
}

type NoHeaderProps = {
	header?: never | undefined;
	headerLevel?: never | undefined;
};
type HeaderProps = {
	header: JSX.Element;
	headerLevel: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

type Props = {
	/**
	 * A unique identifier for the onDialogOpen and onDialogClose events
	 */
	children: ComponentProps<typeof KDialog.Content>['children'];
	/**
	 * Classnames to apply to the close button. Use `false` to hide the button.
	 */
	closeButton?: string | false;
	/**
	 * Control the width of the dialog. Only applicable for large screens.
	 * @default 'sm'
	 */
	width?: 'fit' | 'sm' | 'lg' | 'xl';
	/**
	 * Control the height of the dialog. Only applicable for small screens.
	 * @default 'fit'
	 */
	height?: 'fit' | 'full';
	/**
	 * Remove padding from the content area (excludes header padding)
	 */
	noPadding?: boolean;
} & (NoHeaderProps | HeaderProps);

export function DialogContent(props: Props) {
	const sizing = mergeProps({ width: 'sm', height: 'fit' }, props);
	return (
		<KDialog.Portal>
			<KDialog.Overlay class="fixed inset-0 z-40 bg-black/50 backdrop-blur-sm" />
			<div class="fixed inset-0 z-40 flex items-center justify-center">
				<KDialog.Content
					// eslint-disable-next-line tailwindcss/no-arbitrary-value
					class={twJoin(
						'fixed bottom-0 w-dvw max-w-full overflow-y-auto overflow-x-hidden overscroll-contain rounded-t bg-white shadow-xl outline-none animate-out fade-out slide-out-to-bottom anim-duration-200 ui-expanded:animate-in ui-expanded:fade-in ui-expanded:slide-in-from-bottom ui-expanded:anim-duration-200 sm:bottom-auto sm:my-auto sm:max-h-[97dvh] sm:rounded',
						sizing.width === 'fit' && 'sm:w-fit',
						sizing.width === 'sm' && 'sm:h-fit sm:w-full sm:max-w-xl',
						sizing.width === 'lg' && 'sm:h-fit sm:w-full sm:max-w-4xl',
						sizing.width === 'xl' && 'sm:h-fit sm:w-full sm:max-w-5xl',
						sizing.height === 'fit' && 'max-h-[97dvh]',
						sizing.height === 'full' && 'h-[97dvh] sm:h-fit',
					)}
				>
					<Show when={props.closeButton !== false || props.header}>
						<div
							class={twJoin(
								'pointer-events-none inset-x-0 top-0 z-10 flex h-16 flex-row items-center justify-between gap-4 px-6 py-3',
								props.header ? 'sticky border-b border-neutral-500 bg-white' : 'absolute',
							)}
						>
							<Show when={!!props.header}>
								<KDialog.Title as={props.headerLevel} class="grow font-semibold">
									{props.header}
								</KDialog.Title>
							</Show>
							<Show when={props.closeButton !== false}>
								<div class="pointer-events-auto relative -end-4 ms-auto shrink">
									<KDialog.CloseButton
										as={Button}
										appearance={props.header ? 'transparent' : 'transparent-current'}
										tabIndex={-1}
										class={props.closeButton as string | undefined}
									>
										<Icon name="close-md" />
										<span class="sr-only">Close</span>
									</KDialog.CloseButton>
								</div>
							</Show>
						</div>
					</Show>

					<div
						class={twJoin(
							// Important: h-fit not h-full otherwise Safari will collapse this content to about 1rem
							'relative h-fit self-start',
							!props.noPadding && 'px-4 pb-6 md:px-6',
							props.header || !props.noPadding ? 'pt-6' : '',
						)}
					>
						{props.children}
					</div>
				</KDialog.Content>
			</div>
		</KDialog.Portal>
	);
}

export function DialogActions(props: ParentProps) {
	return (
		<div class="flex flex-col items-stretch justify-start gap-4 sm:flex-row-reverse sm:items-center">
			{props.children}
		</div>
	);
}

export function DialogAction(props: ComponentProps<typeof Button>) {
	return <Button {...props} class="shrink grow-0" />;
}
