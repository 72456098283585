import { createAsync } from '@solidjs/router';
import { For, Suspense } from 'solid-js';
import { Title } from '@solidjs/meta';
import { ActivityIndicator, Button, Heading, Link } from '@troon/ui';
import dayjs from '@troon/dayjs';
import { gql } from '../../graphql';
import { formatDateTimeAttribute } from '../../modules/date-formatting';
import { cachedQuery } from '../../graphql/cached-get';
import { ErrorBoundary } from '../../components/error-boundary';

export default function Activity() {
	const data = createAsync(() => getActivities({}), { deferStream: true });

	return (
		<div class="flex flex-col gap-8">
			<Title>Activity | My account | Troon</Title>
			<Heading as="h1">Activity</Heading>

			<ErrorBoundary>
				<ul class="rounded border border-neutral p-4 md:p-6">
					<Suspense
						fallback={
							<li>
								<ActivityIndicator>Loading recent activity…</ActivityIndicator>
							</li>
						}
					>
						<For
							each={data()?.transactions}
							fallback={
								<li class="flex flex-col items-center gap-2 text-center italic text-neutral-700">
									No activity yet.{' '}
									<Button class="inline-flex w-fit not-italic" as={Link} href="/tee-times">
										Book your first tee time
									</Button>
								</li>
							}
						>
							{(transaction) => {
								const date = new Date(
									transaction.dayCreated.year,
									transaction.dayCreated.month - 1,
									transaction.dayCreated.day,
								);
								return (
									<li class="flex flex-row items-center justify-between gap-x-8 border-b border-neutral py-4 last:border-b-0">
										<div>
											<span class="block text-lg">{transaction.facilityName}</span>
											<time datetime={formatDateTimeAttribute(date, false)}>{dayjs(date).format('MMMM D, YYYY')}</time>
										</div>
										<span class="text-lg font-semibold">+{transaction.points} points</span>
									</li>
								);
							}}
						</For>
					</Suspense>
				</ul>
			</ErrorBoundary>
		</div>
	);
}

const activityQuery = gql(`
query activity {
	transactions: userRewardTransactions {
		points
		facilityName
		dayCreated {
			year
			month
			day
		}
		transactionType
	}
}`);

const getActivities = cachedQuery(activityQuery);
