import { Match, Switch, Show } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Icon } from '@troon/icons';
import { Toast as KToast, toaster } from '@kobalte/core/toast';
import { Button } from '../forms/button';
import type { JSX } from 'solid-js';

type Toast = {
	title: JSX.Element;
	description?: JSX.Element;
	variant?: 'negative' | 'positive' | 'info';
};

export function ToastRegion() {
	return (
		<KToast.Region pauseOnInteraction pauseOnPageIdle duration={5_000}>
			<KToast.List class="pointer-events-none fixed inset-4 z-50 flex flex-col items-center justify-end gap-4 md:inset-8 md:items-end" />
		</KToast.Region>
	);
}

export function addToast(options: Toast) {
	return toaster.show((props) => (
		<KToast
			toastId={props.toastId}
			// eslint-disable-next-line tailwindcss/no-arbitrary-value
			class={twJoin(
				'pointer-events-auto flex w-full translate-x-0 flex-row items-start gap-2 rounded px-2 py-1 shadow animate-in slide-in-from-bottom anim-duration-200 ui-closed:animate-out ui-closed:slide-out-to-bottom ui-swipe-move:translate-x-[var(--kb-toast-swipe-move-x)] md:w-fit md:max-w-96 md:px-3 md:py-2 md:slide-in-from-bottom-0 md:slide-in-from-right md:ui-closed:slide-out-to-bottom-0 md:ui-closed:slide-out-to-right',
				options?.variant === 'positive' && 'bg-green-100 text-green-500',
				options?.variant === 'negative' && 'bg-red-100 text-red-500',
				(options?.variant === 'info' || !options?.variant) && 'bg-brand-100 text-brand-500',
			)}
		>
			<Switch fallback={<Icon name="info" class="mt-2 size-8 shrink-0" title="Info" />}>
				<Match when={options?.variant === 'positive'}>
					<Icon name="circle-check" class="mt-2 size-8 shrink-0" title="Success" />
				</Match>
				<Match when={options?.variant === 'negative'}>
					<Icon name="circle-warning" class="mt-2 size-8 shrink-0" title="Error" />
				</Match>
			</Switch>
			<div class="flex h-full grow flex-col gap-1">
				<KToast.Title class={twJoin('flex grow flex-col justify-center', options.description ? 'font-semibold' : '')}>
					{options.title}
				</KToast.Title>
				<Show when={options.description}>
					{(desc) => <KToast.Description class="text-sm">{desc()}</KToast.Description>}
				</Show>
			</div>
			<div class="shrink-0 grow-0">
				<KToast.CloseButton
					as={Button}
					appearance="transparent-current"
					action={options?.variant === 'negative' ? 'danger' : undefined}
				>
					<Icon name="close-md" />
				</KToast.CloseButton>
			</div>
		</KToast>
	));
}
