import { Heading } from '@troon/ui';
import { NoHydration } from 'solid-js/web';
import { getConfigValue } from '../../../modules/config';
import type { RouteDefinition } from './_types';

export default function CommunityAssociationsServicesPage() {
	return (
		<NoHydration>
			<Heading as="h2">Deliver Genuine Service to Your Association</Heading>
			<p>Our key responsibilities consist of the following:</p>
			<ul class="flex list-disc flex-col gap-2 ps-8">
				<li>Collection of monthly/quarterly/annual assessments</li>
				<li>Enforcement of the Association’s rules & regulations</li>
				<li>Management and oversight of outside vendors (e.g., landscaping, security, lake maintenance, etc.)</li>
				<li>
					Manage resolution of complaints, maintenance services, and emergency services in accordance with procedures
					and guidelines adopted by the Board
				</li>
				<li>Handling of all Association accounting and requested records keeping</li>
				<li>Facilitate Association communication with residents</li>
				<li>
					Provide administrative services as directed by the Association (e.g., producing Board meeting minutes,
					coordinating monthly/annual meetings, etc.)
				</li>
				<li>
					Process all necessary paperwork regarding rental, sale, and transfer of units and homes in the community
				</li>
			</ul>
		</NoHydration>
	);
}

export const route = {
	info: {
		nav: { hero: true },
		title: 'Community associations',
		description: 'Integrate a hospitality-centric approach to your community.',
		hero: `${getConfigValue('IMAGE_HOST')}/digital/hero/community-associations.jpg`,
	},
} satisfies RouteDefinition;
