import { For } from 'solid-js';
import { ResponsiveNavigationMenu, NavigationMenuItem, Container, Page } from '@troon/ui';
import { Grid, GridThird, GridTwoThirds } from '../components/layouts/grid';
import type { ComponentProps, ParentProps } from 'solid-js';

type Props = ParentProps<{
	sidebar: Array<ComponentProps<typeof NavigationMenuItem>>;
}>;

export function SidebarLayout(props: Props) {
	return (
		<>
			<Container>
				<Page>
					<Grid>
						<GridThird class="md:py-8">
							<ResponsiveNavigationMenu trigger="My account">
								<For each={props.sidebar}>
									{(item) => (
										<NavigationMenuItem href={item.href!} icon={item.icon}>
											{item.children}
										</NavigationMenuItem>
									)}
								</For>
							</ResponsiveNavigationMenu>
						</GridThird>
						<GridTwoThirds>
							<Page>{props.children}</Page>
						</GridTwoThirds>
					</Grid>
				</Page>
			</Container>
		</>
	);
}
