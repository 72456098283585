import { NoHydration } from 'solid-js/web';
import { BreadcrumbItem, Button, Container, Heading, Link, Page, Section, TextLink } from '@troon/ui';
import { useLocation } from '@solidjs/router';
import { Meta, Title } from '@solidjs/meta';
import { Hero } from '../../components/hero/photo';
import { getConfigValue } from '../../modules/config';
import { PictureSection } from '../../components/layouts/picture-section';
import { Grid, GridHalf } from '../../components/layouts/grid';
import type { RouteDefinition } from '@solidjs/router';

export default function TennisPage() {
	const loc = useLocation();
	return (
		<>
			<Title>Tennis Experiences | Troon Privé Member Programs | Troon</Title>
			<Meta name="description" content="Ultimate Member Experiences by Cliff Drysdale Tennis." />

			<NoHydration>
				<Hero
					src={`${getConfigValue('IMAGE_HOST')}/digital/hero/tennis-experiences.jpg`}
					breadcrumbs={() => (
						<>
							<BreadcrumbItem href="/member-programs">Troon Privé Privileges</BreadcrumbItem>
							<BreadcrumbItem href={loc.pathname}>Tennis Experiences</BreadcrumbItem>
						</>
					)}
				>
					<Heading as="h1">Tennis Experiences</Heading>
					<p class="text-lg">Ultimate Member Experiences by Cliff Drysdale Tennis.</p>
				</Hero>

				<Container>
					<Page>
						<PictureSection src={`${getConfigValue('IMAGE_HOST')}/digital/hero/tennis-experiences.jpeg`}>
							<Heading as="h2">Overview</Heading>
							<p>
								With the acquisition of Cliff Dysdale Tennis in 2018, Troon is pleased to announce new travel
								experiences for Full Members of Troon Privé clubs that include but are not limited to:
							</p>

							<ul class="list-disc ps-6">
								<li>A tennis travel concierge team</li>
								<li>Preferred pricing and pre-sales</li>
								<li>Adult tennis camps</li>
								<li>Signature events</li>
								<li>Tournament and Grand Slam outings</li>
								<li>Complimentary game arranging when staying at a CDT resort location</li>
								<li>
									Preferred rates, amenities, unlimited court time, and select upgrades when staying at CDT resorts
									across the US and Bermuda
								</li>
							</ul>

							<Button
								as={Link}
								href="mailto:k.steck@cliffdrysdale.com?subject=Troon%20Prive%20Tennis%20Experience%20Request"
								class="size-fit grow-0"
							>
								Plan your experience
							</Button>
						</PictureSection>

						<Section appearance="primary" class="rounded">
							<Grid class="divide-x divide-white/20">
								<GridHalf class="flex flex-col gap-8 px-8 sm:px-16 lg:px-24">
									<Heading as="h3">Resort Destinations</Heading>
									<ul class="flex list-disc flex-col gap-3 ps-6 [&>li>ul]:list-disc [&>li>ul]:ps-6">
										<li>
											<strong>Bermuda:</strong>
											<ul>
												<li>Rosewood Bermuda</li>
											</ul>
										</li>
										<li>
											<strong>California:</strong>
											<ul>
												<li>Omni La Costa Resort and Spa</li>
												<li>Omni Rancho Las Palmas Resort and Spa</li>
												<li>The Ritz-Carlton, Santa Barbara</li>
											</ul>
										</li>
										<li>
											<strong>Florida:</strong>
											<ul>
												<li>Hawks Cay Resort</li>
												<li>Omni Amelia Island Resort</li>
												<li>The Ritz-Carlton Key Biscayne, Miami</li>
											</ul>
										</li>
										<li>
											<strong>Georgia:</strong>
											<ul>
												<li>The Château Élan Winery & Resort</li>
											</ul>
										</li>
										<li>
											<strong>Hawaii:</strong>
											<ul>
												<li>Mauna Lani Resort</li>
											</ul>
										</li>
										<li>
											<strong>Maryland:</strong>
											<ul>
												<li>Inn at Perry Cabin</li>
											</ul>
										</li>
										<li>
											<strong>The Caribbean:</strong>
											<ul>
												<li>Baha Mar</li>
											</ul>
										</li>
									</ul>
								</GridHalf>
								<GridHalf class="flex flex-col gap-8 px-8 sm:px-16 lg:px-24">
									<Heading as="h3">Private Tennis Clubs & Communities</Heading>

									<ul class="flex list-disc flex-col gap-3 ps-6 [&>li>ul]:list-disc [&>li>ul]:ps-6">
										<li>
											<strong>California:</strong>
											<ul>
												<li>The Club at Castlewood</li>
												<li>Newport Beach Tennis Club</li>
												<li>Silver Creek Valley Country Club</li>
											</ul>
										</li>
										<li>
											<strong>Texas:</strong>
											<ul>
												<li>John Newcombe Country Club</li>
												<li>Polo Tennis & Fitness Club</li>
												<li>Tyler Athletic and Swim Club</li>
												<li>Walden Racquet Club</li>
											</ul>
										</li>
										<li>
											<strong>Utah:</strong>
											<ul>
												<li>Red Ledges</li>
											</ul>
										</li>
										<li>
											<strong>Florida:</strong>
											<ul>
												<li>Dunes of Naples</li>
												<li>Weston Racquet Club</li>
											</ul>
										</li>
										<li>
											<strong>Georgia:</strong>
											<ul>
												<li>The Standard Club</li>
											</ul>
										</li>
									</ul>
								</GridHalf>
							</Grid>
							<p class="px-8 sm:px-16 lg:px-24">
								To book your experience and receive Privé Tennis Privileges, members must book through the CDT Tennis
								Concierge,{' '}
								<TextLink
									class="text-white underline"
									href="mailto:k.steck@cliffdrysdale.com?subject=Troon%20Prive%20Tennis%20Experience%20Request"
								>
									Katie Steck at k.steck@cliffdrysdale.com
								</TextLink>
								. Cannot be used within a 50-mile radius of your home club. Access is based on court availability. Guest
								fees may apply.
							</p>
						</Section>
					</Page>
				</Container>
			</NoHydration>
		</>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
