import { useExperiment } from '@troon/analytics';
import { createSignal, Match, Show, splitProps, Switch } from 'solid-js';
import { DialogAction, DialogActions, DialogContent, Heading, Link, MessageBar, TroonAccess } from '@troon/ui';
import { Icon } from '@troon/icons';
import { createAsync } from '@solidjs/router';
import { AccessProducts } from '../access-products';
import { usePersisted } from '../../providers/persistence-store';
import { getAccessProducts } from '../../providers/card';
import { ImageOverlay } from '../image-overlay';
import { DialogAccessProducts } from './dialog-access-products';
import type { IconName } from '@troon/icons';
import type { ComponentProps, ParentProps } from 'solid-js';

type Props = ComponentProps<typeof AccessProducts> & { onCancel: () => void };

export function AccessProductDialogContent(inputProps: Props) {
	const branch = useExperiment('access-dialog-flow');
	const [props, access] = splitProps(inputProps, ['onCancel']);
	const [step, setStep] = createSignal(0);
	const [params] = usePersisted();
	createAsync(() => getAccessProducts({ promoCode: params.promoCode?.value }), { deferStream: true });

	const [url, setUrl] = createSignal('');
	const [state, setState] = createSignal({});

	return (
		<DialogContent
			width="xl"
			height="fit"
			header={
				!branch || branch === 'control' || branch === 'vertical' || step() !== 0
					? 'Choose your Troon Access'
					: undefined
			}
			closeButton={typeof branch === 'string' && branch.includes('flow') && step() === 0 ? false : undefined}
			headerLevel="h3"
			noPadding
		>
			<Show
				when={branch !== 'control' && (branch as string)}
				fallback={
					<div class="px-4 pb-4 md:px-6 md:pb-6">
						<AccessProducts {...access} />
					</div>
				}
			>
				{(branch) => (
					<Switch>
						<Match when={branch().includes('flow') && step() === 0}>
							<Explainer onCancel={props.onCancel} onContinue={() => setStep(1)} />
						</Match>
						<Match when={branch().includes('vertical')}>
							<div class="px-4 pb-4 md:px-6 md:pb-6">
								<DialogAccessProducts
									{...access}
									onUrlChange={(url, state) => {
										setUrl(url);
										setState(state);
									}}
								/>
							</div>
							<div class="sticky inset-x-0 bottom-0 border-t border-neutral bg-white px-6 py-3">
								<DialogActions>
									<DialogAction as={Link} href={url()} state={state()}>
										Continue to Payment <Icon name="arrow-right-md" />
									</DialogAction>
								</DialogActions>
							</div>
						</Match>
						<Match when={branch().includes('horizontal')}>
							<div class="px-4 pb-4 md:px-6 md:pb-6">
								<AccessProducts {...access} />
							</div>
						</Match>
					</Switch>
				)}
			</Show>
		</DialogContent>
	);
}

function Explainer(props: { onCancel: () => void; onContinue: () => void }) {
	return (
		<div class="grid w-full grid-cols-12">
			<div class="relative col-span-12 flex flex-col justify-center gap-4 bg-gradient-to-r from-neutral-950 to-brand-700 px-4 py-16 text-white md:col-span-5 md:gap-8 md:px-12 md:py-8">
				<TroonAccess class="w-44" />
				<Heading as="p" size="h3">
					Save up to 50% on tee times at over 150 courses.
				</Heading>
				<p>Troon Access is the ultimate way to experience Troon. Join today and start saving every time you play.</p>
				<ImageOverlay class="absolute inset-x-0 bottom-0 md:hidden" />
			</div>
			<div class="relative col-span-12 md:col-span-7">
				<div class="flex shrink grow flex-col gap-8 overflow-auto px-4 py-8 md:px-12">
					<Heading as="h4" size="h5">
						Member Benefits
					</Heading>
					<div class="flex flex-col gap-6">
						<Benefit icon="tag">
							<p>
								<b>Savings on every round.</b> Save a minimum of 15% off the public rate on every round. Troon Access+
								cardholders enjoy up to 50% off tee times within 78 hours.
							</p>
						</Benefit>

						<MessageBar icon="info" appearance="brand">
							<p class="text-sm">Troon Access members save an average of $700/year on tee times!</p>
						</MessageBar>

						<Benefit icon="map-pin">
							<p>
								<b>Access to over 150 courses.</b> From Kapalua, to Gamble Sands and Troon North – your Troon Access
								benefits give you a network of premier courses to play.
							</p>
						</Benefit>

						<Benefit icon="gift">
							<p>
								<b>Exclusive perks & partner offers.</b> Enjoy additional benefits like partner offers and 10% savings
								on pro-shop merchandise.
							</p>
						</Benefit>

						<Benefit icon="users">
							<p>
								<b>Guest Passes</b> allow your playing partners to play at the same discounted rate as you for a given
								round.
							</p>
						</Benefit>
					</div>
				</div>
				<div class="sticky inset-x-0 bottom-0 border-t border-neutral bg-white px-4 py-3 md:px-6">
					<DialogActions>
						<DialogAction onClick={props.onContinue}>
							Choose your Access <Icon name="arrow-right-md" />
						</DialogAction>
						<DialogAction appearance="transparent" onClick={props.onCancel}>
							Maybe later
						</DialogAction>
					</DialogActions>
				</div>
			</div>
		</div>
	);
}

function Benefit(props: ParentProps<{ icon: IconName }>) {
	return (
		<div class="flex items-start gap-4">
			<div class="rounded-full bg-gradient-to-r from-neutral-950 to-brand-700 p-2.5 text-white">
				<Icon name={props.icon} class="size-6" />
			</div>
			<div>{props.children}</div>
		</div>
	);
}
