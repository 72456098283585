import { twJoin } from '@troon/tailwind-preset/merge';
import { Picture } from '@troon/ui';
import { splitProps } from 'solid-js';
import type { ComponentProps, ParentProps } from 'solid-js';

type Props = ParentProps<{
	reverse?: boolean;
	src: ComponentProps<typeof Picture>['src'];
}> &
	Partial<ComponentProps<typeof Picture>>;

export function PictureSection(props: Props) {
	const [, pictureProps] = splitProps(props, ['children', 'reverse']);
	return (
		<div class="grid grid-cols-1 gap-x-16 gap-y-8 md:grid-cols-2 lg:gap-x-36">
			<div class={twJoin('flex flex-col justify-center gap-8', props.reverse && 'order-2')}>{props.children}</div>
			<div class={twJoin('flex flex-col justify-center gap-8', props.reverse && 'order-1')}>
				<Picture
					// eslint-disable-next-line tailwindcss/no-arbitrary-value
					class="aspect-[4/3] w-full overflow-hidden rounded object-contain"
					{...(pictureProps as ComponentProps<typeof Picture>)}
					width={480}
					height={360}
					sizes="(min-width: 768px) 45vw, 95vw"
					src={pictureProps.src}
				/>
			</div>
		</div>
	);
}
