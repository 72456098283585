import { Suspense } from 'solid-js';
import { clientOnly } from '@solidjs/start';
import type { RouteSectionProps, RouteDefinition } from '@solidjs/router';

export default function Storybook(props: RouteSectionProps) {
	return (
		<Suspense>
			<StorybookContent {...props} />
		</Suspense>
	);
}

const StorybookContent = clientOnly(async () => ({ default: (await import('./_client')).ClientContent }));

export const route = {
	info: { nav: { appearance: 'extra-minimal' }, banner: { hide: true } },
} satisfies RouteDefinition;
