import { Title } from '@solidjs/meta';
import { Heading, LinkButton, Container } from '@troon/ui';
import { useNavigate } from '@solidjs/router';
import { Icon } from '@troon/icons';
import { Hero } from '../../components/hero/photo';
import { getConfigValue } from '../../modules/config';
import { SearchLocations } from '../../components/search-locations';
import { SearchFacilitiesContent } from './_components/contents';
import type { RouteDefinition } from '@solidjs/router';

export default function Home() {
	const navigate = useNavigate();

	return (
		<>
			<Hero src={`${getConfigValue('IMAGE_HOST')}/digital/hero/explore-courses.jpg`} fullWidth>
				<div class="flex w-full max-w-4xl flex-col items-stretch justify-center gap-8 px-4 text-center text-white md:py-24">
					<Heading as="h1">Explore Courses</Heading>
					<Title>Explore Golf Courses | Troon</Title>
					<div class="w-full rounded bg-white text-neutral-900">
						<SearchLocations
							onSelectPlace={(place) => {
								const params = new URLSearchParams({
									lat: place.coordinates.latitude.toString(),
									lon: place.coordinates.longitude.toString(),
									query: place.displayValue ?? place.name,
								});
								navigate(`/courses/search?${params.toString()}`);
							}}
							onSelectRegion={(region) => {
								const params = new URLSearchParams({ regionIds: region.id, query: region.displayValue ?? region.name });
								navigate(`/courses/search?${params.toString()}`);
							}}
							inputClass="ps-10 py-4"
							mobileInputClass="p-4"
							placeholder="Search by city or course name"
						/>
					</div>
					<LinkButton appearance="current" href="/courses/directory" class="mx-auto w-fit text-white">
						View full list<span class="sr-only"> of Troon Golf Courses</span>
						<Icon name="arrow-right-md" />
					</LinkButton>
				</div>
			</Hero>
			<Container class="flex flex-col gap-8">
				<SearchFacilitiesContent />
			</Container>
		</>
	);
}
export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
