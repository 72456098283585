import { twJoin } from '@troon/tailwind-preset/merge';
import { Show } from 'solid-js';
import { Icon } from '@troon/icons';
import { Picture } from './picture';
import { Heading } from './text';
import { LinkButton } from './link';
import type { ComponentProps, JSX, ParentProps } from 'solid-js';

type P = ParentProps<{
	level?: ComponentProps<typeof Heading>['as'];
	size?: ComponentProps<typeof Heading>['size'];
	title: string;
	banner?: string;
	bannerAlt?: string;
	img: string;
	imgAlt?: string;
}>;

type WithLinkProps = P & {
	url: string;
	linkText: JSX.Element;
};
type WithoutLinkProps = P & {
	url?: never;
	linkText?: never;
};

export function Card(props: WithoutLinkProps | WithLinkProps) {
	return (
		<div class={twJoin('relative overflow-hidden rounded border border-neutral', props.url && 'group/benefit')}>
			<Show when={props.banner}>
				{(bg) => (
					<div class="-z-0 -mb-20 hidden w-full overflow-hidden md:block">
						<Picture
							src={bg()}
							alt={props.bannerAlt ?? ''}
							width={400}
							height={150}
							sizes="26rem"
							class="w-full bg-center object-contain transition-transform group-hover/benefit:scale-105"
							loading="lazy"
							crop="center"
							mode="crop"
						/>
					</div>
				)}
			</Show>
			<div class={twJoin('flex flex-col gap-4 p-4 md:p-6')}>
				<div class="z-0 aspect-square w-fit rounded border border-neutral bg-white p-2">
					<Picture
						src={props.img}
						width={100}
						height={100}
						sizes="6rem"
						class="aspect-square w-24"
						crop="center"
						alt={props.imgAlt ?? ''}
						loading="lazy"
						mode="contain"
					/>
				</div>
				<div class="z-0 flex flex-col gap-1">
					<Heading as={props.level ?? 'h4'} size={props.size}>
						{props.title}
					</Heading>
				</div>
				{props.children}
				<Show when={props.url}>
					<LinkButton href={props.url} class="-ms-2 flex w-fit flex-row gap-2 after:absolute after:inset-0 after:z-0">
						{props.linkText}
						<Icon name="chevron-right" />
					</LinkButton>
				</Show>
			</div>
		</div>
	);
}
