import { createMemo } from 'solid-js';
import { createDollarFormatter } from '../../../modules/number-formatting';

/**
 * Extracted out so suspense works correctly
 */
export function PriceRange(props: { prices?: Array<{ min: number; max: number }> }) {
	const dollarFormatter = createDollarFormatter('en');
	const feeString = createMemo(() => {
		const prices = Object.values(props.prices ?? {}).flat();
		if (!prices.length) {
			return null;
		}
		const [min, max] = prices.reduce(
			(memo, price) => {
				return [Math.min(memo[0], price.min), Math.max(memo[1], price.max)];
			},
			[Infinity, -Infinity] as [number, number],
		);

		if (min !== max) {
			return dollarFormatter().formatRange(min, max);
		}
		return dollarFormatter().format(min);
	});

	return <>{feeString()}</>;
}
