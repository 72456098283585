import { createTokenizer, createToken, resolveTokens } from '@solid-primitives/jsx-tokenizer';
import { Icon } from '@troon/icons';
import { twMerge } from '@troon/tailwind-preset/merge';
import { createEffect, createSignal, createUniqueId, For, mergeProps, Show } from 'solid-js';
import type { ParentProps, Setter } from 'solid-js';

type Props = ParentProps<{
	containerClass?: string;
	itemClass?: string;
	controls?: 'inline' | 'after';
}>;

export function ContentCarousel(inputProps: Props) {
	const props = mergeProps({ controls: 'after' }, inputProps);
	const tokens = resolveTokens(Tokenizer, () => props.children);
	const [scroller, setScroller] = createSignal<HTMLDivElement>();
	const [current, setCurrent] = createSignal(0);

	function next() {
		setCurrent((s) => Math.min(s + 1, tokens().length - 1));
	}

	function prev() {
		setCurrent((s) => Math.max(s - 1, 0));
	}

	createEffect(() => {
		const scrollEl = scroller();
		if (!scrollEl) {
			return;
		}
		const { width } = scrollEl.getBoundingClientRect();
		scrollEl.style.transform = `translate3d(-${current() * width}px, 0, 0)`;
	});

	return (
		<div class="relative flex flex-col gap-4">
			<div class={twMerge('overflow-hidden', props.containerClass)}>
				<div
					class="relative flex h-fit items-stretch justify-stretch transition-transform duration-300"
					ref={setScroller}
				>
					<For each={tokens()}>
						{(token) => (
							<div class={twMerge('relative w-full shrink-0 grow', props.itemClass)} id={token.data.id}>
								{token.data.children}
							</div>
						)}
					</For>
				</div>
			</div>
			<Show when={props.controls === 'inline'}>
				<div class="pointer-events-none absolute inset-x-0 bottom-6 z-20 flex justify-center">
					<ContentCarouselDots count={tokens().length} current={current()} setCurrent={setCurrent} />
				</div>
				<div class="pointer-events-none absolute inset-x-0 z-30 flex h-full items-end justify-between gap-4 p-4 text-white md:items-center">
					<ContentCarouselPrev current={current()} onClick={() => prev()} />
					<ContentCarouselNext onClick={() => next()} current={current()} count={tokens().length} />
				</div>
			</Show>
			<Show when={props.controls === 'after'}>
				<div class="flex justify-between">
					<ContentCarouselDots count={tokens().length} current={current()} setCurrent={setCurrent} />
					<div class="flex gap-4">
						<ContentCarouselPrev current={current()} onClick={() => prev()} />
						<ContentCarouselNext onClick={() => next()} current={current()} count={tokens().length} />
					</div>
				</div>
			</Show>
		</div>
	);
}

const Tokenizer = createTokenizer<Token>({ name: 'ContentCarousel' });

const CarouselItem = createToken(Tokenizer, (props) => ({ ...props, type: 'item', id: createUniqueId() }));
type Token = ParentProps<{ type: 'item'; id: string; name?: string }>;

ContentCarousel.Item = CarouselItem;

function ContentCarouselDots(props: { count: number; current: number; setCurrent: Setter<number> }) {
	return (
		<ol class="pointer-events-auto flex gap-2">
			<For each={[...Array(props.count).keys()]}>
				{(token, i) => (
					<li class="group" aria-current={i() === props.current}>
						<button
							class="size-3 rounded-full bg-neutral/50 outline-none hover:bg-brand-100 focus-visible:ring focus-visible:ring-brand-100 focus-visible:ring-offset-2 focus-visible:ring-offset-white group-aria-current:bg-brand-500"
							onClick={() => {
								props.setCurrent(i());
							}}
						>
							<span class="sr-only">Go to item</span>
						</button>
					</li>
				)}
			</For>
		</ol>
	);
}

function ContentCarouselPrev(props: { current: number; onClick: () => void }) {
	return (
		<button
			onClick={() => props.onClick()}
			disabled={props.current === 0}
			class="pointer-events-auto flex size-12 items-center justify-center rounded-full border border-neutral outline-none focus-visible:ring focus-visible:ring-brand-100 focus-visible:ring-offset-2 focus-visible:ring-offset-white enabled:hover:border-brand enabled:hover:bg-brand-100 enabled:hover:text-brand-600 disabled:text-neutral-400"
		>
			<Icon name="arrow-left-md" />
			<span class="sr-only">Previous item</span>
		</button>
	);
}

function ContentCarouselNext(props: { count: number; current: number; onClick: () => void }) {
	return (
		<button
			onClick={() => props.onClick()}
			disabled={props.current === props.count - 1}
			class="pointer-events-auto flex size-12 items-center justify-center rounded-full border border-neutral outline-none focus-visible:ring focus-visible:ring-brand-100 focus-visible:ring-offset-2 focus-visible:ring-offset-white enabled:hover:border-brand enabled:hover:bg-brand-100 enabled:hover:text-brand-600 disabled:text-neutral-400"
		>
			<Icon name="arrow-right-md" />
			<span class="sr-only">Next item</span>
		</button>
	);
}
