import { createContext, createEffect, createSignal, splitProps, createUniqueId } from 'solid-js';
import { useSubmission } from '@solidjs/router';
import { twMerge } from '@troon/tailwind-preset/merge';
import { mergeRefs } from '@solid-primitives/refs';
import type { OperationResult, TypedDocumentNode } from '@urql/core';
import type { Action } from '@solidjs/router';
import type { Accessor, JSX } from 'solid-js';

type Rec = Record<string, unknown>;
type Mutation = { __typename?: string; [key: string]: unknown };

type Props<
	R extends Rec = Rec,
	V extends Rec = Rec,
	M extends Mutation = Mutation,
> = JSX.FormHTMLAttributes<HTMLFormElement> & {
	action: Action<[FormData], OperationResult<M, R> | void>;
	document?: TypedDocumentNode<M, V>;
	suppressRequired?: boolean;
	id?: string;
};

// TODO: enable solid/reactivity rule and fix

export function Form<R extends Rec, V extends Rec>(props: Props<R, V>) {
	const [form, setForm] = createSignal<HTMLFormElement>();
	const required =
		// eslint-disable-next-line solid/reactivity
		props.suppressRequired || !props.document
			? {}
			: // eslint-disable-next-line solid/reactivity
				props.document?.definitions.reduce((memo: Record<string, boolean>, defs) => {
					if (defs.kind !== 'OperationDefinition' || !defs.variableDefinitions) {
						return memo;
					}
					for (const def of defs.variableDefinitions) {
						memo[def.variable.name.value] = def.type.kind === 'NonNullType';
					}
					return memo;
				}, {});

	const [, formAttrs] = splitProps(props, ['document', 'suppressRequired']);
	const [fieldErrors, setFieldErrors] = createSignal<Record<string, Array<string>>>({});
	const [errors, setErrors] = createSignal<Array<string>>([]);
	// eslint-disable-next-line solid/reactivity
	const formId = props.id || createUniqueId();

	// eslint-disable-next-line solid/reactivity
	const data = useSubmission(props.action, ([data]) => data.get('__formId') === formId);

	createEffect(() => {
		if (!data.result?.error) {
			setFieldErrors({});
			setErrors([]);
			return;
		}

		const newErrors: Record<string, Array<string>> = {};
		for (const err of data.result?.error?.graphQLErrors ?? []) {
			if (typeof err.extensions.displayMessage === 'string') {
				setErrors((errs) => [...errs, err.extensions.displayMessage] as Array<string>);
			} else if ((err.extensions.fields as Array<unknown>)?.length === 0) {
				setErrors((errs) => [...errs, 'There was an error completing your request. Please try again.']);
			}

			for (const fieldErr of (err.extensions.fields ?? []) as Array<{ field: string; displayMessage: string }>) {
				if (!(fieldErr.field in newErrors)) {
					newErrors[fieldErr.field] = [];
				}
				newErrors[fieldErr.field]!.push(fieldErr.displayMessage);
			}
		}
		setFieldErrors(newErrors);

		(form()?.querySelector('[aria-invalid]') as HTMLInputElement)?.focus();
	});

	return (
		<FormContext.Provider value={{ data, errors, fieldErrors, required }}>
			<form
				{...formAttrs}
				method="post"
				ref={mergeRefs(props.ref, setForm)}
				class={twMerge('flex flex-col gap-y-6', formAttrs.class)}
			>
				<input type="hidden" name="__formId" value={formId} />
				{props.children}
			</form>
		</FormContext.Provider>
	);
}

type CTX = {
	data: ReturnType<typeof useSubmission>;
	errors: Accessor<Array<string>>;
	fieldErrors: Accessor<Record<string, Array<string>>>;
	required: Record<string, boolean>;
};

export const FormContext = createContext<CTX>({
	data: { pending: false, url: '', clear: () => {}, retry: () => {}, input: [], error: null },
	errors: () => [],
	fieldErrors: () => ({}),
	required: {},
});

export function disableAutocomplete(opts: {
	autocomplete?: JSX.InputHTMLAttributes<HTMLInputElement>['autocomplete'] | undefined;
	[key: string]: unknown;
}) {
	return {
		autocomplete: opts.autocomplete,
		'data-1p-ignore': opts.autocomplete === 'off' ? true : undefined,
		'data-lpignore': opts.autocomplete === 'off' ? 'true' : undefined,
		'data-form-type': opts.autocomplete === 'off' ? 'other' : undefined,
		'data-bwignore': opts.autocomplete === 'off' ? true : undefined,
	} as const;
}
