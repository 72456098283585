import { Title } from '@solidjs/meta';
import {
	Button,
	Errors,
	Field,
	FieldDescription,
	FieldErrors,
	Heading,
	Input,
	Label,
	Option,
	Select,
	TextField,
	TextLink,
	Container,
	Breadcrumbs,
	BreadcrumbItem,
	Section,
	Page,
} from '@troon/ui';
import { Formspree, formspreeAction, useFormspree } from '@troon/forms';
import { createSignal, For, Match, Switch } from 'solid-js';
import { useLocation, useSubmission } from '@solidjs/router';
import { NoHydration } from 'solid-js/web';
import dayjs from '@troon/dayjs';
import { Icon } from '@troon/icons';
import { getConfigValue } from '../../modules/config';
import { useUser } from '../../providers/user';
import { Grid, GridFive, GridSeven } from '../../components/layouts/grid';

export default function RewardsPointsRequestPage() {
	const formAction = useFormspree(action);
	const submission = useSubmission(formAction);
	const user = useUser();
	const [firstname, setFirstname] = createSignal(user()?.me.firstName ?? '');
	const [lastname, setLastname] = createSignal(user()?.me.lastName ?? '');
	const loc = useLocation();

	return (
		<>
			<Title>Rewards Points Request | Troon</Title>

			<Container>
				<div class="pt-4">
					<Breadcrumbs>
						<BreadcrumbItem href="/rewards">Troon Rewards</BreadcrumbItem>
						<BreadcrumbItem href={loc.pathname}>Request missing points</BreadcrumbItem>
					</Breadcrumbs>
				</div>

				<Page>
					<Grid>
						<GridFive>
							<Section>
								<Icon name="golf-cart-custom" class="size-24" />
								<Heading as="h2">Request Missing Troon Rewards Points</Heading>

								<p>
									Missing points from the past 90 days? Please fill out the form below for each missing transaction. If
									you do not see a course listed in the dropdown, they likely do not participate in Troon/OB Rewards.
								</p>

								<p>
									If you have any questions or need assistance, please submit a Contact Us Request Form. Please see the{' '}
									<TextLink href="/rewards/terms">Troon Rewards Terms and Conditions</TextLink>.
								</p>
							</Section>
						</GridFive>

						<GridSeven class="rounded border border-neutral bg-neutral-100 p-4 md:p-8">
							<Switch>
								<Match when={submission.result}>
									<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
										<Icon name="circle-check-custom" class="text-8xl" />
										<Heading as="h1" size="h2">
											Request received!
										</Heading>
										<p class="text-lg">
											Thanks for contacting us! Our team is reviewing your request and we will get in touch with you
											shortly.
										</p>
									</div>
								</Match>
								<Match when>
									<>
										<Formspree action={formAction}>
											<div class="flex w-full flex-wrap gap-6">
												<TextField name="First name" required class="grow">
													<Label>First name</Label>
													<Input
														autocomplete="given-name"
														readonly={!!user()?.me}
														value={user()?.me.firstName}
														onInput={(e) => setFirstname(e.currentTarget.value)}
													/>
												</TextField>

												<TextField name="Last name" required class="grow">
													<Label>Last name</Label>
													<Input
														autocomplete="family-name"
														readonly={!!user()?.me}
														value={user()?.me.lastName}
														onInput={(e) => setLastname(e.currentTarget.value)}
													/>
												</TextField>
											</div>

											<input
												type="hidden"
												name="subject"
												value={`Rewards Points Request - ${firstname()} ${lastname()}`}
											/>

											<TextField name="Email" required>
												<Label>Email address</Label>
												<Input
													autocomplete="email"
													inputMode="email"
													readonly={!!user()?.me}
													value={user()?.me.email}
												/>
											</TextField>

											<TextField name="Troon Rewards Number" required>
												<Label>Troon Rewards Number</Label>
												<Input
													autocomplete="email"
													inputMode="email"
													readonly={!!user()?.me}
													value={user()?.me.troonRewardsId}
												/>
											</TextField>

											<TextField name="Date of play" required>
												<Label>Date and time of play</Label>
												<Input
													type="datetime-local"
													min={dayjs().startOf('day').subtract(91, 'days').format('YYYY-MM-DDTHH:mm')}
													max={dayjs().format('YYYY-MM-DDTHH:mm')}
												/>
											</TextField>

											<Field name="Course played" required>
												<Label>Course played</Label>
												<Select>
													<NoHydration>
														<Option disabled selected class="hidden" />
														<For each={facilities}>{(facility) => <Option>{facility}</Option>}</For>
													</NoHydration>
												</Select>
												<FieldErrors />
											</Field>

											<TextField name="Name on reservation">
												<Label>Name on reservation</Label>
												<Input />
												<FieldDescription>If different than your own.</FieldDescription>
											</TextField>

											<Errors />
											<Button type="submit" class="w-fit">
												Submit request
											</Button>
										</Formspree>
									</>
								</Match>
							</Switch>
						</GridSeven>
					</Grid>
				</Page>
			</Container>
		</>
	);
}

const action = formspreeAction(getConfigValue('FORMSPREE_PROJECT_ID'), 'rewards-points-request');

const facilities = [
	'Ak-Chin Southern Dunes Golf Club',
	'Al Hamra Golf Club',
	'Al Houara Golf Club',
	'Al Mouj Golf',
	'Al Zorah Golf Club',
	'Alhambra Golf Course',
	'Antelope Hills Golf Course',
	'Arabian Ranches Golf Club',
	'Arizona National Golf Club',
	'Arlington Ridge Golf Club',
	'Avon Fields Golf Course',
	'Baylands Golf Links',
	'Bayonet and Black Horse',
	'Bayside Resort Golf Club',
	'Bear Trap Dunes Golf Club',
	'Bellevue Golf Course',
	'Bent Creek Golf Course',
	'Billy Caldwell',
	'Blue Cypress Golf Club',
	'Boulders Resort & Spa',
	'Bradford Creek Golf Course',
	'Brambleton Golf Course',
	'Brentwood Golf Course',
	'Brookstone Park',
	'Bucknell Golf Club',
	'Burnham Woods Golf Course',
	'California Country Club',
	'Cape Royal Golf Club',
	'Castle Oaks Golf Club',
	'Cedarcrest Golf Course',
	'Centennial Golf Club',
	'Charbonneau Golf Club',
	'Château Élan Golf Club',
	'Chick Evans Golf Course',
	'Cider Ridge Golf Club',
	'Classic Club',
	'Colony West Country Club',
	'Compass Pointe Golf Courses',
	'Copper Canyon Golf Club',
	'Costa Navarino',
	'Coyote Springs',
	'Cranbury Golf Club',
	'Crawfordsville Municipal Golf Course',
	'Deerfield Golf Club',
	'Delcastle Golf Course',
	'Dell Urich Golf Club',
	'Dove Valley Ranch Golf Club',
	'Dubai Hills Golf Club',
	'Durango Hills Golf Club',
	'Eagle Landing Golf Club',
	'Eagle Mountain Golf Club',
	'Ed Oliver Golf Club',
	'Edgebrook Golf Course',
	'El Conquistador Golf Club',
	'El Rio Golf Course',
	'Els Club Desaru Coast - Ocean Course',
	'Els Club Desaru Coast - Valley Course',
	'Enger Park Golf Course',
	'Fairways Country Club',
	'Falmouth Country Club',
	'Fellows Creek Golf Course',
	'Finkbine Golf Course',
	'Foothills Golf Club',
	'Forest Greens',
	'Forest Ridge Golf Club',
	'Fox Hollow Golf Club',
	'Fred Enke Golf Course',
	'Gamble Sands',
	'Garrisons Lake Golf Club',
	'Gateway Park Golf Course',
	'George Dunne',
	'Giants Ridge Golf',
	'Glenview Golf Course',
	'Golf Baytown',
	'Golf Club of Estrella',
	'Golf Club of Ocala',
	'Grass Clippings at Rolling Hills',
	'Greenbriar Oceanaire Golf & Country Club',
	'Harry Semrow',
	'Heritage Ranch',
	'Hiddenbrooke Golf Club',
	'Highland Park Golf Course',
	'Highland Woods',
	'Hokuala Golf Course Kauai',
	'Hyatt Hills Golf Complex',
	'Indian Boundary',
	'Indian River Preserve Golf Club',
	'Indian Wells Golf Resort',
	'Interbay Golf Center',
	'Ironhorse Golf Club',
	'Jack Frost National Golf Club',
	'Jackson Park Golf Course - Seattle',
	'Jefferson Park Golf Course',
	'Joe Louis Golf Course',
	'Johnson Park Golf Course',
	'Ka’anapali Golf Courses',
	'Kapalua',
	'Kenton County Golf Courses',
	'King & Bear at World Golf Village Resort',
	'Kinston Country Club',
	'Kissing Tree Golf Club',
	'Knoxville Municipal Golf Course',
	'La Cantera Resort & Spa',
	'LA VIE Club',
	'Lagoon Park Golf Course',
	'Lake Ashton Golf Club',
	'Lake Monticello Golf Course',
	'Lake Padden Golf Course',
	'Lake Ridge Golf Course',
	'LaoLao Bay Golf & Resort',
	'Las Colinas Golf & Country Club',
	'Legion Memorial Golf Course',
	'Lexington Golf & Country Club',
	'Longbow Golf Club',
	'Lookout Mountain Golf Club',
	'Lynnwood Golf Course',
	'Maderas Golf Club',
	'Madinaty Golf Club',
	'Maggie Valley Club & Resort',
	'Magnolia Green Golf Club',
	'Marquette Park',
	'Mauna Lani Resort',
	'Meadowlark Golf Course',
	'Mohawk Park Golf Course',
	'Mollymook Golf Club',
	'Monarch Beach Golf Links',
	'Montgomerie Golf Club Dubai',
	'Mountain Falls Golf Club',
	'Mt Graham Golf Club',
	'Mystic Creek Golf Club',
	'Native Oaks',
	'New Berlin Hills Golf Course',
	'Ocean Course at Hokuala',
	'Ocean Shores Golf Course',
	'Orchard Valley Golf Course',
	'Pacific Harbour Golf & Country Club',
	'Page Belcher Golf Course',
	'Papago Golf Course',
	'Pearl Country Club',
	'PGA National Czech Republic',
	'Pine Ridge Golf Club',
	'Pinetop Lakes Golf & Country Club',
	'Poston Butte Golf Club',
	'Potomac Shores Golf Club',
	'Power Ranch Golf Club',
	'Prince William Golf',
	'Princeville Makai Golf Club',
	'Pronghorn',
	'Purgatory Golf Club',
	'Rancho Mañana Golf Club',
	'Randolph North Golf Course',
	'Red Hawk Golf and Resort',
	'Rio Mar Country Club',
	'River Oaks Golf Course',
	'Rock Creek Golf Club',
	'Rock Manor Golf Course',
	'Rollingstone Ranch Golf Club',
	'Royal Greens Golf & Country Club',
	'SaddleBrooke Two (The Preserve & Mountain View)',
	'Salgados Golf Course',
	'Sanctuary Lake Golf Course',
	'Sandia Golf Club',
	'Scott Park Golf Links',
	'Seaview Hotel and Golf Club',
	'Sedona Golf Resort',
	'Shadow Hills Golf Club',
	'Shoop Park Golf Course',
	'Shorecliffs Golf Club',
	'Silverado Resort and Spa',
	'Silverbell Golf Course',
	'Slammer & Squire at World Golf Village Resort',
	'Split Rail Links & Golf Club',
	'St. Johns Golf & Country Club',
	'Sterling Grove Golf + Country Club',
	'Stonetree Golf Club',
	'Sun ‘n Lake Golf & Country Club',
	'Sun City Hilton Head',
	'Sylvan Glen Golf Course',
	'Teleli Golf Club',
	'Thanksgiving Point',
	'The Allegria Golf Club',
	'The Club at Starr Pass',
	'The Els Club, Dubai',
	'The Golf Club at Mansion Ridge',
	'The Golf Club at Moffett Field',
	'The Grange Golf Club',
	'The Grove',
	'The Hideout',
	'The Island Golf Club & Restaurant',
	'The Lodge of Four Seasons',
	'The Phoenician',
	'The Preserve at Eisenhower',
	'The Rawls Course at Texas Tech',
	'The Refuge',
	'The Ridge at Castle Pines',
	'The Ridge Golf & Event Center',
	'The Track, Meydan Golf',
	'The Westin Kierland Golf Club',
	'The Westin Savannah Harbor Golf Resort & Spa',
	'Tiburón Golf Club',
	'Tri-Mountain Golf Course',
	'Troon North Golf Club',
	'Tustin Ranch',
	'Volcano Golf Course',
	'Wallkill Golf Club',
	'Walter Hall Golf Couse',
	'Washington Park Golf Course',
	'West Seattle Golf Course',
	'White Deer Golf Course',
	'White Mountain Golf Park',
	'Whittle Springs Golf Course',
	'Willowbrook Golf Course',
	'Willowick Municipal Golf Course',
	'Wintonbury Hills Golf Course',
	'Yocha Dehe Golf Club',
	"Captain's Cove Golf & Yacht Club",
	"Water's Edge Golf Course",
];
