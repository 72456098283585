import { createAsync } from '@solidjs/router';
import { For, Match, Show, Suspense, Switch } from 'solid-js';
import {
	ActivityIndicator,
	Button,
	CheckList,
	CheckListItem,
	Heading,
	Link,
	Picture,
	ProductCard,
	Tag,
	TroonAccessPlusProduct,
	TroonAccessProduct,
} from '@troon/ui';
import { Icon } from '@troon/icons';
import { useTrackEvent } from '@troon/analytics';
import { twJoin } from '@troon/tailwind-preset/merge';
import { getAccessProducts } from '../providers/card';
import { useUser } from '../providers/user';
import { TroonCardSubscriptionProductType } from '../graphql';
import { usePersisted } from '../providers/persistence-store';
import type { JSXElement } from 'solid-js';

type Props = {
	banners?: Record<TroonCardSubscriptionProductType, JSXElement>;
	floating?: boolean;
	forUser?: boolean;
	golfPass?: boolean;
	isGift?: boolean;
	redirect?: string;
	urlPrefix?: string;
};

export function AccessProducts(props: Props) {
	const [params] = usePersisted();
	const data = createAsync(() => getAccessProducts({ promoCode: params.promoCode?.value }), { deferStream: true });
	const user = useUser();
	const trackEvent = useTrackEvent();

	return (
		<Suspense fallback={<ActivityIndicator />}>
			<Show when={data()?.products}>
				{(products) => (
					<ul class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:gap-6">
						<For each={products()}>
							{(card, index) => (
								<li
									class={twJoin(
										'shrink grow',
										index() === 1 ? 'row-start-1' : 'row-start-2 overflow-hidden md:row-start-1',
									)}
								>
									<ProductCard
										banner={props.banners && props.banners[card.type]}
										isCurrent={props.forUser !== false && user()?.me?.troonAccessProductType === card.type}
										floating={props.floating}
										variant={card.type === TroonCardSubscriptionProductType.TroonAccessPlus ? 'plus' : undefined}
									>
										<div class="relative z-10 flex flex-col gap-4">
											<div class="flex flex-row items-start justify-between">
												<Heading as="h2" class="flex items-center gap-2">
													<Switch fallback={<TroonAccessProduct class="h-8" />}>
														<Match
															when={props.golfPass && card.type === TroonCardSubscriptionProductType.TroonAccessPlus}
														>
															<Picture
																src={golfpassLogo}
																alt="GolfPass+"
																width={708}
																height={66}
																sizes="354px"
																// eslint-disable-next-line tailwindcss/no-arbitrary-value
																class="w-[354px]"
															/>
														</Match>
														<Match when={card.type === TroonCardSubscriptionProductType.TroonAccessPlus}>
															<TroonAccessPlusProduct class="h-8" current />
														</Match>
													</Switch>
													<span class="sr-only">{card.subscriptionName}</span>
												</Heading>
												<Show
													when={
														!props.golfPass &&
														props.forUser !== false &&
														user()?.me?.troonAccessProductType === card.type
													}
												>
													<Tag>Current Plan</Tag>
												</Show>
											</div>
											<Show when={card.description}>
												<p>{card.description}</p>
											</Show>
										</div>
										<p
											class={twJoin(
												'mb-2 font-semibold',
												card.subtotalAmount.value !== card.totalAmount.value
													? 'text-4xl sm:text-3xl lg:text-4xl'
													: 'text-4xl',
											)}
										>
											<Show when={card.subtotalAmount.value !== card.totalAmount.value}>
												<span class="me-2 font-normal text-neutral-700 line-through">
													{card.subtotalAmount.displayValue}
												</span>
											</Show>
											<span>{card.totalAmount.displayValue}</span>
											<Show when={card.autoRenew && !card.disclosure && !props.isGift}>/yr</Show>
											<Show when={card.disclosure}>
												{(disclosure) => <span class="block pt-2 text-sm font-normal">{disclosure()}</span>}
											</Show>
										</p>
										<Show when={props.isGift}>
											<p class="-mt-4 text-sm">Gift includes 1 year subscription. No auto-renewal.</p>
										</Show>

										<Switch>
											<Match
												when={
													!props.isGift && props.forUser !== false && user()?.me.troonAccessProductType === card.type
												}
											>
												<Button as={Link} href="/account/access" appearance="secondary-current" class="grow-0">
													Manage Plan
												</Button>
											</Match>
											<Match when={props.forUser === false || props.isGift || !user()?.me.troonAccessProductType}>
												<Button
													as={Link}
													rel="nofollow"
													href={`${props.urlPrefix ?? ''}/access/${props.isGift ? 'gift' : 'checkout'}/${card.id}`}
													state={{ redirect: props.redirect }}
													appearance={card.isPopular ? 'primary' : 'secondary'}
													onClick={() => {
														trackEvent('didSelectAccessProduct', {
															productType: 'troonAccess',
															productId: card.id,
														});
													}}
													// HACK: `inset-0` makes it only set relative to the button/link when in the `active` state
													// due to the `scale-95` style. Ensure parent has `overflow-hidden`
													class="grow-0 after:absolute after:-inset-96"
												>
													<span>
														Select<span class="sr-only"> {card.subscriptionName}</span> and Continue
													</span>{' '}
													<Icon name="arrow-right-md" />
												</Button>
											</Match>
										</Switch>

										<div class="flex grow flex-col gap-4">
											<p>Includes:</p>
											<CheckList>
												<For each={card.valueProps}>{(value) => <CheckListItem>{value}</CheckListItem>}</For>
												<Show when={props.golfPass && card.type === TroonCardSubscriptionProductType.TroonAccessPlus}>
													<CheckListItem>
														<b>1 year of GolfPass+ (a $400 value)</b>
													</CheckListItem>
												</Show>
											</CheckList>
										</div>
									</ProductCard>
								</li>
							)}
						</For>
					</ul>
				)}
			</Show>
		</Suspense>
	);
}

const golfpassLogo =
	'https://images.ctfassets.net/rdsy7xf5c8dt/6zCmxuYsBp7ARZ66T8hkaM/5cc6af82de3f1146b47884b6ccdca5dd/Access_GolfPass_logo__1_.png';
