import { mergeProps, splitProps, createMemo } from 'solid-js';
import { A, useLocation } from '@solidjs/router';
import { twJoin, twMerge } from '@troon/tailwind-preset/merge';
import { Dynamic } from 'solid-js/web';
import { useFocusable } from '../providers/focusable';
import { useBaseUrl } from '../providers/global';
import type { ComponentProps } from 'solid-js';

type Props = ComponentProps<typeof A>;

export function Link(inputProps: Props) {
	const location = useLocation();
	const focusableProps = useFocusable<Props>();
	const props = mergeProps(focusableProps, inputProps);
	const baseUrl = useBaseUrl();

	const baseHost = new URL(baseUrl).host;

	const url = createMemo(() => {
		try {
			return new URL(props.href);
		} catch {
			return new URL(props.href, baseUrl);
		}
	});

	return (
		<Dynamic
			component={url().host === baseHost ? A : 'a'}
			aria-current={`${location.pathname}${location.search}` === props.href ? 'page' : undefined}
			{...props}
			class={twMerge(
				'rounded-sm outline-none focus-visible:ring-1 focus-visible:ring-brand-500 focus-visible:ring-offset-2',
				props.class,
			)}
			target={url().host === baseHost ? undefined : '_blank'}
			href={
				props['aria-disabled']
					? ''
					: typeof props.href === 'string'
						? props.href.startsWith('#')
							? props.href
							: withoutTrailingSlash(baseUrl, props.href)
						: props.href
			}
		/>
	);
}

export function TextLink(props: Props) {
	return (
		<Link
			{...props}
			class={twMerge(
				'text-brand underline-offset-2 transition-colors duration-200 hover:underline hover:decoration-solid focus-visible:ring-1 focus-visible:ring-brand-500 focus-visible:ring-offset-2 active:text-brand-700',
				props.class,
			)}
		/>
	);
}

type ButtonProps = Omit<ComponentProps<typeof A>, 'href'> & {
	href?: ComponentProps<typeof A>['href'] | undefined;
	size?: 'sm' | 'lg';
	appearance?: 'primary' | 'current';
};

export function LinkButton(inputProps: ButtonProps) {
	const merged = mergeProps({ size: 'lg', appearance: 'primary' }, inputProps);
	const [props, spread] = splitProps(merged, ['size', 'appearance']);
	return (
		// @ts-expect-error
		<Dynamic
			component={spread.href ? Link : 'button'}
			{...spread}
			class={twMerge(
				twJoin(
					'inline-flex grow-0 cursor-pointer flex-wrap items-center justify-center gap-x-2 rounded-md px-2 py-1 text-center outline-none transition-all duration-200 focus-visible:ring focus-visible:ring-offset-0 aria-disabled:cursor-not-allowed aria-disabled:active:scale-100',

					props.size === 'lg' && 'text-base',
					props.size === 'sm' && 'text-sm',

					props.appearance === 'primary' &&
						'text-brand hover:bg-brand-100 focus-visible:ring-brand-100 active:bg-brand-200 aria-disabled:bg-transparent aria-disabled:text-neutral-700',

					props.appearance === 'current' &&
						'text-current hover:bg-current/10 focus-visible:ring-current/30 active:bg-current/30 aria-disabled:bg-transparent aria-disabled:text-neutral-700',
				),
				spread.class,
			)}
		/>
	);
}

function withoutTrailingSlash(host: string, href: string) {
	if (/^(http|tel:)/.test(href)) {
		return href;
	}
	const url = new URL(href, host);
	if (url.pathname.endsWith('/')) {
		url.pathname = url.pathname.replace(/\/$/, '');
		return url.toString().replace(host, '');
	}

	return href;
}
