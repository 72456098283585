import { Heading, BreadcrumbItem, Container, Section, Button, Link, HorizontalRule, Page } from '@troon/ui';
import { useCurrentMatches, useLocation } from '@solidjs/router';
import { createMemo } from 'solid-js';
import { Meta, Title } from '@solidjs/meta';
import { NoHydration } from 'solid-js/web';
import { getConfigValue } from '../../modules/config';
import { Hero } from '../../components/hero/photo';
import { Grid, GridFive, GridSeven } from '../../components/layouts/grid';
import { ContactForm } from './_components/contact-form';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

type Info = { title: string; description: string; hero?: string };

export default function PrivateClubsManagementPage(props: RouteSectionProps) {
	const loc = useLocation();
	const matches = useCurrentMatches();
	const data = createMemo<Info>(() => {
		return matches().find((match) => match.route.info?.title)!.route.info as unknown as Info;
	});

	return (
		<>
			<NoHydration>
				<Title>{data().title} | Golf Club Management Services | Troon</Title>
				<Meta name="description" content={data().description} />
				<Hero
					src={data().hero ?? `${getConfigValue('IMAGE_HOST')}/digital/hero/about.jpg`}
					crop="center"
					breadcrumbs={() => (
						<>
							<BreadcrumbItem href="/management-services">Management Services</BreadcrumbItem>
							<BreadcrumbItem href={loc.pathname}>{data().title}</BreadcrumbItem>
						</>
					)}
				>
					<Heading as="h1">{data().title}</Heading>
					<p>{data().description}</p>
				</Hero>
			</NoHydration>

			<Container>
				<Page>
					<Section>
						<Grid>
							<>
								<GridSeven class="flex flex-col gap-8 md:gap-12">{props.children}</GridSeven>
							</>
							<GridFive>
								<div class="sticky top-24 rounded border border-neutral-400 bg-neutral-100 p-6">
									<ContactForm />
								</div>
							</GridFive>
						</Grid>
					</Section>

					<HorizontalRule />

					<Section class="flex flex-col items-center justify-center">
						<Button as={Link} href="/management-services" class="w-fit">
							Return to Management Services
						</Button>
					</Section>
				</Page>
			</Container>
		</>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
