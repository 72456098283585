import { createEffect, createSignal, createUniqueId, splitProps } from 'solid-js';
import { useSubmission } from '@solidjs/router';
import { FormContext } from '@troon/ui';
import type { Action } from '@solidjs/router';
import type { JSX } from 'solid-js';

type FormspreeProps = JSX.FormHTMLAttributes<HTMLFormElement> & {
	action: Action<[data: FormData], Record<string, unknown>>;
	suppressRequired?: boolean;
};

export function Formspree(props: FormspreeProps) {
	const [form, setForm] = createSignal<HTMLFormElement>();
	const required = {};

	const [, formAttrs] = splitProps(props, ['suppressRequired']);
	const [fieldErrors, setFieldErrors] = createSignal<Record<string, Array<string>>>({});
	const [errors, setErrors] = createSignal<Array<string>>([]);
	const formId = createUniqueId();

	// eslint-disable-next-line solid/reactivity
	const data = useSubmission(props.action, ([data]) => data.get('__formId') === formId);

	createEffect(() => {
		if (!data.error?.error) {
			setFieldErrors({});
			setErrors([]);
			return;
		}

		const newErrors: Record<string, Array<string>> = {};

		setErrors((errs) => [...errs, 'There was an error completing your request. Please try again.']);

		for (const fieldErr of (data.error.errors ?? []) as Array<{ code: string; field: string; message: string }>) {
			if (!(fieldErr.field in newErrors)) {
				newErrors[fieldErr.field] = [];
			}
			newErrors[fieldErr.field]!.push(messages[fieldErr.code] ?? 'This field is invalid');
		}
		setFieldErrors(newErrors);

		(form()?.querySelector('[aria-invalid]') as HTMLInputElement)?.focus();
	});

	return (
		<FormContext.Provider value={{ data, errors, fieldErrors, required }}>
			<form
				{...formAttrs}
				enctype="multipart/form-data"
				method="post"
				ref={(el) => {
					setForm(el);
					if (typeof props.ref === 'function') {
						props.ref(el);
					} else {
						// eslint-disable-next-line solid/reactivity
						props.ref = el!;
					}
				}}
				class={`flex flex-col gap-y-6 ${formAttrs.class}`}
			>
				<input type="hidden" name="__formId" value={formId} />
				{props.children}
			</form>
		</FormContext.Provider>
	);
}

const messages: Record<string, string> = {
	REQUIRED_FIELD_MISSING: 'This field is required.',
	REQUIRED_FIELD_EMPTY: 'This field is required.',
	TYPE_EMAIL: 'Please enter a valid email address',
	TYPE_NUMERIC: 'Please enter a valid number',
	TYPE_FILE: 'Please add a file',
};
