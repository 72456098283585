import { Heading } from '@troon/ui';
import { TroonGolfLandingPages } from './_components/layout';
import type { RouteDefinition } from '@solidjs/router';
import type { ComponentProps } from 'solid-js';

export default function TroonGolfArizonaLandingPage() {
	return (
		<TroonGolfLandingPages title="Troon Golf Arizona" image="troon-golf-arizona.png" slugs={slugs} location="Arizona">
			<Heading as="h2">Experience the best golf in Arizona with Troon.</Heading>
			<p>
				Troon Golf courses in Arizona are as diverse as the Southwest itself, each woven into the rugged desert terrain
				with a unique vision and infused with the impeccable quality that is Troon Golf hallmark.
			</p>
		</TroonGolfLandingPages>
	);
}

const slugs: ComponentProps<typeof TroonGolfLandingPages>['slugs'] = {
	'ak-chin-southern-dunes-golf-club': { tel: '602.334.4700' },
	'boulders-resort-and-spa-scottsdale': { tel: '602.336.6436' },
	'dove-valley-ranch-golf-club': { tel: '480.900.4853' },
	'eagle-mountain-golf-club': { tel: '480.207.3906' },
	'foothills-golf-club': { tel: '480.900.3721' },
	'golf-club-of-estrella': { tel: '602.346.9612' },
	'longbow-golf-club': { tel: '480.359.2879' },
	'lookout-mountain-golf-club': { tel: '602.346.9342' },
	'power-ranch-golf-club': { tel: '480.900.5935' },
	'putting-world-scottsdale': { tel: '480.582.1221', url: 'https://putting-world-tgaz.book.teeitup.com' },
	'rancho-manana-golf-club': { tel: '480.992.0959' },
	'sedona-golf-resort': { tel: '928.224.8820', url: 'https://sedona-golf-resort-tgaz.book.teeitup.com' },
	'sterling-grove-golf-country-club': { tel: '888.399.4234' },
	'sunridge-canyon-golf-club': { tel: '480.944.6177', url: 'https://sunridge-canyon-golf-club-tgaz.book.teeitup.com' },
	'the-club-at-starr-pass': { tel: '520.276.1499' },
	'the-phoenician': { tel: '602.346.9549' },
	'the-westin-kierland-golf-club': { tel: '602.345.1939' },
	'troon-north-golf-club': { tel: '602.357.0433' },
	'tubac-golf-resort-and-spa': { tel: '520.413.1133' },
};

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
