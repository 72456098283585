import { Title } from '@solidjs/meta';
import { Heading, Container, BreadcrumbItem, Page, Button, Link, Picture, Section } from '@troon/ui';
import { NoHydration } from 'solid-js/web';
import { useLocation } from '@solidjs/router';
import { Hero } from '../../../components/hero/photo';
import { getConfigValue } from '../../../modules/config';
import type { RouteDefinition } from '@solidjs/router';

export default function BenefitsPage() {
	const loc = useLocation();

	return (
		<NoHydration>
			<Title>Employee Benefits | Troon</Title>
			<Hero
				src={`${getConfigValue('IMAGE_HOST')}/digital/hero/about.jpg`}
				crop="center"
				breadcrumbs={() => (
					<>
						<BreadcrumbItem href="/about">About Troon</BreadcrumbItem>
						<BreadcrumbItem href="/about/career-opportunities">Career opportunities</BreadcrumbItem>
						<BreadcrumbItem href={loc.pathname}>Employee Benefits</BreadcrumbItem>
					</>
				)}
			>
				<Heading as="h1" class="text-center text-white">
					Employee Benefits
				</Heading>
			</Hero>

			<Page>
				<Container size="small">
					<Heading as="h2">What we offer to our valued team members</Heading>
					<p>
						At Troon, we believe our Associates are our greatest strength and competitive advantage. It is Troon’s goal
						to provide a comprehensive benefits package that helps associates manage their healthcare and retirement
						needs in order to enhance their quality of life and provide peace of mind.
					</p>
					<Heading as="h3">401(k) Retirement Plan</Heading>
					<p>
						Troon offers all associates a 401(k) retirement savings plan. The plan offers a pre-tax salary deferral from
						1% to 60%. The company will match $0.25/per dollar up to the first 5% of compensation deferred, and 100%
						vesting after 3 years of service. Empower Retirement is the record keeper.
					</p>
					<Heading as="h3">Health and Dental Care</Heading>
					<p>
						Troon offers our full-time associates comprehensive, competitive Medical and Prescription Drug plans
						administered through UnitedHealthcare and CVS Caremark. Troon offers dental through Delta Dental of AZ.
					</p>
					<Heading as="h3">Vision Benefit</Heading>
					<p>
						Troon provides a supplemental vision plan through VSP. The plan provides associates with services in over
						2,000 locations, and offers an annual eye exam, savings on eye wear, and contacts.
					</p>
					<Heading as="h3">Disability Insurance</Heading>
					<p>
						Troon offers full-time associates the opportunity to elect voluntary short term disability insurance through
						NYLife.
					</p>
					<Heading as="h3">Life Insurance</Heading>
					<p>
						Troon provides basic life and accidental death coverage for all full-time associates through NYLife. We also
						offer our associates the opportunity to elect Supplemental Life Insurance for themselves, their spouse and
						their dependents.
					</p>
					<Heading as="h3">Employee Assistance Program</Heading>
					<p>
						Troon offers all associates an Employee Assistance & Wellness Support Program (EAP). Resources are available
						24/7 for confidential support and guidance, as well as assistance with referrals for further services.
					</p>
					<Heading as="h3">Flexible Spending Accounts</Heading>
					<p>
						Flexible spending accounts can save associates money by allowing the associate to pay for health care and
						dependent day care expenses with pre-tax money versus after tax money.
					</p>
					HCSA: At Troon, we offer the convenience of a Health Care Spending Card in conjunction with your HCSA (health
					care saving account). This card allows you to pay for co-payments and prescriptions at the provider or
					pharmacy. Your full election is available to use as soon as you are eligible. DCSA: The funds from your DCSA
					(dependent care savings account) are deducted each pay period and can be used to reimburse your eligible
					dependent care expenses up to the amount that is in your account at the time your claim is received.
					<Heading as="h3">Health Savings Account (HSA)</Heading>
					<p>
						Troon offers a Health Savings Account with our High Deductible Healthcare Plan options. The Health Savings
						Account can save associates money by allowing them to pay for health, dental and vision expenses with
						pre-tax money saved in the account.
					</p>
					<Heading as="h3">Legal Assistance</Heading>
					<p>
						Legal assistance is a voluntary benefit administered through The Hyatt Premier Legal Plan that provides
						telephone advice and office appointments regarding certain legal matters (excluding business, illegal
						activity and employment).
					</p>
					<Heading as="h3">Paid Time-Off</Heading>
					<p>
						All regular, full-time associates are eligible for Paid Time Off (PTO). PTO is intended to provide paid time
						off benefits for vacations and other absences due to illness or personal emergency.
					</p>
				</Container>

				<Section class="relative overflow-hidden">
					<Picture
						src={`${getConfigValue('IMAGE_HOST')}/digital/hero/about.jpg`}
						alt=""
						width={1024}
						height={600}
						sizes="95vw"
						class="absolute inset-0 z-0 size-full object-cover brightness-75"
					/>
					<Container class="relative z-10 items-center py-24 text-center">
						<p class="text-4xl font-semibold text-white md:text-5xl">Join our team!</p>
						<Button
							class="size-fit"
							as={Link}
							href="https://recruiting2.ultipro.com/TRO1001TROO/JobBoard/2b13054b-60bb-410d-9136-e52c1c7d9720/?q=&o=postedDateDesc"
						>
							View all job openings
						</Button>
					</Container>
				</Section>
			</Page>
		</NoHydration>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
