import { createUniqueId, mergeProps, splitProps, useContext } from 'solid-js';
import { createStore } from 'solid-js/store';
import { twJoin, twMerge } from '@troon/tailwind-preset/merge';
import { useFocusable } from '../providers/focusable';
import { InputContext, LabelContext } from './field';
import { FormContext, disableAutocomplete } from './form';
import type { LabelCTX } from './field';
import type { JSX } from 'solid-js';

type SwitchProps = JSX.InputHTMLAttributes<HTMLInputElement> & {
	reverse?: boolean;
};

export function ToggleSwitch(inputProps: SwitchProps) {
	const id = createUniqueId();
	const [labelStore, setLabelStore] = createStore<LabelCTX>({
		for: id,
	});
	const { data } = useContext(FormContext);
	const [input] = useContext(InputContext);
	const focusable = useFocusable<JSX.InputHTMLAttributes<HTMLInputElement>>();
	const merged = mergeProps({ id }, input, focusable, inputProps);
	const [field, other, props] = splitProps(
		merged,
		['id', 'name', 'disabled', 'readonly', 'onClick', 'onChange', 'checked'],
		['reverse'],
	);

	return (
		<LabelContext.Provider value={[labelStore, setLabelStore]}>
			<div class={twMerge(twJoin('flex items-center gap-2', other.reverse && 'flex-row-reverse'), props.class)}>
				<div class={'relative h-6 w-10 shrink-0 self-center'}>
					<input
						{...field}
						type="checkbox"
						disabled={false}
						readonly={data.pending || field.readonly || field.disabled}
						aria-required={props.required}
						{...disableAutocomplete(props)}
						class="peer absolute inset-0 z-10 appearance-none outline-0"
					/>
					<div class="size-full rounded-full border border-neutral-500 bg-neutral-500 shadow-inner transition-colors duration-150 peer-checked:bg-brand" />
					<div class="absolute inset-x-2.5 inset-y-0.5 size-5 -translate-x-2 rounded-full border border-neutral-100 bg-white shadow-md transition-all duration-200 peer-checked:translate-x-2 peer-focus-visible:ring-1 peer-focus-visible:ring-brand-700 peer-active:scale-90" />
				</div>

				{props.children}
			</div>
		</LabelContext.Provider>
	);
}
