import { createAsync } from '@solidjs/router';
import { createEffect, createSignal, createUniqueId, For, Show, Suspense } from 'solid-js';
import {
	ActivityIndicator,
	CheckList,
	CheckListItem,
	Heading,
	Tag,
	TroonAccessPlusProduct,
	TroonAccessProduct,
} from '@troon/ui';
import { useTrackEvent } from '@troon/analytics';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Transition } from 'solid-transition-group';
import { getAccessProducts } from '../../providers/card';
import { TroonCardSubscriptionProductType } from '../../graphql';
import { usePersisted } from '../../providers/persistence-store';
import type { JSXElement } from 'solid-js';

type Props = {
	banners?: Record<TroonCardSubscriptionProductType, JSXElement>;
	floating?: boolean;
	forUser?: boolean;
	golfPass?: boolean;
	isGift?: boolean;
	redirect?: string;
	urlPrefix?: string;
	onUrlChange: (url: string, state: Record<string, string | undefined>) => void;
};

export function DialogAccessProducts(props: Props) {
	const [persisted] = usePersisted();
	const data = createAsync(() => getAccessProducts({ promoCode: persisted.promoCode?.value }), { deferStream: true });
	const trackEvent = useTrackEvent();
	const [open, setOpen] = createSignal<string>();
	const id = createUniqueId();

	createEffect(() => {
		const first = data()?.products.sort((a, b) => b.totalAmount.value - a.totalAmount.value)[0];
		if (first) {
			setOpen(first.id);
		}
	});

	createEffect(() => {
		props.onUrlChange(`${props.urlPrefix ?? ''}/access/${props.isGift ? 'gift' : 'checkout'}/${open()}`, {
			redirect: props.redirect,
		});
	});

	return (
		<Suspense fallback={<ActivityIndicator />}>
			<Show when={data()?.products?.sort((a, b) => b.totalAmount.value - a.totalAmount.value)}>
				{(products) => (
					<>
						<label class="sr-only" for={id}>
							Choose your Troon Access
						</label>
						<div class="flex flex-col gap-4 md:gap-6" role="radiogroup" id={id}>
							<For each={products()}>
								{(card) => (
									<div
										id={card.id}
										role="radio"
										aria-checked={open() === card.id}
										class="relative rounded border border-neutral p-4 transition-all hover:border-brand hover:bg-brand-100 aria-checked:border-brand aria-checked:bg-brand-100 md:p-6"
										onClick={() => {
											setOpen(card.id);
											trackEvent('didSelectAccessProduct', {
												productType: 'troonAccess',
												productId: card.id,
											});
										}}
									>
										<Show when={card.isPopular}>
											<div class="absolute inset-x-0 -top-2.5 flex justify-center">
												<Tag appearance="access">Most popular</Tag>
											</div>
										</Show>
										<div class="flex flex-wrap items-center justify-between gap-y-4">
											<Heading as="h2">
												<Show
													when={card.type === TroonCardSubscriptionProductType.TroonAccessPlus}
													fallback={<TroonAccessProduct class="h-8" />}
												>
													<TroonAccessPlusProduct class="h-8" current />
												</Show>
												<label for={card.id} class="sr-only">
													{card.subscriptionName}
												</label>
											</Heading>
											<p
												class={twJoin(
													'font-semibold',
													card.subtotalAmount.value !== card.totalAmount.value
														? 'text-3xl sm:text-2xl lg:text-3xl'
														: 'text-3xl',
												)}
											>
												<Show when={card.subtotalAmount.value !== card.totalAmount.value}>
													<span class="me-2 text-2xl font-normal text-neutral-700 line-through">
														{card.subtotalAmount.displayValue}
													</span>
												</Show>
												<span>{card.totalAmount.displayValue}</span>
												<Show when={card.autoRenew && !card.disclosure && !props.isGift}>/yr</Show>
											</p>
											<Show when={card.description}>
												<p class="basis-full text-neutral-800">{card.description}</p>
											</Show>
											<Show when={card.disclosure}>
												{(disclosure) => <p class="block basis-full pt-2 text-sm font-normal">*{disclosure()}</p>}
											</Show>
											<Show when={props.isGift}>
												<p class="block basis-full pt-2 text-sm font-normal">
													Gift includes 1 year subscription. No auto-renewal.
												</p>
											</Show>{' '}
										</div>

										<Transition
											enterClass="opacity-0 max-h-0 overflow-hidden"
											enterToClass="max-h-dvh opacity-1 motion-safe:transition-all motion-safe:duration-300 ease-in-out"
											exitClass="opacity-1 max-h-dvh overflow-hidden"
											exitToClass="max-h-0 opacity-0 motion-safe:transition-all motion-safe:duration-300 ease-in-out"
										>
											<Show when={open() === card.id}>
												<div>
													<div class="mt-6 flex grow flex-col gap-4 border-t border-neutral pt-6">
														<p>Includes:</p>
														<CheckList class="md:grid md:grid-cols-2">
															<Show when={props.banners && props.banners[card.type]}>
																{(banner) => <CheckListItem>{banner()}</CheckListItem>}
															</Show>
															<For each={card.valueProps}>{(value) => <CheckListItem>{value}</CheckListItem>}</For>
															<Show
																when={props.golfPass && card.type === TroonCardSubscriptionProductType.TroonAccessPlus}
															>
																<CheckListItem>
																	<b>1 year of GolfPass+ (a $400 value)</b>
																</CheckListItem>
															</Show>
														</CheckList>
													</div>
												</div>
											</Show>
										</Transition>
									</div>
								)}
							</For>
						</div>
					</>
				)}
			</Show>
		</Suspense>
	);
}
