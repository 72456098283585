import {
	ActivityIndicator,
	DialogContent,
	DialogAction,
	DialogActions,
	DialogTrigger,
	Dialog,
	Errors,
	Form,
	Input,
	Label,
	TextField,
	Heading,
} from '@troon/ui';
import { createAsync, useSubmission } from '@solidjs/router';
import { createSignal, For, Match, Show, Suspense, Switch } from 'solid-js';
import { useUser } from '../providers/user';
import { gql, mutationAction, useMutation } from '../graphql';
import { cachedQuery } from '../graphql/cached-get';
import { ReservationCard } from './reservation-card';
import type { Button } from '@troon/ui';
import type { ComponentProps } from 'solid-js';
import type { JSX } from 'solid-js/h/jsx-runtime';

type Props = ComponentProps<typeof Button> & {
	title?: JSX.Element;
};

export function SupportButton(props: Props) {
	const user = useUser();
	const [open, setOpen] = createSignal(false);

	const requestSupport = useMutation(mutation);
	const submission = useSubmission(requestSupport);

	return (
		<Dialog key="feedback" open={open()} onOpenChange={setOpen}>
			<DialogTrigger appearance="transparent" {...props}>
				{props.children ?? 'Contact support'}
			</DialogTrigger>
			<DialogContent header={props.title ?? props.children ?? 'Contact support'} headerLevel="h2">
				<Switch>
					<Match when={submission.result?.data?.createSupportRequest.ok}>
						<div class="flex flex-col gap-4">
							<p>
								Thank you for reaching out! Your support request has been received, and our team will get back to you
								within one business day.
							</p>
							<DialogActions>
								<DialogAction
									onClick={() => {
										submission.clear();
										setOpen(false);
									}}
								>
									Close
								</DialogAction>
							</DialogActions>
						</div>
					</Match>
					<Match when>
						<Form action={requestSupport} class="flex flex-col gap-4 sm:min-w-96">
							<p>
								Need assistance? Contact our support team for help with your account or Troon Rewards. If you have an
								issue with an upcoming reservation, please call the course directly.
							</p>

							<InlineReservations />

							<TextField name="name" required>
								<Label>Name</Label>
								<Input readonly={!!user()} value={user() ? `${user()?.me.firstName} ${user()?.me.lastName}` : ''} />
							</TextField>

							<TextField name="email" required>
								<Label>Email</Label>
								<Input readonly={!!user()} inputMode="email" value={user()?.me.email} />
							</TextField>

							<TextField name="message" required>
								<Label>Message</Label>
								<Input multiline rows={4} />
							</TextField>

							<Errors />

							<DialogActions>
								<DialogAction type="submit">Submit</DialogAction>
							</DialogActions>
						</Form>
					</Match>
				</Switch>
			</DialogContent>
		</Dialog>
	);
}

function InlineReservations() {
	const user = useUser();
	const reservations = createAsync(
		async () => {
			if (!user()) {
				return null;
			}
			return getReservations({});
		},
		{ deferStream: true },
	);

	return (
		<Suspense fallback={<ActivityIndicator>Checking for upcoming Reservations…</ActivityIndicator>}>
			<Show when={user() && reservations()?.upcoming.length}>
				<div class="flex flex-col gap-6">
					<Heading as="h3" size="h5">
						Manage upcoming reservations
					</Heading>
					<For each={reservations()!.upcoming}>{(res) => <ReservationCard showAction={false} reservation={res} />}</For>
				</div>
			</Show>
		</Suspense>
	);
}

const requestSupport = gql(`
mutation createSupportRequest($message: String!, $name: String, $email: String) {
	createSupportRequest(message: $message, name: $name, email: $email) {
		ok
	}
}
`);

const mutation = mutationAction(requestSupport, {
	track: {
		event: 'getSupport',
	},
});

const reservationsQuery = gql(`
query upcomingReservations {
	upcoming: allReservations(upcoming: true, past: false) {
	 ...ReservationCard
	}
}`);

const getReservations = cachedQuery(reservationsQuery);
