import { HttpStatusCode } from '@solidjs/start';
import { createAsync, useAction, useNavigate, useParams, useSearchParams, useSubmission } from '@solidjs/router';
import {
	Button,
	DialogContent,
	Dialog,
	Errors,
	FieldDescription,
	Form,
	Label,
	Link,
	Radio,
	RadioBar,
	RadioBarButton,
	RadioGroup,
	DialogTrigger,
	TextLink,
	HorizontalRule,
	Heading,
	Section,
	Page,
	Container,
	ActivityIndicator,
} from '@troon/ui';
import {
	For,
	Show,
	createSignal,
	useContext,
	Suspense,
	Switch,
	Match,
	createUniqueId,
	createMemo,
	createEffect,
} from 'solid-js';
import { Meta, Title } from '@solidjs/meta';
import { createStore, produce } from 'solid-js/store';
import { Icon } from '@troon/icons';
import { captureException } from '@sentry/solidstart';
import { useTrackEvent } from '@troon/analytics';
import { gql, mutationAction, TroonCardSubscriptionProductType, useMutation } from '../../../../../graphql';
import { FacilityCtx } from '../../../../../providers/facility';
import { Grid, GridFive, GridSeven } from '../../../../../components/layouts/grid';
import { PaymentInfo } from '../../../../../components/payment-info';
import { cardBrandToComponent, cardBrandToString } from '../../../../../modules/credit-cards';
import { addCard, AddCard, AddCardFields } from '../../../../../partials/add-card';
import { useUser } from '../../../../../providers/user';
import { AuthFlow } from '../../../../../partials/auth/auth';
import { cachedQuery } from '../../../../../graphql/cached-get';
import { useUtmParams } from '../../../../../providers/utm';
import ConfirmSubscription from '../../../../../components/confirm-subscription';
import { usePersisted } from '../../../../../providers/persistence-store';
import { getConfigValue } from '../../../../../modules/config';
import { FrequentlyAskedQuestions } from '../../../../../components/faqs';
import { RequestPhoneNumber } from '../../../../../components/request-phone-number';
import { AccessCardUpsellRate } from './components/access-upsell-rate';
import { AccessUpsellPayment } from './components/access-upsell-payment';
import { TeeTimeFacilityHeader } from './components/tee-time-facility-header';
import { AccessUpsellTakeover } from './components/access-upsell-takeover';
import type { SetStoreFunction } from 'solid-js/store';
import type { AccessorWithLatest } from '@solidjs/router';
import type { CourseTeeTimeRate, TeeTimePaymentInfoQuery } from '../../../../../graphql';

export default function ReserveTeeTimeWrapper() {
	const facility = useContext(FacilityCtx);
	const [searchParams] = useSearchParams<{
		subscriptionId?: string;
		productId?: string;
	}>();
	const [confirmSubscriptionOpen, setConfirmSubscriptionOpen] = createSignal(
		!!(searchParams.subscriptionId && searchParams.productId),
	);

	return (
		<>
			<Suspense>
				<Show when={facility()?.facility}>
					{(facility) => <Title>{`Book tee times | ${facility()?.name} | Troon`}</Title>}
				</Show>
			</Suspense>
			<Meta name="robots" content="noindex" />
			<Meta name="googlebot" content="noindex" />

			<ReserveTeeTime />

			<Show when={searchParams.subscriptionId && searchParams.productId}>
				<Dialog
					key="subscription-confirmation"
					defaultOpen
					open={confirmSubscriptionOpen()}
					onOpenChange={setConfirmSubscriptionOpen}
				>
					<DialogContent>
						<ConfirmSubscription
							subscriptionId={searchParams.subscriptionId!}
							productId={searchParams.productId!}
							onContinue={() => {
								setConfirmSubscriptionOpen(false);
							}}
							continueText="Continue booking"
						/>
					</DialogContent>
				</Dialog>
			</Show>
		</>
	);
}

function ReserveTeeTime() {
	const params = useParams<{ facilityId: string; teeTimeId: string }>();
	const facility = useContext(FacilityCtx);
	const [searchParams] = useSearchParams<{
		rateId?: string;
		players?: string;
		triggerId?: string;
		subscriptionId?: string;
		productId?: string;
	}>();
	const navigate = useNavigate();

	const [store, setStore] = createStore({
		rateId: searchParams.rateId || null,
		players: searchParams.players
			? isNaN(parseInt(searchParams.players, 10))
				? null
				: parseInt(searchParams.players, 10)
			: null,
	});

	const receipt = createAsync(
		() =>
			getPaymentInfo({
				teeTimeId: params.teeTimeId,
				rateId: store.rateId,
				players: store.players,
			}),
		{ deferStream: true },
	);

	return (
		<Show
			when={receipt.latest?.info}
			fallback={
				<Container>
					<Page>
						<HttpStatusCode code={404} />
						<Section>
							<p>The requested tee time is no longer available.</p>
							<div class="flex flex-row">
								<Button
									as={Link}
									href={`/course/${facility()?.facility.slug}/reserve-tee-time`}
									onClick={(e) => {
										e.preventDefault();
										navigate(-1);
									}}
									class="shrink grow-0"
								>
									See available tee times
								</Button>
							</div>
						</Section>
					</Page>
				</Container>
			}
		>
			<ReserveTeeTimeFound receipt={receipt} store={store} setStore={setStore} />
		</Show>
	);
}

type TTStore = { rateId: string | null; players: number | null };

function ReserveTeeTimeFound(props: {
	receipt: AccessorWithLatest<TeeTimePaymentInfoQuery | undefined>;
	store: TTStore;
	setStore: SetStoreFunction<TTStore>;
}) {
	const [searchParams] = useSearchParams<{
		rateId?: string;
		players?: string;
		triggerId?: string;
		subscriptionId?: string;
		productId?: string;
	}>();
	const facility = useContext(FacilityCtx);
	const trackEvent = useTrackEvent();
	const utm = useUtmParams();
	const [showAddPayment, setShowAddPayment] = createSignal(false);
	const [showAuth, setShowAuth] = createSignal(false);
	const [showAuthLink, setShowAuthLink] = createSignal(false);
	const authDialogId = createUniqueId();
	const [showCCError, setShowCCError] = createSignal<boolean | string>(false);

	const addCardAction = addCard(trackEvent, (cardId) => {
		setCardId(cardId);
	});
	const handleAddCard = useAction(addCardAction);
	const addCardSubmission = useSubmission(addCardAction);

	const [persisted] = usePersisted();

	const [form, setForm] = createSignal<HTMLFormElement>();
	const [addCardForm, setAddCardForm] = createSignal<HTMLFormElement>();

	const [cardId, setCardId] = createSignal<string>();
	const mutation = reserve(async () => {
		/**
		 * Support for Google Merchant Actions (eg, Book with Troon link on Google search results)
		 */
		if (persisted.rwg_token && persisted.rwg_token.expires > Date.now() && persisted.rwg_merchant) {
			const merchant_changed = facility()?.facility.slug === persisted.rwg_merchant.value ? 2 : 1;
			fetch(getConfigValue('GOOGLE_ACTIONS_ENDPOINT'), {
				method: 'POST',
				body: JSON.stringify({
					conversion_partner_id: getConfigValue('GOOGLE_ACTIONS_PARTNER_ID'),
					rwg_token: persisted.rwg_token.value,
					merchant_changed: `${merchant_changed}`,
				}),
			});
		}
	});
	const reserveAction = useMutation(mutation);
	const triggerFormAction = useAction(reserveAction);

	const user = useUser();

	const ccInfo = createAsync(
		async () => {
			if (!user()) {
				return undefined;
			}
			const res = await getCreditCards({});
			const firstCard = res?.creditCards[0];
			if (firstCard) {
				setCardId(firstCard.id);
			}
			return res;
		},
		{ deferStream: true },
	);
	const canShowAccessUpsell = createMemo(
		() =>
			!(
				!facility()?.facility.supportsTroonAccessCourseSiteUpsell &&
				utm().campaign === 'course-booking-link' &&
				utm().source === facility()?.facility.slug
			),
	);

	const [needsPhoneNumber, setNeedsPhoneNumber] = createSignal(!!(user() && !user()?.me.phoneNumber));
	const [userHasPhone, setUserHasPhone] = createSignal(!!user()?.me.phoneNumber);
	createEffect(() => {
		if (needsPhoneNumber()) {
			return;
		}
		setNeedsPhoneNumber(!!(user() && !user()?.me.phoneNumber));
	});

	// When the user logs in, update setHasPhone to ensure they can continue through booking
	createEffect(() => {
		if (user()?.me.phoneNumber) {
			setUserHasPhone(true);
		}
	});

	const upsell = createMemo(() => {
		return (
			<Suspense>
				<Show when={!user()?.me.troonAccessProductType && canShowAccessUpsell()}>
					<Show when={props.receipt.latest?.info}>
						{(info) => (
							<Show when={info().troonSubscriptionRate}>
								{(rate) => (
									<>
										<AccessUpsellPayment
											rate={rate() as CourseTeeTimeRate}
											discounts={{
												[TroonCardSubscriptionProductType.TroonAccess]:
													info().troonSubscriptionRateDiscounts.troonAccess?.displayValue,
												[TroonCardSubscriptionProductType.TroonAccessPlus]:
													info().troonSubscriptionRateDiscounts.troonAccessPlus?.displayValue,
											}}
										/>
										<Show when={user()?.me.card}>
											<AccessUpsellTakeover />
										</Show>
									</>
								)}
							</Show>
						)}
					</Show>
				</Show>
			</Suspense>
		);
	});

	return (
		<Show when={props.receipt.latest?.info}>
			{(data) => (
				<div class="-mb-6 bg-neutral-100 md:-mb-12">
					<Container class="mt-8 lg:mt-12">
						<Grid>
							<GridSeven>
								<Section class="md:gap-12">
									<Heading as="h1" size="h3">
										Confirm Tee Time
									</Heading>

									<div class="rounded border border-neutral bg-white p-4 md:p-8 lg:hidden">
										<TeeTimeFacilityHeader
											cartIncluded={data().rate.cartIncluded}
											courseName={data().course.name}
											dayTime={data().rate.dayTime}
											facilityName={facility()!.facility.name}
											facilitySlug={facility()!.facility.slug}
											holes={data().holes}
											logo={facility()!.facility.metadata?.logo?.url}
										/>
									</div>

									<Suspense>
										<Show
											when={
												[
													...data().availableRates.sort((a, b) => a.price.value - b.price.value),
													...(data().troonSubscriptionRate ? [data().troonSubscriptionRate] : []),
												] as Array<CourseTeeTimeRate>
											}
										>
											{(rates) => (
												<Section layout="tight">
													<RadioGroup name="__rateId">
														<Label class="text-xl font-semibold md:text-2xl">Choose Your Rate</Label>
														<div class="flex flex-col gap-4">
															<For each={rates()}>
																{(rate) => (
																	<Switch>
																		<Match
																			when={
																				canShowAccessUpsell() && rate.isTroonCardRate && rate.isAvailableToUser !== true
																			}
																		>
																			<div class="flex flex-col gap-2">
																				<AccessCardUpsellRate
																					rate={rate}
																					isSelected={props.store.rateId === rate.id || data().rate.id === rate.id}
																					discounts={{
																						[TroonCardSubscriptionProductType.TroonAccess]:
																							data().troonSubscriptionRateDiscounts.troonAccess?.displayValue,
																						[TroonCardSubscriptionProductType.TroonAccessPlus]:
																							data().troonSubscriptionRateDiscounts.troonAccessPlus?.displayValue,
																					}}
																					original={rates().find((r) => r.name === 'Public')?.price?.displayValue}
																				/>
																				<Show when={!user() && data().troonSubscriptionRate}>
																					{(product) => (
																						<p>
																							Already have {product().name}?{' '}
																							<Dialog
																								key="reserve-login-signup-link"
																								open={showAuthLink()}
																								onOpenChange={setShowAuthLink}
																								id={authDialogId}
																							>
																								<DialogTrigger as={TextLink} href="/auth">
																									Log in
																								</DialogTrigger>
																								<DialogContent width="fit" noPadding closeButton="text-white">
																									<AuthFlow
																										onComplete={() => setShowAuthLink(false)}
																										headings={{ '/auth': 'Log in or sign up to continue booking' }}
																									/>
																								</DialogContent>
																							</Dialog>
																						</p>
																					)}
																				</Show>
																			</div>
																		</Match>
																		{/* IMPORTANT: this prop is NOT set on public rates. check strictly equal to false */}
																		<Match when={rate.isAvailableToUser !== false}>
																			<div
																				// eslint-disable-next-line tailwindcss/no-arbitrary-value
																				class="cursor-pointer rounded border border-neutral bg-white p-4 has-[:checked]:border-brand has-[:checked]:bg-brand-100 md:p-6"
																				onClick={() => {
																					props.setStore(
																						produce((s) => {
																							s.players = s.players
																								? Math.max(Math.min(rate.maxPlayers, s.players), rate.minPlayers)
																								: null;
																							s.rateId = rate.id;
																						}),
																					);
																				}}
																			>
																				<Radio
																					value={rate.id}
																					checked={props.store.rateId === rate.id || data().rate.id === rate.id}
																				>
																					<Label class="flex flex-col gap-1 ps-2">
																						<span class="font-semibold">{rate.name}</span>
																						<span class="text-sm text-neutral-800">
																							{rate.price.displayValue} per player
																						</span>
																					</Label>
																				</Radio>
																			</div>
																		</Match>
																	</Switch>
																)}
															</For>
														</div>
													</RadioGroup>
												</Section>
											)}
										</Show>
									</Suspense>

									<HorizontalRule />

									<RadioGroup name="__players" onSelect={(index) => props.setStore('players', parseInt(index, 10) + 1)}>
										<Label class="text-xl font-semibold md:text-2xl">Number of Players</Label>
										<RadioBar>
											<For each={new Array(4).fill(0)}>
												{(info, index) => (
													<RadioBarButton
														value={index()}
														checked={
															!props.store.players
																? data().rate.minPlayers === index() + 1
																: props.store.players === index() + 1
														}
														disabled={data().rate.minPlayers > index() + 1 || data().rate.maxPlayers <= index()}
													>
														<Label>{index() + 1}</Label>
													</RadioBarButton>
												)}
											</For>
										</RadioBar>

										<FieldDescription>
											<div class="flex flex-col gap-4">
												<Switch>
													<Match when={data().rate.minPlayers > 1}>
														<p>
															The {data().rate.name} rate requires a minimum of {data().rate.minPlayers} players to book
															this tee time.
														</p>
													</Match>
													<Match when>
														<p>
															There {data().rate.maxPlayers > 1 ? 'are' : 'is'} {data().rate.maxPlayers} spot
															{data().rate.maxPlayers > 1 ? 's' : ''} available for this tee time.
														</p>
													</Match>
												</Switch>
												<Suspense>
													<Show when={data() && (props.store.players ?? 1) > 0}>
														<div>
															<Heading as="h2" class="text-sm uppercase text-neutral-700 md:text-sm">
																FAQs
															</Heading>
															<FrequentlyAskedQuestions
																layout="tight"
																fullWidth
																id={
																	data().rate.isTroonCardRate
																		? (props.store.players ?? 1) > 1
																			? 'troon-access-booking'
																			: 'individual-access-faqs'
																		: (props.store.players ?? 1) > 1
																			? 'reservation-details-multiple-player-faqs'
																			: 'individual-booking-faqs'
																}
															/>
														</div>
													</Show>
												</Suspense>
											</div>
										</FieldDescription>
									</RadioGroup>

									<Suspense>
										<Show
											when={
												(data().course.requiresCCAtBooking || data().paymentInfo.receipt.dueNow.value > 0) && user()
											}
										>
											<>
												<HorizontalRule />
												<Section layout="tight">
													<Suspense>
														<Show
															when={(ccInfo()?.creditCards ?? []).length > 0}
															fallback={
																<Form
																	// @ts-expect-error jumping through hoops on this one
																	action={addCardAction}
																	ref={setAddCardForm}
																>
																	<Heading as="h2" size="h4">
																		Payment Method
																	</Heading>
																	<p class="text-sm text-neutral-700">
																		This course requires a credit card to book a tee time. You will only be charged if
																		you no show or cancel beyond the cancellation policy.
																	</p>
																	<AddCardFields />
																</Form>
															}
														>
															<RadioGroup name="creditCardId" onSelect={(value) => setCardId(value)}>
																<Label class="text-xl font-semibold md:text-2xl">Payment Method</Label>
																<FieldDescription>
																	<p class="text-sm text-neutral-700">
																		This course requires a credit card to book a tee time. You will only be charged if
																		you no show or cancel beyond the cancellation policy.
																	</p>
																</FieldDescription>{' '}
																<div class="flex flex-col gap-2 rounded py-2">
																	<For each={ccInfo()?.creditCards ?? []}>
																		{(card) => {
																			const CardIcon = cardBrandToComponent[card.brand];
																			return (
																				<div
																					// eslint-disable-next-line tailwindcss/no-arbitrary-value
																					class="cursor-pointer rounded border border-neutral bg-white p-4 has-[:checked]:border-brand has-[:checked]:bg-brand-100 md:p-6"
																					onClick={() => setCardId(card.id)}
																				>
																					<Radio value={card.id} checked={cardId() === card.id}>
																						<Label class="flex flex-row items-center gap-2">
																							<div class="w-10">
																								<CardIcon />
																							</div>
																							<div class="grow">
																								{cardBrandToString[card.brand]} ending in {card.lastFour}
																							</div>
																						</Label>
																					</Radio>
																				</div>
																			);
																		}}
																	</For>
																</div>
															</RadioGroup>
														</Show>
													</Suspense>
													<Suspense>
														<Show when={(ccInfo()?.creditCards ?? []).length}>
															<div class="self-start">
																<Dialog
																	key="reserve-add-payment-method"
																	open={showAddPayment()}
																	onOpenChange={setShowAddPayment}
																>
																	<DialogTrigger appearance="transparent">+ Add payment method</DialogTrigger>
																	<DialogContent header="Add payment method" headerLevel="h2">
																		<AddCard
																			onSuccess={(cardId) => {
																				setCardId(cardId);
																				setShowAddPayment(false);
																			}}
																		/>
																	</DialogContent>
																</Dialog>
															</div>
														</Show>
													</Suspense>
												</Section>
											</>
										</Show>
									</Suspense>
								</Section>
							</GridSeven>

							<GridFive>
								<div class="sticky top-24 rounded border border-neutral bg-white">
									<div class="hidden border-b border-neutral p-6 lg:block">
										<TeeTimeFacilityHeader
											cartIncluded={data().rate.cartIncluded}
											courseName={data().course.name}
											dayTime={data().rate.dayTime}
											facilityName={facility()!.facility.name}
											facilitySlug={facility()!.facility.slug}
											holes={data().holes}
											logo={facility()!.facility.metadata?.logo?.url}
										/>
									</div>

									<div class="flex flex-col gap-4 p-4 md:p-6">
										<Heading as="h2" size="h5">
											Payment Info
										</Heading>

										<PaymentInfo receipt={props.receipt()?.info.paymentInfo.receipt} upsell={upsell()} />

										<Suspense>
											<Show
												when={
													props.receipt.latest?.info.course.supportsTroonRewards ||
													props.receipt.latest?.info.paymentInfo.rewardPointsEarned
												}
											>
												<p class="flex items-center gap-4 rounded bg-brand-100 p-4 text-sm text-brand-600">
													<Icon name="logo-square" class="size-8 shrink-0 text-brand" />
													<span>
														Earn{' '}
														<Suspense>
															<Show when={props.receipt()?.info.paymentInfo.rewardPointsEarned}>
																{(pointsEarned) => (
																	<>
																		up to <b class="font-bold">{pointsEarned()}</b>{' '}
																	</>
																)}
															</Show>
														</Suspense>
														<b class="font-bold">Troon Rewards</b> points when you pay for your round.
													</span>
												</p>
											</Show>
										</Suspense>
									</div>
								</div>
							</GridFive>

							<GridSeven>
								<Section class="md:gap-12">
									<Show when={needsPhoneNumber()}>
										<Section>
											<div class="hidden sm:block">
												<HorizontalRule />
											</div>

											<Heading as="h2" size="h4">
												Contact Information
											</Heading>
											<p>
												A phone number is required for the course to contact you in case of any changes to your tee
												time.
											</p>

											<RequestPhoneNumber onSuccess={() => setUserHasPhone(true)} />
										</Section>
									</Show>
									<div class="hidden sm:block">
										<HorizontalRule />
									</div>

									<Section class="pb-24">
										<Form document={reserveMutation} action={reserveAction} method="post" ref={setForm}>
											<div class="flex flex-col gap-4">
												<Heading as="h2" size="h4" class="flex items-center gap-2">
													<Icon name="square-warning" class="size-8 text-brand" /> Cancellation policy
												</Heading>
												<p class="text-neutral-800">{data().cancellationInfo.displayMessage}</p>
											</div>

											<Show when={data()?.courseNotes}>
												{(note) => (
													<div class="flex flex-col gap-2 rounded border border-neutral bg-white p-4">
														<Heading as="h3" size="h5">
															Notes from the course:
														</Heading>
														<p class="text-sm">{note()}</p>
													</div>
												)}
											</Show>

											<input type="hidden" name="__facilitySlug" value={facility()?.facility.slug} />
											<input
												type="hidden"
												name="__value"
												value={props.receipt.latest?.info.paymentInfo.receipt.total.value}
											/>
											<Show when={cardId()}>
												{(cardId) => <input type="hidden" name="creditCardId" value={cardId()} />}
											</Show>
											<For each={Object.entries(utm())}>
												{([key, val]) => (
													<Show when={val}>
														<input type="hidden" name={`utm.${key}`} value={val} />
													</Show>
												)}
											</For>
											<Suspense>
												<input type="hidden" name="reserveId" value={data().reserveId} />
												<Show when={searchParams.triggerId}>
													<input type="hidden" name="triggerId" value={searchParams.triggerId} />
												</Show>
											</Suspense>

											<Show when={showCCError()} fallback={<Errors />}>
												{(error) => (
													<p class="text-red-500" aria-live="assertive">
														{error()}
													</p>
												)}
											</Show>

											<Switch>
												<Match
													/**
													 * Login is required before attempting to book
													 */
													when={!user()}
												>
													<Dialog
														key="reserve-login-signup"
														open={showAuth()}
														onOpenChange={setShowAuth}
														id={authDialogId}
													>
														<DialogTrigger>Log in to book</DialogTrigger>
														<DialogContent width="fit" noPadding>
															<AuthFlow
																onComplete={() => setShowAuth(false)}
																headings={{ '/auth': 'Log in or sign up to continue booking' }}
															/>
														</DialogContent>
													</Dialog>
												</Match>

												<Match
													/**
													 * Credit card form needs to be submitted first
													 */
													when={
														(data().course.requiresCCAtBooking || data().paymentInfo.receipt.dueNow.value > 0) &&
														!cardId()
													}
												>
													<Button
														disabled={!userHasPhone()}
														type="button"
														// eslint-disable-next-line solid/reactivity
														onClick={() => {
															const data = new FormData(addCardForm());
															const reserveData = new FormData(form());
															handleAddCard(data).then((res) => {
																if (
																	('error' in res && res.error) ||
																	!('data' in res) ||
																	!res.data ||
																	!('addCreditCard' in res.data)
																) {
																	setShowCCError(
																		(res.error?.graphQLErrors.find((e) => e.extensions.displayMessage)?.extensions
																			.displayMessage as string) ??
																			'This course requires a credit card to book a tee time. Please enter and verify your credit card details and try again.',
																	);
																	return;
																}
																setShowCCError(false);
																const creditCardId = res.data.addCreditCard.id;
																setCardId(creditCardId);
																reserveData.set('creditCardId', creditCardId);
																triggerFormAction(reserveData);
															});
														}}
													>
														<Show when={addCardSubmission.pending}>
															<ActivityIndicator aria-label="Loading" class="me-2 size-4" />
														</Show>
														Confirm & Book Tee Time <Icon name="arrow-right-md" />
													</Button>
												</Match>

												<Match
													/**
													 * All good to submit
													 */
													when={
														!(
															props.receipt.latest?.info.course.requiresCCAtBooking ||
															props.receipt.latest?.info.paymentInfo.receipt.dueNow.value > 0
														) ||
														((props.receipt.latest?.info.course.requiresCCAtBooking ||
															props.receipt.latest?.info.paymentInfo.receipt.dueNow.value > 0) &&
															cardId())
													}
												>
													<Button type="submit" disabled={!userHasPhone()}>
														Confirm & Book Tee Time <Icon name="arrow-right-md" />
													</Button>
												</Match>
											</Switch>
											<Show when={needsPhoneNumber()}>
												<p aria-live="polite" class="text-center text-sm text-red-500">
													A phone number is required for the course to contact you in case of any changes to your tee
													time.
												</p>
											</Show>
										</Form>
									</Section>
								</Section>
							</GridSeven>
						</Grid>
					</Container>
				</div>
			)}
		</Show>
	);
}

const reserveMutation = gql(`
mutation reserveTeeTime(
	$reserveId: String!
	$creditCardId: String
	$triggerId: String
	$utm: UTMAttributesInput
) {
	reserveTeeTime(
		reserveId: $reserveId
		creditCardId: $creditCardId
		teeTimeAlertTriggerId: $triggerId
		utmAttributes: $utm
	) {
		id
		courseId
		playerCount
	}
}
`);

const reserve = (onSuccess: () => Promise<void>) =>
	mutationAction(reserveMutation, {
		timeout: 45_000,
		revalidates: ['home', 'allReservations'],
		redirect: (data) => `/reservation/${data?.reserveTeeTime.id}/confirmed`,
		onSuccess,
		track: {
			event: 'reserveTeeTime',
			transform: (data, res) => ({
				facilitySlug: data.get('__facilitySlug') as string,
				value: parseFloat(data.get('__value') as string),
				players: res?.reserveTeeTime.playerCount,
				courseId: res?.reserveTeeTime.courseId,
			}),
		},
	});

const paymentInfoQuery = gql(`
query teeTimePaymentInfo(
	$teeTimeId: String!
	$rateId: String
	$players: Int
) {
	info: courseTeeTimeReservationInfo(
		teeTimeId: $teeTimeId
		rateId: $rateId
		players: $players
	) {
		reserveId
		teeTime { ...TeeTime }
		courseNotes
		availableRates {
			id
			name
			dayTime { day { year, month, day }, time { hour, minute } }
			holesOption
			cartIncluded
			practiceBallsIncluded
			minPlayers
			maxPlayers
			price { displayValue, value }
		}
		rate {
			id
			name
			dayTime { day { year, month, day }, time { hour, minute } }
			holesOption
			cartIncluded
			practiceBallsIncluded
			minPlayers
			maxPlayers
			isTroonCardRate
			price { displayValue, value }
		}
		troonSubscriptionRate {
			id
			name
			dayTime { day { year, month, day }, time { hour, minute } }
			isAvailableToUser
			isPrePaid
			isTroonCardRate
			isLocalCardRate
			holesOption
			cartIncluded
			practiceBallsIncluded
			minPlayers
			maxPlayers
			price { displayValue, value }
		}
		troonSubscriptionRateDiscounts {
			troonAccess { displayValue, value }
			troonAccessPlus { displayValue, value }
		}
		paymentInfo {
			rewardPointsEarned
			receipt {
				...PaymentInfo
				total { displayValue, value }
				dueNow { displayValue, value }
				dueLater { displayValue, value }
				items {
					label
					amount { displayValue, value }
					itemType
				}
			}
		}
		cancellationInfo {
			displayMessage
		}
		course {
			id
			name
			requiresCCAtBooking
			supportsTroonRewards
		}
		players
		holes
	}
}
`);

const getPaymentInfo = cachedQuery(paymentInfoQuery, {
	onError(error) {
		if (!error.message.includes('for tee time in the past')) {
			captureException(error);
		}
		return null;
	},
});

const cardQuery = gql(`
query paymentMethods {
	creditCards {
		id
		lastFour
		brand
	}
}`);

const getCreditCards = cachedQuery(cardQuery);
