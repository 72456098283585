import { Title } from '@solidjs/meta';
import dayjs from '@troon/dayjs';
import { createAsync } from '@solidjs/router';
import { produce } from 'solid-js/store';
import { createResource, For, Match, Show, Suspense, Switch } from 'solid-js';
import { useTrackEvent } from '@troon/analytics';
import { ActivityIndicator, PillButton, Container, Tabs, TabList, Tab, TabPanel, HorizontalRule } from '@troon/ui';
import { useWindowScrollPosition } from '@solid-primitives/scroll';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Icon } from '@troon/icons';
import { captureException } from '@sentry/solidstart';
import { z } from 'zod';
import { TeeTimeSearchFields, teeTimeSearchSchema } from '../../../components/tee-time-search';
import { gql } from '../../../graphql';
import { cachedQuery } from '../../../graphql/cached-get';
import { dayToDayJs } from '../../../modules/date-formatting';
import { useUser } from '../../../providers/user';
import { FacilityCtx } from '../../../providers/facility';
import { TeeTimeSearchExplainer } from '../../../components/tee-time-access-explainer';
import { TeeTimeAccessUpsell } from '../../course/[facilityId]/(sub)/reserve-tee-time/components/tee-time-access-upsell';
import { TeeTimesFacility } from '../components/tee-times-facility';
import { EmptyState } from '../components/empty-state';
import { NoFacilities, NoTeeTimes } from '../components/no-results-state';
import { TeeTime } from '../components/tee-time';
import { FacilityFilterPill } from '../components/facility-filter';
import { teeTimeFilterSchema } from '../../../components/tee-time-search/filters';
import { createSearchStore } from '../../../modules/search-store';
import type { CalendarDay, FacilityQuery, TeeTimeFacilitySearchQuery, TeeTimeFragment } from '../../../graphql';

const schema = teeTimeFilterSchema.merge(teeTimeSearchSchema).extend({
	facilities: z.array(z.string()).nullish(),
	view: z.union([z.literal('course'), z.literal('time')]).default('course'),
});

export function TeeTimeSearchPage() {
	const user = useUser();
	const scroll = useWindowScrollPosition();

	const [filters, setFilters] = createSearchStore(schema);

	const facilities = createAsync(
		async () => {
			const query = {
				location:
					filters.lat && filters.lon ? { latitude: filters.lat, longitude: filters.lon, radiusMiles: 50 } : undefined,
				slugs: filters.facilities,
				regionIds: filters.regionId,
				supportsAccess: filters.access,
				supportsRewards: filters.rewards,
			};
			// Important: this needs to be before the request for reactivity
			const filterFacilities = filters.filterFacilities ?? [];

			if (
				!query.regionIds?.length &&
				!query.slugs?.length &&
				(!query.location?.latitude || !query.location?.longitude)
			) {
				return { allFacilities: [], facilities: [] };
			}
			const res = await getFacilities(query as Parameters<typeof getFacilities>[0]);
			const allFacilities = res?.facilities?.facilities ?? [];

			const facilities =
				filterFacilities.length === 0 || filterFacilities.length === allFacilities?.length
					? allFacilities
					: (allFacilities?.filter((f) => filterFacilities.includes(f.slug)) ?? []);

			return { all: allFacilities, facilities };
		},
		{ deferStream: true },
	);

	const [maxDate] = createResource(() => {
		const maxDates = facilities.latest?.facilities
			?.flatMap((f) => f.courses.map((c) => dayToDayJs(c.bookingWindowDay as CalendarDay, f.timezone).valueOf()))
			.filter(Boolean);

		return maxDates ? new Date(Math.max(...maxDates)) : undefined;
	});

	return (
		<div>
			<Title>Search for tee times | Troon Rewards</Title>
			<h1 class="sr-only">Search for tee times</h1>

			<div
				class={twJoin(
					'z-30 flex flex-col gap-6 bg-white py-6 transition-all md:sticky md:top-0 md:gap-6',
					scroll.y > 0 && 'md:shadow-md',
				)}
			>
				<Container>
					<div class="flex flex-row flex-wrap items-stretch xl:flex-nowrap">
						<TeeTimeSearchFields
							noButton
							hideShowAllLink
							filters={filters}
							setFilters={setFilters}
							maxDate={maxDate.latest}
						/>
					</div>
				</Container>
			</div>

			<Container>
				<div>
					<div class="mb-4 flex flex-row items-center justify-start gap-4 overflow-x-auto">
						<PillButton
							aria-pressed={!!filters.rewards}
							onClick={() => {
								setFilters(
									produce((s) => {
										s.rewards = s.rewards ? undefined : true;
									}),
								);
							}}
						>
							Troon Rewards
						</PillButton>

						<Show when={user()?.activeTroonCardSubscription}>
							<PillButton
								aria-pressed={!!filters.access}
								onClick={() => {
									setFilters(
										produce((s) => {
											s.access = s.access ? undefined : true;
										}),
									);
								}}
							>
								Troon Access
							</PillButton>
						</Show>

						<Suspense>
							<Show when={(facilities.latest?.all?.length ?? 0) > 1}>
								<FacilityFilterPill
									facilities={facilities()?.all}
									selected={facilities()?.facilities?.map((f) => f.slug)}
									onSelect={(items) => {
										setFilters(
											produce((s) => {
												if (items.length === facilities.latest?.all?.length) {
													s.filterFacilities = null;
													return;
												}
												s.filterFacilities = items.length === 0 ? '_' : items;
											}),
										);
									}}
								/>
							</Show>
						</Suspense>
					</div>
					<Show when={user()?.activeTroonCardSubscription || user()?.me.card}>
						<div class="rounded bg-brand-100 px-4 py-2 text-brand-600">
							<TeeTimeSearchExplainer />
						</div>
					</Show>
				</div>
				<Tabs
					defaultValue={filters.view}
					onChange={(value) => setFilters('view', value as z.infer<typeof schema>['view'])}
					class="flex flex-col gap-10"
				>
					<TabList aria-label="Tee time views">
						<Tab value="course">
							<Icon name="flag" /> Course
						</Tab>
						<Tab value="time">
							<Icon name="clock" /> Tee time
						</Tab>
					</TabList>
					<TabPanel value={filters.view}>
						<Show
							when={(filters.lat && filters.lon) || filters.regionId || filters.facilities}
							fallback={
								<EmptyState
									onSelectLocation={(loc) => {
										setFilters({
											query: loc.displayValue,
											lat: loc.coordinates.latitude,
											lon: loc.coordinates.longitude,
										});
									}}
								/>
							}
						>
							<Suspense fallback={<ActivityIndicator />}>
								<Internal
									facilities={facilities()?.facilities as FacilitiesProp}
									players={filters.players}
									startAt={filters.startAt}
									endAt={filters.endAt}
									date={dayjs(filters.date).format('YYYY-MM-DD')}
									view={filters.view}
								/>
							</Suspense>
						</Show>
					</TabPanel>
				</Tabs>
			</Container>
		</div>
	);
}

type FacilitiesProp =
	| undefined
	| (TeeTimeFacilitySearchQuery['facilities']['facilities'] & {
			facility: TeeTimeFacilitySearchQuery['facilities']['facilities'][number];
	  });

type InternalProps = {
	facilities?: FacilitiesProp;
	players: number | undefined;
	date: string;
	startAt: number;
	endAt: number;
	view: z.infer<typeof schema>['view'];
};

function Internal(props: InternalProps) {
	const trackEvent = useTrackEvent();
	const user = useUser();

	const [teeTimes] = createResource(
		() => {
			const courseIds = props.facilities?.flatMap((f) => f.courses.map((c) => c.id));
			if (!courseIds?.length) {
				return { view: props.view };
			}

			const day = dayjs(props.date);

			return {
				facilities: props.facilities,
				courseIds,
				players: props.players,
				startAt: props.startAt,
				endAt: props.endAt,
				year: day.year(),
				month: day.month() + 1,
				day: day.date(),
				view: props.view,
			};
		},

		async (data) => {
			if (!data.facilities) {
				return data.view === 'course' ? {} : [];
			}

			const { facilities, ...filters } = data;
			const { view, ...query } = filters;

			const teeTimesRes = await getTeeTimes(query);
			if (teeTimesRes) {
				trackEvent('getTeeTimes', { ...filters, resultsCount: teeTimesRes?.teeTimes.length });
			}

			const res = teeTimesRes?.teeTimes ?? [];

			if (filters.view === 'course') {
				return facilities?.length
					? facilities.reduce(
							(memo, facility) => {
								facility.courses.forEach((course) => {
									memo[course.id] = ((res as Array<TeeTimeFragment>) ?? []).filter((tt) => tt.courseId === course.id);
								});
								return memo;
							},
							{} as Record<string, Array<TeeTimeFragment>>,
						)
					: {};
			}

			return res;
		},
		{ deferStream: true },
	);

	return (
		<Switch>
			<Match when={props.view === 'course'}>
				<Suspense>
					<Show
						when={
							props.facilities &&
							(teeTimes.state === 'refreshing' || teeTimes.state === 'ready' || teeTimes.state === 'errored')
						}
					>
						<div class="flex flex-col gap-6">
							<For each={props.facilities} fallback={<NoFacilities />}>
								{(facility, i) => (
									<>
										<FacilityCtx.Provider value={() => ({ facility }) as unknown as FacilityQuery}>
											<TeeTimesFacility
												facility={facility}
												// @ts-expect-error TODO
												teeTimes={teeTimes}
												players={props.players}
												startAt={props.startAt}
												endAt={props.endAt}
												date={props.date}
											/>
										</FacilityCtx.Provider>
										<Show when={!user()?.activeTroonCardSubscription && i() === 0}>
											<div class="overflow-hidden rounded">
												<TeeTimeAccessUpsell redirect="/tee-times" location="multi-course tee time search" />
											</div>
											<HorizontalRule />
										</Show>
									</>
								)}
							</For>
						</div>
					</Show>
				</Suspense>
			</Match>
			<Match when={props.view === 'time'}>
				<Suspense fallback={<ActivityIndicator />}>
					<ol class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-6">
						<For
							each={teeTimes() as Array<TeeTimeFragment>}
							fallback={
								<div class="col-span-1 sm:col-span-2 lg:col-span-3">
									<NoTeeTimes />
								</div>
							}
						>
							{(teeTime) => (
								<Show
									when={props.facilities?.find((facility) =>
										facility.courses.some(({ id }) => id === teeTime.courseId),
									)}
								>
									{(facility) => (
										<li>
											<TeeTime
												// @ts-expect-error TODO
												teeTime={teeTime}
												facility={facility()}
												selectedPlayers={props.players}
											/>
										</li>
									)}
								</Show>
							)}
						</For>
					</ol>
				</Suspense>
			</Match>
		</Switch>
	);
}

const facilityQuery = gql(`
query teeTimeFacilitySearch(
	$slugs: [String!],
	$regionIds: [String!],
	$location: FacilityLocationInput,
	$supportsTroonAccess: Boolean,
	$supportsTroonRewards: Boolean,
) {
	facilities: facilitiesV3(
		idOrSlugs: $slugs,
		regionIds: $regionIds,
		location: $location,
		supportsTroonAccess: $supportsTroonAccess,
		supportsTroonRewards: $supportsTroonRewards,
		types: [DAILY_FEE_RESORT, SEMI_PRIVATE]
	) {
		facilities {
			...MultiTeeTimeFacility
			...MultiTeeTimesFacilityGroup
			...FacilityFilter
			slug
			timezone
			courses { id, bookingWindowDay { year, month, day } }
		}
	}
}
`);

const getFacilities = cachedQuery(facilityQuery);

const teeTimesQuery = gql(`query teeTimesMulti(
		$courseIds: [String!]!,
		$year: Int!,
		$month: Int!,
		$day: Int!,
		$startAt: Int!,
		$endAt: Int!,
		$includeCart: Boolean,
		$players: Int
) {
	teeTimes: courseTeeTimes(
		courseIds: $courseIds,
		day: { year: $year, month: $month, day: $day },
		filters: {
			startAt: { hour: $startAt, minute: 0 },
			endAt: { hour: $endAt, minute: 0 },
			players: $players,
			includeCart: $includeCart,
	}) {
		...MultiTeeTime
		...FacilityTeeTime
	}
}`);

const getTeeTimes = cachedQuery(teeTimesQuery, {
	onError: (e) => {
		captureException(e);
		return null;
	},
});
