import { Icon } from '@troon/icons';
import {
	PillButton,
	HorizontalRule,
	ToggleSwitch,
	Label,
	FieldDescription,
	Dialog,
	DialogTrigger,
	DialogContent,
	Button,
	CheckboxGroup,
	Checkbox,
} from '@troon/ui';
import { createSignal, For, Match, Show, Switch } from 'solid-js';
import { produce } from 'solid-js/store';
import { z } from 'zod';
import { coerceBoolean } from '../../modules/search-store';
import { gql } from '../../graphql';
import { createFragment } from '../../graphql/create-fragment';
import { useUser } from '../../providers/user';
import { FavoritesDialog } from '../favorites-dialog';
import { TeeTimeSearchExplainer } from '../tee-time-access-explainer';
import type { FragmentType, TeeTimeFacilityFilterFragment } from '../../graphql';
import type { SearchStore, SetSearchStore } from '../../modules/search-store';
import type { ComponentProps } from 'solid-js';

export const teeTimeFilterSchema = z.object({
	access: coerceBoolean().nullish(),
	rewards: coerceBoolean().nullish(),
	accessDeals: coerceBoolean().nullish(),
	facilities: z.array(z.string()).nullish(),
	favorites: coerceBoolean().nullish(),
	filterFacilities: z.union([z.array(z.string()).nullish(), z.string()]),
});

type BaseProps = {
	hasFavorites?: boolean;
	filters: SearchStore<typeof teeTimeFilterSchema>;
	multi?: boolean;
	setFilters: SetSearchStore<typeof teeTimeFilterSchema>;
};

type CourseProps = BaseProps & {
	courses: Array<FragmentType<typeof TeeTimeCourseFilterFragment>>;
	facilities?: never;
};

export type FacilityProps = BaseProps & {
	courses?: never;
	facilities: Array<FragmentType<typeof TeeTimeFacilityFilterFragment>>;
};

export function TeeTimeFilters(props: CourseProps | FacilityProps) {
	const user = useUser();
	const facilities = createFragment(TeeTimeFacilityFilterFragment, props as FacilityProps, 'facilities');
	const courses = createFragment(TeeTimeCourseFilterFragment, props as CourseProps, 'courses');
	const [groupRef, setGroupRef] = createSignal<HTMLElement>();
	const compare = new Intl.Collator('en-US').compare;

	return (
		<div class="flex flex-col gap-6">
			<Show when={props.multi}>
				<TeeTimeSearchExplainer />
				<div class="flex flex-wrap gap-3">
					<PillButton
						aria-pressed={!!props.filters.access}
						onClick={() => props.setFilters(produce((s) => (s.access = !s.access || null)))}
					>
						Troon Access
					</PillButton>

					<PillButton
						aria-pressed={!!props.filters.rewards}
						onClick={() => props.setFilters(produce((s) => (s.rewards = !s.rewards || null)))}
					>
						Troon Rewards
					</PillButton>

					<Switch>
						<Match when={user() && props.hasFavorites === false}>
							<FavoritesDialog
								facilities={facilities}
								onFavorited={() => props.setFilters(produce((s) => (s.favorites = true)))}
							/>
						</Match>
						<Match when={user()}>
							<PillButton
								aria-pressed={!!props.filters.favorites}
								onClick={() => props.setFilters(produce((s) => (s.favorites = !s.favorites || null)))}
							>
								Favorites
							</PillButton>
						</Match>
					</Switch>
				</div>
				<HorizontalRule />
			</Show>

			<ToggleSwitch
				checked={!!props.filters.accessDeals}
				reverse
				class="flex-wrap"
				onChange={() => props.setFilters(produce((s) => (s.accessDeals = !s.accessDeals || null)))}
			>
				<Label class="grow">
					<Icon name="troon-access" class="w-8" /> Featured Deals
				</Label>
				<FieldDescription>
					<p class="mr-16 text-sm text-neutral-800">
						{/* TODO: x% off? */}
						Show tee times more than x% off the standard rate. Access+ Members only.
					</p>
				</FieldDescription>
			</ToggleSwitch>

			<Show
				when={
					facilities && Array.from(facilities)?.length > 1
						? facilities
						: courses && Array.from(courses)?.length > 1
							? (courses as typeof facilities)
							: false
				}
			>
				{(facilities) => (
					<>
						<HorizontalRule />
						<CheckboxGroup name="facilities">
							<details ref={setGroupRef} class="group/details flex flex-col gap-2">
								<summary class="-mx-2 box-content flex w-full cursor-pointer justify-between gap-1 rounded-md px-2 py-1 hover:bg-brand-100 details-marker:hidden marker:hidden ">
									<Label class="pointer-events-none">Courses</Label>
									<span class="me-1 rotate-90 text-brand transition group-open/details:-rotate-90">
										<Icon name="chevron-right" />
									</span>
								</summary>
								<div class="flex flex-col gap-1">
									<For each={Array.from(facilities()).sort((a, b) => compare(a.name, b.name))}>
										{(facility) => (
											<Checkbox
												reverse
												value={facility.slug}
												checked={
													!props.filters.filterFacilities || props.filters.filterFacilities?.includes(facility.slug)
												}
												onChange={(e) => {
													const elements = groupRef()!.querySelectorAll(
														`[name=${e.target.name}]:checked`,
													) as NodeListOf<HTMLInputElement>;
													const checked = Array.from(elements).map((el) => el.value);
													props.setFilters(
														produce((s) => {
															s.filterFacilities = checked.length === (facilities()?.length ?? -1) ? null : checked;
														}),
													);
												}}
											>
												<Label class="grow font-normal">{facility.name}</Label>
											</Checkbox>
										)}
									</For>
								</div>
							</details>
						</CheckboxGroup>
					</>
				)}
			</Show>
		</div>
	);
}
export function TeeTimeFiltersFAB(props: ComponentProps<typeof TeeTimeFilters>) {
	const [filterDialogOpen, setFilterDialogOpen] = createSignal(false);

	return (
		<Dialog key="tee-time-search-fitlers" open={filterDialogOpen()} onOpenChange={setFilterDialogOpen}>
			<div class="fixed inset-x-0 bottom-4 z-50 flex flex-col items-center">
				<DialogTrigger class="size-fit shadow">
					<Icon name="filter" /> Filter by
				</DialogTrigger>
			</div>
			<DialogContent header="Filter by" headerLevel="h2" height="fit" noPadding>
				<div class="px-4 pb-4 md:px-6 md:pb-6">
					{/* @ts-ignore jumping through hoops for the fragments here */}
					<TeeTimeFilters
						hasFavorites={props.hasFavorites}
						courses={props.courses}
						facilities={props.facilities}
						filters={props.filters}
						multi={props.multi}
						setFilters={props.setFilters}
					/>
				</div>
				<div class="sticky inset-x-0 bottom-0 flex border-t border-neutral bg-white p-4 md:p-6">
					<Button onClick={() => setFilterDialogOpen(false)}>View results</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}

const TeeTimeFacilityFilterFragment = gql(`fragment TeeTimeFacilityFilter on Facility {
	name
	slug
	...FavoriteFacility
}`);

const TeeTimeCourseFilterFragment = gql(`fragment TeeTimeCourseFilter on Course {
	name
	slug
}`);
