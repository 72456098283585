import { mergeProps, Show, useContext } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { twMerge } from '@troon/tailwind-preset/merge';
import { Button } from '@kobalte/core/button';
import { Icon } from '@troon/icons';
import { useFocusable } from '../providers/focusable';
import { FormContext } from './form';
import type { JSX } from 'solid-js';

type Props = JSX.ButtonHTMLAttributes<HTMLButtonElement>;

export function PillButton(inputProps: Props) {
	const { data } = useContext(FormContext);
	const focusableProps = useFocusable<Props>();
	const props = mergeProps({ as: Button, appearance: 'primary' as const }, focusableProps, inputProps);

	return (
		<Dynamic
			{...props}
			component={props.as}
			aria-disabled={data?.pending || props.disabled}
			formnovalidate
			class={twMerge(
				'flex flex-row flex-nowrap items-center gap-1 text-nowrap rounded-full border border-neutral bg-white px-3 py-2 text-sm font-medium text-neutral-800 outline-none transition-all hover:border-brand hover:shadow-md focus-visible:border-brand active:scale-95 active:shadow-none aria-pressed:border-brand aria-pressed:bg-brand-100 aria-pressed:text-brand-600',
				props.class,
			)}
		>
			{props.children}
			<Show when={!!props['aria-pressed']}>
				<Icon name="close-md" class="size-3" />
			</Show>
		</Dynamic>
	);
}
