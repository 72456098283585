import { Show, Suspense } from 'solid-js';
import { Heading, Link } from '@troon/ui';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Icon } from '@troon/icons';
import { gql } from '../../graphql';
import { dayTimeToDate, formatDateTime, formatDateTimeAttribute } from '../../modules/date-formatting';
import { createFragment } from '../../graphql/create-fragment';
import type { FragmentType } from '../../graphql';
import type { ParentProps } from 'solid-js';

type Props = ParentProps<{
	bordered?: boolean;
	reservation: FragmentType<typeof ReservationHeaderFragment> | undefined;
}>;

export function FacilityReservationHeader(props: Props) {
	const reservation = createFragment(ReservationHeaderFragment, props, 'reservation');
	return (
		<header
			class={twJoin(
				'grid grid-cols-12 items-center gap-4 pb-6 sm:pb-8 lg:gap-8',
				props.bordered && 'mb-6 border-b border-neutral-500 sm:mb-8',
			)}
		>
			<div class={twJoin('col-span-12 flex flex-col gap-2 md:gap-4 lg:col-span-8')}>
				<Heading as="h1">
					<Suspense fallback="…">
						<Link href={`/course/${reservation?.facility.slug}`} class="hover:text-brand-600 hover:underline">
							<span class={twJoin('text-3xl font-semibold md:text-5xl')}>{reservation?.facility.name}</span>
						</Link>
					</Suspense>
				</Heading>

				<Show when={(reservation?.facility.courses.length ?? 0) > 1}>
					<p class="text-lg font-medium text-neutral-700">{reservation?.course.name}</p>
				</Show>

				<div class="order-first font-semibold text-brand">
					<Show when={reservation}>
						{(res) => (
							<time datetime={formatDateTimeAttribute(dayTimeToDate(res().dayTime))}>
								{formatDateTime(dayTimeToDate(res().dayTime))}
							</time>
						)}
					</Show>
				</div>

				<Show when={reservation}>
					{(res) => (
						<ul class="flex flex-wrap gap-x-6 gap-y-2 text-sm text-neutral-800 md:text-base">
							<li class="flex items-center gap-1">
								<Icon name="users" /> {res().playerCount} player
								{res().playerCount > 1 ? 's' : ''}
							</li>
							<li class="flex items-center gap-1">
								<Icon name="flag" />
								<span>{res().holeCount} holes</span>
							</li>
							<Show when={res().includesCart}>
								<li class="flex items-center gap-1">
									<Icon name="golf-cart" />
									<span>Cart included</span>
								</li>
							</Show>
						</ul>
					)}
				</Show>
			</div>

			<div class="col-span-12 lg:col-span-4">{props.children}</div>
		</header>
	);
}

const ReservationHeaderFragment = gql(`fragment ReservationHeader on Reservation {
  playerCount
  holeCount
  includesCart
  dayTime { day { year, month, day }, time { hour, minute } }
  facility {
    name
    slug
    courses { name }
  }
  course { name }
}`);
