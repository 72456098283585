import { children, createMemo, createSignal, Show } from 'solid-js';
import { Icon } from '@troon/icons';
import { Dynamic } from 'solid-js/web';
import { A, useIsRouting } from '@solidjs/router';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Picture } from './picture';
import { ActivityIndicator } from './activity-indicator';
import type { JSXElement } from 'solid-js';
import type { Dayjs } from '@troon/dayjs';

type Props = {
	badge?: JSXElement;
	courseName?: string;
	/**
	 * @default 'USD'
	 */
	currencyCode?: string;
	date: Dayjs;
	discount?: JSXElement;
	facilityName?: string;
	img?: string;
	/**
	 * @default 'en-US'
	 */
	locale?: string;
	playersMax: number;
	playersMin: number;
	rateMax: number;
	rateMin: number;
	href?: string;
	onClick?: (e: Event) => void;
};

export function TeeTimeCard(props: Props) {
	const [clicked, setClicked] = createSignal(false);
	const isRouting = useIsRouting();

	const badge = createMemo(() => 'badge' in props && !!props.badge);
	const discount = children(() => props.discount);

	const feeString = children(() => {
		const formatter = new Intl.NumberFormat(props.locale ?? 'en-US', {
			style: 'currency',
			currency: props.currencyCode ?? 'USD',
			currencyDisplay: 'narrowSymbol',
			trailingZeroDisplay: 'stripIfInteger',
		});
		if (discount()) {
			return (
				<span>
					<span class="text-brand">{formatter.format(props.rateMin)}</span>{' '}
					<span class="font-normal line-through">{formatter.format(props.rateMax)}</span>
				</span>
			);
		}
		if (props.rateMin !== props.rateMax) {
			return formatter.formatRange(props.rateMin, props.rateMax);
		}
		return formatter.format(props.rateMin);
	});

	return (
		<div class="flex size-full flex-col items-stretch justify-stretch @container/tt">
			<div
				class="relative flex grow flex-wrap items-start justify-between gap-4 overflow-hidden rounded border border-neutral bg-white p-4 transition-colors focus-within:border-brand focus-within:bg-brand-100 hover:border-brand hover:bg-brand-100/50 @md/tt:items-stretch"
				onClick={() => setClicked(true)}
			>
				<Show when={props.img}>
					{(img) => (
						<div class="order-1 shrink-0 grow-0 basis-7/12 self-start @md/tt:order-2 @md/tt:basis-0 @md/tt:justify-self-end">
							<div class="box-content aspect-square w-14 overflow-hidden rounded-md border border-neutral bg-white p-1">
								<Picture
									src={img()}
									alt=""
									width={56}
									height={56}
									sizes="3.5rem"
									mode="contain"
									class="size-14 object-cover"
								/>
							</div>
						</div>
					)}
				</Show>

				<Show when={badge()}>
					<div class={twJoin('order-2 shrink-0 grow-0 @md/tt:hidden', !props.img && 'order-3')}>{props.badge}</div>
				</Show>

				<div class="order-2 grow basis-7/12 truncate @md/tt:order-1 @md/tt:w-auto @md/tt:self-start">
					<Dynamic
						component={props.href ? A : 'div'}
						preload={true}
						onClick={props.onClick}
						href={props.href}
						class="flex shrink grow basis-full flex-col gap-1 truncate after:absolute after:inset-0 @md:basis-0"
					>
						<span class="sr-only">Reserve </span>
						<span class="flex justify-between">
							<time datetime={props.date.toISOString()}>
								<span class="text-2xl font-semibold @md/tt:text-xl">{props.date.format('h:mm A')}</span>
							</time>
						</span>
						<Show when={props.facilityName}>
							{(facilityName) => (
								<>
									<span class="sr-only"> at </span>
									<span class="truncate font-medium">{facilityName()}</span>
								</>
							)}
						</Show>
						<Show when={props.courseName}>
							{(courseName) => (
								<>
									<Show when={!props.facilityName}>
										<span class="sr-only"> on the </span>
									</Show>
									<span class="truncate text-sm font-medium text-neutral-800">{courseName()}</span>
									<span class="sr-only">course </span>
								</>
							)}
						</Show>
						<span class="flex items-center gap-x-1 text-neutral-800 @md/tt:text-sm">
							<Icon name="users" /> <span class="sr-only"> for </span>
							{props.playersMin !== props.playersMax
								? new Intl.NumberFormat(props.locale ?? 'en-US', {
										style: 'decimal',
										trailingZeroDisplay: 'stripIfInteger',
									}).formatRange(props.playersMin, props.playersMax)
								: new Intl.NumberFormat(props.locale ?? 'en-US', {
										style: 'decimal',
										trailingZeroDisplay: 'stripIfInteger',
									}).format(props.playersMin)}
							<span class="sr-only"> golfers.</span>
						</span>
					</Dynamic>
				</div>

				<div
					class={twJoin(
						'order-3 flex grow items-center justify-between gap-2 text-xl font-semibold @md/tt:order-4 @md/tt:items-end @md/tt:text-base',
						props.img ? 'basis-1/2 @md/tt:basis-full' : 'basis-1/4 @md/tt:flex-col',
					)}
				>
					<div
						class={twJoin(
							'flex grow basis-1/2 flex-wrap justify-start gap-2 whitespace-nowrap',
							!props.img && '@md/tt:justify-end',
						)}
					>
						<Show when={badge()}>
							<div class={'hidden shrink-0 @md/tt:block'}>{props.badge}</div>
						</Show>
						{feeString()}
					</div>
					<Show when={discount()}>{(discount) => <div>{discount()}</div>}</Show>
				</div>

				<Show when={clicked() && isRouting()}>
					<div class="absolute inset-0 flex items-center justify-center rounded bg-white/60">
						<ActivityIndicator class="size-8 text-brand" />
					</div>
				</Show>
			</div>
		</div>
	);
}
