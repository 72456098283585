import { getRequestEvent, isServer } from 'solid-js/web';
import { UAParser } from 'ua-parser-js';

export function getUserAgent() {
	const ua = (isServer ? getRequestEvent()?.request.headers.get('user-agent') : window.navigator.userAgent) ?? '';
	return ua;
}

const performanceRestricted =
	/(lighthouse|bingbot\/|googlebot\/|tenablewas|page speed insights|gptbot|chatgpt-user|facebookbot|googleother|google-extended|perplexitybot|adsbot-google-)/i;

export function isPerformanceRestrictedUserAgent(ua: string) {
	return performanceRestricted.test(ua);
}

export function getParsedUserAgent() {
	return new UAParser(getUserAgent()).getResult();
}
