import { createContext, mergeProps, onCleanup, onMount, splitProps, useContext } from 'solid-js';
import { isServer } from 'solid-js/web';
import { insertGtmScript } from './gtm';
import type PostHog from 'posthog-js';
import type { PostHog as PostHogNode } from 'posthog-node';
import type { ParentProps } from 'solid-js';

type PostHogWeb = typeof PostHog;

type Ctx = {
	posthog: PostHogWeb | undefined;
	flags: Record<string, boolean | string>;
	overrides: Record<string, boolean | string>;
};

const Context = createContext<Ctx>();

type Props = ParentProps & {
	posthog?: PostHogWeb | PostHogNode;
	flags?: Record<string, boolean | string>;
	overrides?: Record<string, boolean | string>;
	gtmId?: string;
};

export function AnalyticsProvider(inputProps: Props) {
	const merged = mergeProps({ flags: {}, overrides: {} }, inputProps);
	const [props] = splitProps(merged, ['flags', 'overrides', 'posthog']) as unknown as [Ctx];

	let callbackId: NodeJS.Timeout;
	onMount(() => {
		if (isServer || !inputProps.gtmId) {
			return;
		}

		callbackId = setTimeout(() => {
			insertGtmScript(inputProps.gtmId!);
		}, 2_000);
	});

	onCleanup(() => {
		if (callbackId) {
			clearTimeout(callbackId);
		}
	});

	return <Context.Provider value={props}>{inputProps.children}</Context.Provider>;
}

export function useAnalytics() {
	const ctx = useContext(Context);
	return ctx?.posthog;
}

export function useIsEnabled(key: string, defaultValue: boolean = false, sendEvents: boolean = true) {
	const ctx = useContext(Context);
	if (key in (ctx?.overrides ?? {})) {
		return !!ctx!.overrides[key];
	}

	if (isServer) {
		return !!ctx?.flags[key];
	}

	return !!(ctx?.posthog?.isFeatureEnabled(key, { send_event: sendEvents }) ?? defaultValue);
}

export function useExperiment(key: string, defaultBranch: string = 'control', sendEvents: boolean = true) {
	const ctx = useContext(Context);
	if (key in (ctx?.overrides ?? {})) {
		return ctx!.overrides[key];
	}

	if (isServer) {
		return ctx!.flags[key] ?? defaultBranch;
	}

	return ctx?.posthog?.getFeatureFlag(key, { send_event: sendEvents }) ?? defaultBranch;
}
