import { Heading } from '@troon/ui';
import { TroonGolfLandingPages } from './_components/layout';
import type { RouteDefinition } from '@solidjs/router';
import type { ComponentProps } from 'solid-js';

export default function TroonGolfArizonaLandingPage() {
	return (
		<TroonGolfLandingPages title="Troon Golf Hawaii" image="troon-golf-hawaii.png" slugs={slugs} location="Hawaii">
			<Heading as="h2">Experience the best golf in Hawaii with Troon.</Heading>
			<p>
				Troon Golf courses in Hawaii are as unique as the islands themselves, each woven into the serene terrain with a
				unique vision and infused with the impeccable quality that is a Troon Golf hallmark.
			</p>
		</TroonGolfLandingPages>
	);
}

const slugs: ComponentProps<typeof TroonGolfLandingPages>['slugs'] = {
	'kaanapali-golf-courses': { tel: '808.720.6694' },
	kapalua: { tel: '808.419.3617', url: 'https://www.chronogolf.com/en/club/5033/widget' },
	'kapolei-golf-club': { tel: '808.466.3999' },
	'mauna-lani,-auberge-resorts-collection': { tel: '808.319.4885' },
	'mauna-lani-auberge-resorts-collection': { tel: '808.319.4885' },
	'ocean-course-at-hokuala': { tel: '808.868.5171', url: 'https://troonhihokuala.ezlinksgolf.com' },
	'pearl-at-kalauao': { tel: '808.868.5140' },
	'princeville-makai-golf-club': { tel: '808.278.6133' },
	'the-king-kamehameha-golf-club': { tel: '808.249.0033' },
	'volcano-golf-course': { tel: '808.319.4745' },
};

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
