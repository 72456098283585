import { useAction, useSearchParams, useSubmission } from '@solidjs/router';
import { ActivityIndicator, Button, Heading, Link, TextLink } from '@troon/ui';
import { createEffect, Match, onMount, Show, Suspense, Switch } from 'solid-js';
import { useTrackEvent } from '@troon/analytics';
import { captureException } from '@sentry/solidstart';
import { Icon } from '@troon/icons';
import dayjs from '@troon/dayjs';
import { produce } from 'solid-js/store';
import { gql, mutationAction, TroonCardSubscriptionStatus, useMutation } from '../graphql';
import { useUser } from '../providers/user';
import { dayTimeToDate } from '../modules/date-formatting';
import { Receipt } from '../routes/access/checkout/_components/receipt';
import { usePersisted } from '../providers/persistence-store';
import { SupportButton } from './support';

type Props = {
	subscriptionId: string;
	productId: string;
	onContinue?: () => void;
	continueText?: string;
};
const artificialTimeoutMs = 2_000;

export default function ConfirmSubscription(props: Props) {
	const trackEvent = useTrackEvent();
	const user = useUser();
	const [, setPersisted] = usePersisted();
	const [params] = useSearchParams<{ confirmed?: string }>();

	const confirmSubmissionAction = useMutation(confirmSubscriptionMutation);
	const confirmSubscription = useAction(confirmSubmissionAction);
	const confirmation = useSubmission(confirmSubmissionAction);

	createEffect(() => {
		if (confirmation.result?.error) {
			captureException(confirmation.result.error);
		}
	});

	onMount(async () => {
		if (!props.subscriptionId) {
			return;
		}
		const data = new FormData();
		data.set('subscriptionId', props.subscriptionId);
		setTimeout(async () => {
			const res = await confirmSubscription(data);
			if (res.data && !params.confirmed) {
				trackEvent('checkout', {
					productId: props.productId,
					value: parseFloat(res.data.confirmation.totalAmount.value),
				});
				// We don't set search params with useSearchParams because that triggers a routing call
				const url = new URL(window.location.href);
				url.searchParams.set('confirmed', '1');
				history.replaceState({}, '', url.toString());
			}
			setPersisted(
				produce((s) => {
					if (s.promoCode) {
						delete s.promoCode;
					}
				}),
			);
		}, artificialTimeoutMs);
	});

	return (
		<Suspense>
			<Switch>
				<Match when={confirmation.pending || !confirmation.result}>
					<ActivityIndicator class="size-16 text-brand">Confirming your Troon Access</ActivityIndicator>
				</Match>
				<Match when={confirmation.result?.error}>
					{(error) => (
						<>
							<p>
								{error()
									.graphQLErrors.map(({ message }) => message)
									.join('')}
							</p>
							<SupportButton />
						</>
					)}
				</Match>
				<Match when={confirmation.result?.data}>
					{(data) => (
						<Show when={user()?.me}>
							{(me) => (
								<div class="flex flex-col gap-8">
									<div class="flex flex-col items-center gap-6 text-center">
										<Icon name="circle-check-custom" class="text-8xl" />
										<Heading as="h1" size="h2">
											Your {data().confirmation.subscriptionName} is now active!
										</Heading>
										<p class="text-lg">We’ve sent a confirmation email to {me().email}.</p>
										<Button
											as={Link}
											href="/"
											onClick={(e) => {
												if (props.onContinue) {
													e.preventDefault();
													props.onContinue();
												}
											}}
										>
											{props.continueText ?? 'Find a Tee Time'}
										</Button>
									</div>
									<div class="flex flex-col gap-4 rounded border border-neutral p-6">
										<h2 class="text-xl font-semibold">Order summary</h2>
										<p>
											<Switch>
												<Match when={data().confirmation.autoRenews && data().confirmation.nextInvoiceDate}>
													{(nextInvoiceDate) => (
														<>
															Your membership will renew automatically on{' '}
															{dayjs(dayTimeToDate(nextInvoiceDate())).format('MM/DD/YYYY')}.{' '}
														</>
													)}
												</Match>
												<Match when={data().confirmation.endDayTime}>
													{(endDayTime) => (
														<>
															Your membership will not auto-renew and will end on{' '}
															{dayjs(dayTimeToDate(endDayTime())).format('MM/DD/YYYY')}.{' '}
														</>
													)}
												</Match>
											</Switch>
											You can manage your membership through your{' '}
											<TextLink href="/account/access">account settings</TextLink>.
										</p>
										<Receipt
											discount={data().confirmation.discountAmount}
											subtotal={data().confirmation.subtotalAmount}
											tax={data().confirmation.taxAmount}
											total={data().confirmation.totalAmount}
										/>
									</div>
								</div>
							)}
						</Show>
					)}
				</Match>
			</Switch>
		</Suspense>
	);
}

const confirmSubscription = gql(`mutation confirmTroonCardSubscription($subscriptionId: String!) {
  confirmation: confirmTroonCardSubscriptionPayment(troonCardSubscriptionId: $subscriptionId) {
    id
    status
    subscriptionName
		autoRenews
    startDayTime { day { year, month, day }, time { hour, minute } }
    endDayTime { day { year, month, day }, time { hour, minute } }
    nextInvoiceDate { day { year, month, day }, time { hour, minute } }
    totalAmount { displayValue, value }
    discountAmount { displayValue, value }
    taxAmount { displayValue, value }
    subtotalAmount { displayValue, value }
  }
}`);

export const confirmSubscriptionMutation = mutationAction(confirmSubscription, {
	retry: {
		retryIf: (res) => {
			return res.data?.confirmation.status !== TroonCardSubscriptionStatus.Active;
		},
	},
	revalidates: ['loggedInUser'],
});
