import { Title } from '@solidjs/meta';
import { createAsync } from '@solidjs/router';
import { For, Suspense } from 'solid-js';
import { Container, Heading, Page, Section } from '@troon/ui';
import { gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import { TeeTimeAlert } from '../../components/tee-time-alert';
import { PageHeader } from '../../components/page-header';

export default function TeeTimeAlertsPage() {
	const data = createAsync(() => getTeeTimeAlerts({}));

	return (
		<Container>
			<Page>
				<Title>Tee Time Alerts | Troon</Title>
				<Section>
					<div class="border-b border-neutral-300 pb-8 md:pb-12">
						<PageHeader
							title="Tee time alerts"
							subtext="Get notified when a tee time becomes available that matches your preferences."
						/>
					</div>
					<Heading as="h2">Active tee time alerts</Heading>
					<Suspense>
						<ul class="flex flex-col gap-4 md:gap-6">
							<For
								each={data()?.teeTimeAlerts}
								fallback={
									<li>There are no active tee time alerts. When you create a new alert, it will be available here.</li>
								}
							>
								{(alert) => (
									<li class="rounded border border-neutral p-4 md:p-6">
										<TeeTimeAlert teeTimeAlert={alert} editable />
									</li>
								)}
							</For>
						</ul>
					</Suspense>
				</Section>
			</Page>
		</Container>
	);
}

const query = gql(`
query teeTimeAlerts {
  teeTimeAlerts(isActive: true) {
    ...TeeTimeAlert
  }
}
`);

const getTeeTimeAlerts = cachedQuery(query, {});
