import { Show } from 'solid-js';
import { twMerge } from '@troon/tailwind-preset/merge';
import { Icon } from '@troon/icons';
import { Button } from '../forms/button';
import { Link } from './link';
import { Picture } from './picture';
import type { ComponentProps, ParentProps } from 'solid-js';

type Props = ParentProps & {
	appearance?: ComponentProps<typeof Button>['appearance'];
	image?: Omit<ComponentProps<typeof Picture>, 'src'> & { src?: string | null };
	icon?: ComponentProps<typeof Icon>['name'];
	action?: string;
	href?: string;
	hiddenButton?: boolean;
};

export function ReservationCard(props: Props) {
	return (
		<div
			class={twMerge(
				'relative rounded border border-neutral-500 transition-colors',
				props.href && 'hover:bg-neutral-100/50',
			)}
		>
			<div class="flex flex-row items-center justify-stretch gap-4 p-4">
				<div class="order-last min-w-32 basis-2/12 items-center justify-center overflow-hidden rounded bg-neutral-500 text-neutral md:order-none md:aspect-auto md:basis-3/12 lg:basis-2/12">
					{props.image?.src ? <Picture {...props.image} src={props.image.src!} class="size-full object-cover" /> : null}
					{props.icon && !props.image?.src ? <Icon name={props.icon} class="text-neutral-700" /> : null}
				</div>
				<div class="flex grow flex-row items-center justify-between">
					{props.children}
					<Show when={props.action && props.href && !props.hiddenButton}>
						<div class="relative z-10 hidden md:block">
							<Button appearance={props.appearance ?? 'transparent'} as={Link} href={props.href!}>
								{props.action}
							</Button>
						</div>
					</Show>
				</div>
				<Show when={props.href}>
					<Link
						href={props.href!}
						class="outline-none after:absolute after:inset-0 after:z-0 after:rounded focus-visible:ring-0 focus-visible:after:ring focus-visible:after:ring-brand-100"
					>
						<span class="sr-only">{props.action ?? 'Details'}</span>
					</Link>
				</Show>
			</div>
		</div>
	);
}
