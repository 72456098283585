import { Heading, ReservationCard as OldReservationCard } from '@troon/ui';
import { Show } from 'solid-js';
import { Icon } from '@troon/icons';
import { dayTimeToDate, formatDateTime, formatDateTimeAttribute } from '../modules/date-formatting';
import { ReservationState, gql } from '../graphql';
import { createFragment } from '../graphql/create-fragment';
import type { FragmentType } from '../graphql';

type Props = {
	reservation: FragmentType<typeof ReservationCardFragment>;
	showAction?: boolean;
};

export function ReservationCard(props: Props) {
	const reservation = createFragment(ReservationCardFragment, props, 'reservation');
	return (
		<OldReservationCard
			appearance="secondary"
			action={props.showAction !== false ? 'Details' : undefined}
			href={`/reservation/${reservation.id}`}
			icon="calendar"
			image={{
				src: reservation.facility.metadata?.hero?.url,
				width: 204,
				height: 153,
				sizes: '200px',
			}}
		>
			<div class="flex flex-col gap-y-2">
				<Heading as="h2" class="flex flex-col text-lg md:text-lg" size="p">
					{reservation.facility.name}
				</Heading>
				<Show when={(reservation.facility.courses.length ?? 0) > 1}>
					<p class="font-medium text-neutral-800">{reservation.course.name}</p>
				</Show>
				<div class="order-first flex flex-row flex-wrap items-center gap-2">
					<Show when={reservation.state === ReservationState.Cancelled}>
						<span class="self-start rounded bg-neutral px-2 py-1 text-sm font-semibold uppercase text-black">
							Cancelled
						</span>
					</Show>
					<time
						datetime={formatDateTimeAttribute(dayTimeToDate(reservation.dayTime))}
						class="text-sm font-semibold text-brand"
					>
						{formatDateTime(dayTimeToDate(reservation.dayTime))}
					</time>
				</div>
				<ul class="flex flex-wrap gap-x-4 text-sm text-neutral-700">
					<li class="flex items-center gap-1">
						<Icon name="users" />
						{reservation.playerCount}
					</li>
					<li class="flex items-center gap-1">
						<Icon name="flag" />
						{reservation.holeCount} holes
					</li>
					<Show when={reservation.includesCart}>
						<li class="flex items-center gap-1">
							<Icon name="golf-cart" />
							Cart included
						</li>
					</Show>
				</ul>
			</div>
		</OldReservationCard>
	);
}

const ReservationCardFragment = gql(`fragment ReservationCard on Reservation {
  id
  teeTimeId
  dayTime { day { year, month, day }, time { hour, minute } }
	includesCart
	playerCount
	holeCount
	state
	facility {
		name
		metadata { hero { url } }
		courses { name }
	}
  course { name }
}`);
