import { Picture, Dialog, DialogContent } from '@troon/ui';
import { createSignal, lazy, Match, Show, Suspense, Switch } from 'solid-js';

import { Trigger } from '@kobalte/core/dialog';
import { Icon } from '@troon/icons';

type Props = {
	dialog?: boolean;
	src: string;
	poster?: string;
};

const ActualVideo = lazy(async () => await import('./hls-video'));

export function Video(props: Props) {
	return (
		<Switch>
			<Match when={props.dialog}>
				<PopupVideo {...props} />
			</Match>
			<Match when={!props.dialog}>
				<InlineVideo {...props} />
			</Match>
		</Switch>
	);
}

export function PopupVideo(props: Props) {
	return (
		<div
			// eslint-disable-next-line tailwindcss/no-arbitrary-value
			class="relative aspect-[4/3] w-full overflow-hidden rounded"
		>
			<Dialog key="video">
				<Trigger
					as="button"
					class="group size-full outline-none focus-visible:ring-2 focus-visible:ring-brand-100 focus-visible:ring-offset-2"
				>
					<Picture
						src={props.poster}
						// eslint-disable-next-line tailwindcss/no-arbitrary-value
						class="absolute inset-0 z-0 aspect-[4/3] size-full bg-center object-cover group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-100"
						width={768}
						height={576}
						sizes="(min-width: 1024px) 60vw, (min-width: 768px) 75vw, 95vw"
						loading="lazy"
					/>
					<span class="absolute inset-0 flex items-center justify-center text-white outline-none focus-visible:ring focus-visible:ring-brand-100 focus-visible:ring-offset-2 active:scale-95 group-hover:text-brand-100/80 motion-safe:transition-all motion-safe:duration-200">
						<Icon name="play-button" class="size-16" />
						<span class="sr-only">Play video</span>
					</span>
				</Trigger>
				<DialogContent width="fit">
					<div class="aspect-video w-full">
						<Suspense>
							<ActualVideo src={props.src} />
						</Suspense>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}

function InlineVideo(props: Props) {
	const [showVideo, setShowVideo] = createSignal(false);

	return (
		<div class="group relative aspect-video w-full overflow-hidden rounded">
			<Picture
				src={props.poster}
				class="absolute z-0 aspect-video size-full bg-center object-cover group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-100"
				width={768}
				height={576}
				sizes="(min-width: 1024px) 60vw, (min-width: 768px) 75vw, 95vw"
				loading="lazy"
			/>
			<Show when={showVideo()}>
				<Suspense>
					<ActualVideo src={props.src} class="absolute inset-0 z-10" />
				</Suspense>
			</Show>
			<Show when={!showVideo()}>
				<button
					class="absolute inset-0 flex items-center justify-center text-white outline-none focus-visible:ring focus-visible:ring-brand-100 focus-visible:ring-offset-2 active:scale-95 group-hover:text-brand-100/80 motion-safe:transition-all motion-safe:duration-200"
					onClick={() => {
						setShowVideo(true);
					}}
				>
					<Icon name="play-button" class="size-16" />
					<span class="sr-only">Play video</span>
				</button>
			</Show>
		</div>
	);
}
