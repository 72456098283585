import { createMemo, createSignal, Show } from 'solid-js';
import { ActivityIndicator, Link, Picture } from '@troon/ui';
import { useTrackEvent } from '@troon/analytics';
import { useIsRouting } from '@solidjs/router';
import { Icon } from '@troon/icons';
import dayjs from '@troon/dayjs';
import { dayTimeToDate, formatDateTimeAttribute } from '../../../modules/date-formatting';
import { createDollarFormatter, createNumberFormatter, holesFormatter } from '../../../modules/number-formatting';
import { gql } from '../../../graphql';
import { createFragment } from '../../../graphql/create-fragment';
import type { FragmentType } from '../../../graphql';

type Props = {
	teeTime: FragmentType<typeof TeeTimeFragment>;
	facility: FragmentType<typeof TeeTimeFacilityFragment>;
	selectedPlayers?: number;
};
export function TeeTime(props: Props) {
	const teeTime = createFragment(TeeTimeFragment, props, 'teeTime');
	const facility = createFragment(TeeTimeFacilityFragment, props, 'facility');
	const dollarFormatter = createDollarFormatter('en', teeTime.minPrice.code ?? 'USD');
	const numberFormatter = createNumberFormatter();

	const feeString = createMemo(() => {
		const min = teeTime.minPrice;
		const max = teeTime.maxPrice;

		if (min.value !== max.value) {
			return dollarFormatter().formatRange(min.value, max.value);
		}
		return dollarFormatter().format(min.value);
	});

	const trackEvent = useTrackEvent();
	const [clicked, setClicked] = createSignal(false);
	const isRouting = useIsRouting();

	const params = createMemo(() => {
		const params = new URLSearchParams();
		if (props.selectedPlayers) {
			params.set('players', `${props.selectedPlayers}`);
		}
		return params;
	});

	return (
		<div class="relative flex flex-col gap-2 rounded border border-neutral p-4 hover:border-brand-300 hover:bg-brand-100">
			<Picture
				src={facility.metadata?.hero?.url}
				width={378}
				height={118}
				sizes="(min-width: 1024px) 33vw, (min-width: 768px) 50vw, 95vw"
				class="w-full rounded"
			/>
			<div class="flex items-start justify-between">
				<h4 class="text-nowrap text-xl font-semibold">
					<time datetime={formatDateTimeAttribute(dayTimeToDate(teeTime.dayTime))}>
						{dayjs(dayTimeToDate(teeTime.dayTime)).format('h:mm A')}
					</time>
				</h4>
				<div class="text-nowrap text-lg font-semibold">{feeString()}</div>
			</div>
			<div class="grow text-sm text-neutral-700">{facility.name}</div>
			<ul class="flex flex-row flex-wrap gap-4 text-sm">
				<li class="inline-flex items-center gap-1">
					<Icon name="users" />
					{teeTime.minPlayers !== teeTime.maxPlayers
						? numberFormatter().formatRange(teeTime.minPlayers, teeTime.maxPlayers)
						: numberFormatter().format(teeTime.minPlayers)}
					<span class="sr-only">golfer(s)</span>
				</li>
				<li class="inline-flex items-center gap-x-1">
					<Icon name="flag" />
					<span>{holesFormatter(numberFormatter(), teeTime.holesOption)} holes</span>
				</li>
				{teeTime.cartIncluded ? (
					<li class="inline-flex items-center gap-x-1">
						<Icon name="golf-cart" title="Cart" /> Included
					</li>
				) : null}
			</ul>
			<Link
				href={`/course/${facility.slug}/reserve-tee-time/${teeTime.id}?${params().toString()}`}
				class="absolute inset-0 z-10"
				onClick={() => {
					setClicked(true);
					trackEvent('didSelectTeeTime', {
						courseId: teeTime.courseId,
						...teeTime.dayTime.day,
						...teeTime.dayTime.time,
					});
				}}
			>
				<span class="sr-only">
					Book {facility.name} at {dayjs(dayTimeToDate(teeTime.dayTime)).format('h:mm A')} for {feeString()}.
				</span>
			</Link>
			<Show when={clicked() && isRouting()}>
				<div class="absolute inset-0 z-20 flex items-center justify-center bg-white/50">
					<ActivityIndicator class="text-brand-600" />
				</div>
			</Show>
		</div>
	);
}

export const TeeTimeFragment = gql(`fragment MultiTeeTime on CourseTeeTime {
	id
	courseId
	course {
		name
		facility { slug }
	}
	dayTime { day { year, month, day }, time { hour, minute } }
	minPrice { code, value }
	maxPrice { code, value }
	minPlayers
	maxPlayers
	holesOption
	cartIncluded
}`);

export const TeeTimeFacilityFragment = gql(`fragment MultiTeeTimeFacility on Facility {
	name
	slug
	metadata { hero { url } }
}`);
