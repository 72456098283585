import { createContext, useContext } from 'solid-js';
import { ToastRegion } from '../overlays/toast';
import { DialogProvider } from './dialog';
import type { ComponentProps, ParentProps } from 'solid-js';

type Props = ParentProps<{ baseUrl: string } & Partial<ComponentProps<typeof DialogProvider>>>;

export function UIProvider(props: Props) {
	// eslint-disable-next-line solid/reactivity
	if (!props.baseUrl.startsWith('http')) {
		throw new Error('Invalid host – missing protocol');
	}
	return (
		// eslint-disable-next-line solid/reactivity
		<HostContext.Provider value={props.baseUrl}>
			<DialogProvider onDialogClosed={props.onDialogClosed} onDialogOpened={props.onDialogOpened}>
				{props.children}
				<ToastRegion />
			</DialogProvider>
		</HostContext.Provider>
	);
}

const HostContext = createContext<string>('https://localhost');

export function useBaseUrl() {
	return useContext(HostContext);
}
