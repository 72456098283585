import { createAsync } from '@solidjs/router';
import { Meta } from '@solidjs/meta';
import { AccessProductsCtx, getAccessProducts } from '../providers/card';
import { getConfigValue } from '../modules/config';
import { usePersisted } from '../providers/persistence-store';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

export default function CardsPageLayout(props: RouteSectionProps) {
	const [persisted] = usePersisted();
	const data = createAsync(async () => (await getAccessProducts({ promoCode: persisted.promoCode?.value }))?.products, {
		deferStream: true,
	});

	return (
		<>
			<Meta name="og:image" content={`${getConfigValue('IMAGE_HOST')}/digital/photo/og/access.png?format=jpeg`} />
			<AccessProductsCtx.Provider value={data}>{props.children}</AccessProductsCtx.Provider>
		</>
	);
}

export const route = {
	preload: async () => getAccessProducts({}),
	info: { banner: { slug: 'troon-access-landing-page-cyber-promo' } },
} satisfies RouteDefinition;
