import { Button, DialogContent, Dialog, Link, Picture, DialogTrigger } from '@troon/ui';
import { Show, For, Suspense, lazy, createMemo, createSignal } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { Icon } from '@troon/icons';
import dayjs from '@troon/dayjs';
import { gql } from '../graphql';
import { dayTimeToDate, dayToDayJs, timeFrameNameFromHours } from '../modules/date-formatting';
import { createFragment } from '../graphql/create-fragment';
import type { CalendarDay, FragmentType } from '../graphql';

type Props = {
	editable?: boolean;
	teeTimeAlert: FragmentType<typeof TeeTimeAlertFragment>;
	level?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

const EditTeeTimeAlert = lazy(() => import('./tee-time-alert-edit'));

export function TeeTimeAlert(props: Props) {
	const [editOpen, setEditOpen] = createSignal(false);
	const teeTimeAlert = createFragment(TeeTimeAlertFragment, props, 'teeTimeAlert');

	const reserveParams = new URLSearchParams();
	const searchParams = new URLSearchParams({
		startAt: `${teeTimeAlert.facilityTrigger.startAt?.hour ?? 0}`,
		endAt: `${teeTimeAlert.facilityTrigger.endAt?.hour ?? 24}`,
		year: `${(teeTimeAlert.facilityTrigger.day as CalendarDay).year}`,
		month: `${(teeTimeAlert.facilityTrigger.day as CalendarDay).month}`,
		day: `${(teeTimeAlert.facilityTrigger.day as CalendarDay).day}`,
		courseIds: teeTimeAlert.facilityTrigger.courseIds.join(','),
	});
	if (teeTimeAlert.facilityTrigger.players) {
		reserveParams.set('players', `${teeTimeAlert.facilityTrigger.players}`);
		searchParams.set('players', `${teeTimeAlert.facilityTrigger.players}`);
	}

	const alertDate = createMemo(() =>
		dayToDayJs(
			teeTimeAlert.facilityTrigger.day as CalendarDay,
			teeTimeAlert.facilityTrigger.facility.timezone,
		).toDate(),
	);

	return (
		<div class="flex flex-row flex-wrap items-stretch gap-4 @container @3xl:flex-nowrap">
			<div class="w-full grow self-stretch @3xl:grow-0 @3xl:basis-48">
				<Picture
					// eslint-disable-next-line tailwindcss/no-arbitrary-value
					class="aspect-[8/3] w-full rounded bg-neutral-500 object-cover @3xl:aspect-[4/3]"
					src={teeTimeAlert.facilityTrigger.facility.metadata?.hero?.url}
					alt={teeTimeAlert.facilityTrigger.facility.name}
					width={400}
					height={145}
					sizes="(min-width: 768px) 200px, 95vw"
				/>
			</div>
			<div class="flex grow flex-col justify-between gap-2 self-stretch">
				<div class="flex shrink-0 grow flex-row justify-between gap-4">
					<div>
						<Dynamic component={props.level ?? 'h2'} class="text-lg font-semibold">
							{teeTimeAlert.facilityTrigger.facility.name}
						</Dynamic>
						<p class="text-sm text-neutral-800">
							<time class="block">
								{dayjs.tz(alertDate(), teeTimeAlert.facilityTrigger.facility.timezone).format('dddd, MMMM D')} •{' '}
								{timeFrameNameFromHours(
									teeTimeAlert.facilityTrigger.startAt?.hour ?? 0,
									teeTimeAlert.facilityTrigger.endAt?.hour ?? 24,
									true,
								)}
							</time>
							<Show when={teeTimeAlert.facilityTrigger.players}>
								{(players) => (
									<>
										<Icon name="users" /> {players()} player
										{players() > 1 ? 's' : ''}
									</>
								)}
							</Show>
						</p>
					</div>
					<div class="flex flex-wrap items-stretch gap-2 self-start @3xl:self-end">
						<Show when={teeTimeAlert.facilityTrigger.courseTeeTimesPreview?.hasMore}>
							<Button
								class="hidden shrink @3xl:block"
								appearance="secondary"
								as={Link}
								href={`/course/${teeTimeAlert.facilityTrigger.facility.slug}/reserve-tee-time/?${searchParams.toString()}`}
							>
								View all
							</Button>
						</Show>
						<Show when={props.editable}>
							<Suspense>
								<Dialog key="edit-tee-time-alert" open={editOpen()} onOpenChange={setEditOpen}>
									<DialogTrigger appearance="secondary">
										<Icon name="edit-pencil-01" />
										<span class="sr-only">
											Edit alert for{' '}
											{dayjs.tz(alertDate(), teeTimeAlert.facilityTrigger.facility.timezone).format('dddd, MMMM D')} •{' '}
											{timeFrameNameFromHours(
												teeTimeAlert.facilityTrigger.startAt?.hour ?? 0,
												teeTimeAlert.facilityTrigger.endAt?.hour ?? 24,
												true,
											)}
										</span>
									</DialogTrigger>
									<DialogContent header="Edit tee time alert" headerLevel="h3">
										<EditTeeTimeAlert
											id={teeTimeAlert.id}
											onComplete={() => setEditOpen(false)}
											facilities={[teeTimeAlert.facilityTrigger.facility]}
											selectedDay={alertDate()}
											selectedTime={[
												teeTimeAlert.facilityTrigger.startAt?.hour ?? 0,
												teeTimeAlert.facilityTrigger.endAt?.hour ?? 24,
											]}
											selectedPlayers={teeTimeAlert.facilityTrigger.players ?? -1}
										/>
									</DialogContent>
								</Dialog>
							</Suspense>
						</Show>
					</div>
				</div>
				<ul class="flex shrink flex-row gap-2">
					<For each={teeTimeAlert.facilityTrigger.courseTeeTimesPreview?.teeTimes}>
						{(teeTime) => (
							<li class="shrink">
								<Button
									as={Link}
									href={`/course/${teeTimeAlert.facilityTrigger.facility.slug}/reserve-tee-time/${teeTime.id}/?${reserveParams.toString()}`}
								>
									{dayjs
										.tz(
											dayTimeToDate({
												day: teeTimeAlert.facilityTrigger.day,
												time: teeTime.dayTime.time,
											}),
											teeTimeAlert.facilityTrigger.facility.timezone,
										)
										.format('h:mm A')}
								</Button>
							</li>
						)}
					</For>
					<Show when={teeTimeAlert.facilityTrigger.courseTeeTimesPreview?.hasMore}>
						<li class="shrink @3xl:hidden">
							<Button
								appearance="transparent"
								as={Link}
								href={`/course/${teeTimeAlert.facilityTrigger.facility.slug}/reserve-tee-time/?${searchParams.toString()}`}
							>
								+ more
							</Button>
						</li>
					</Show>
				</ul>
			</div>
		</div>
	);
}

const TeeTimeAlertFragment = gql(`fragment TeeTimeAlert on TeeTimeAlert {
  id
	state
	pushEnabled
	emailEnabled
	facilityTrigger {
    courseIds
		day { year, month, day }
		startAt { hour }
		endAt { hour }
		players
		facility {
			name
			slug
      timezone
			metadata { hero { url } }
			...TeeTimeAlertForm
		}
		courseTeeTimesPreview {
			hasMore
			teeTimes {
        id
				dayTime { time { hour, minute } }
			}
		}
	}
}`);
