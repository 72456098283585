import { Navigate, action } from '@solidjs/router';
import { Form, Input, TextField, Label, Button, Errors } from '@troon/ui';
import { useTrackEvent } from '@troon/analytics';
import { For, Match, Show, Switch } from 'solid-js';
import { unwrap } from 'solid-js/store';
import { gql, getApiClient } from '../../../graphql';
import { useAuthStore } from '../flow';
import { timeoutRetry } from '../../../graphql/timeout-retry';
import { revalidate } from '../../../graphql/cache';
import { loginQuery } from './01-login';
import type { Track } from '@troon/analytics';

type Props = {
	onComplete?: () => void;
};

export function PostalCode(props: Props) {
	const [store] = useAuthStore();
	const trackEvent = useTrackEvent();
	// eslint-disable-next-line solid/reactivity
	const action = register(trackEvent, props.onComplete);

	return (
		<Switch>
			<Match when={!store.data.email || !store.data.password || !store.data.firstName || !store.data.lastName}>
				<Navigate href="/auth" />
			</Match>

			<Match when={true}>
				<Form action={action} document={signupQuery} method="post" suppressRequired>
					<For each={Object.entries(unwrap(store.data))}>
						{([key, value]) => (
							<Show when={value}>
								<input type="hidden" name={key} value={value} />
							</Show>
						)}
					</For>

					<TextField name="zipcode">
						<Label>Postal code</Label>
						<Input
							inputMode="numeric"
							onInput={() => {
								trackEvent('signupEnteredZipcode', { email: store.data.email });
							}}
						/>
					</TextField>

					<Errors />

					<Button type="submit">Continue</Button>
				</Form>
			</Match>
		</Switch>
	);
}

export const signupQuery = gql(`
	mutation signupV2($email: String!, $password: String!, $zipcode: String!, $firstName: String!, $lastName: String!) {
		signup: signupV2(input: { email: $email, password: $password, zipcode: $zipcode, firstName: $firstName, lastName: $lastName }) {
			succeeded
		}
	}
`);

export const register = (trackEvent: Track, onComplete: (() => void) | undefined) =>
	action(async (formData) => {
		const client = getApiClient();
		const email = formData.get('email') as string;
		const password = formData.get('password') as string;

		const res = await client.mutation(signupQuery, {
			email,
			password,
			zipcode: formData.get('zipcode') as string,
			firstName: formData.get('firstName') as string,
			lastName: formData.get('lastName') as string,
		});

		if (res.data?.signup?.succeeded) {
			trackEvent('signup', {
				$set: { email, fullName: `${formData.get('firstName')} ${formData.get('lastName')}` },
				email,
			});

			// Signup succeeded – make sure we get logged in by retrying.
			const loginResponse = await timeoutRetry(() =>
				client.mutation(loginQuery, {
					email,
					password,
				}),
			);

			if (loginResponse.data?.login.user) {
				await revalidate(undefined, true);
				if (onComplete) {
					onComplete();
				}
			} else {
				return loginResponse;
			}
		}

		return res;
	}, 'register');
