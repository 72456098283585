import { Title } from '@solidjs/meta';
import { Heading } from '@troon/ui';
import { AccessReferAFriend } from '../../components/upsells/refer-a-friend';
import { FrequentlyAskedQuestions } from '../../components/faqs';

export default function Referrals() {
	return (
		<>
			<Title>Refer a Friend | My account | Troon</Title>
			<Heading as="h1">Refer a Friend</Heading>
			<p>
				Receive a 100 Troon Rewards points when someone uses your referral link to buy Troon Access. They’ll receive $25
				OFF their Troon Access membership.
			</p>

			<AccessReferAFriend location="account" withPhoto={false} withLogo={false} layout="compact" />

			<Heading as="h2" size="h4">
				FAQs
			</Heading>
			<div class="rounded border border-neutral">
				<FrequentlyAskedQuestions id="troon-access-referrals" />
			</div>
		</>
	);
}
