import { Heading } from '@troon/ui';
import { Video } from '@troon/video';
import { NoHydration } from 'solid-js/web';
import { getConfigValue } from '../../../modules/config';
import type { RouteDefinition } from './_types';

export default function DailyFeeServicesPage() {
	return (
		<>
			<NoHydration>
				<Heading as="h2">Why Daily Fee & Resort Clubs Partner With Troon</Heading>
				<ul class="flex list-disc flex-col gap-2 ps-8">
					<li>
						Operate at a higher level by focusing on the strategic direction of your club and less on the day-to-day.
					</li>
					<li>
						Outperform your comp set, whether your goal is to increase rate, occupancy, or both via increased REVPATT.
					</li>
					<li>Eliminate the need to source your own candidates and use headhunters.</li>
					<li>
						Opt-in on Troon branding/programs for another “unique selling proposition” and enhanced revenue delivery.
					</li>
					<li>Utilize proven proactive digital marketing campaigns that drive rounds and revenue.</li>
					<li>
						Receive a higher priority on supplies and equipment at the best pricing with preferred vendor relationships.
					</li>
				</ul>
			</NoHydration>

			<Video
				src={`${getConfigValue('CDN')}/digital/video/management-services/daily-fee/index.m3u8`}
				poster={`${getConfigValue('IMAGE_HOST')}/digital/video/management-services/daily-fee/poster.jpg`}
			/>
		</>
	);
}

export const route = {
	info: {
		nav: { hero: true },
		hero: `${getConfigValue('IMAGE_HOST')}/digital/hero/daily-fee-and-resort.jpg`,
		title: 'Daily Fee & Resort',
		description: 'Enhance your guest experience and grow your club’s revenue.',
	},
} satisfies RouteDefinition;
