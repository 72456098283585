import { Link, Picture } from '@troon/ui';
import { Icon } from '@troon/icons';
import { Show } from 'solid-js';
import { dayTimeToDate, formatDateTime } from '../../../../../../modules/date-formatting';
import { createNumberFormatter, holesFormatter } from '../../../../../../modules/number-formatting';
import type { CalendarDayTime, DayTimeFragment, RoundHolesOption } from '../../../../../../graphql';

type Props = {
	cartIncluded: boolean;
	courseName: string;
	dayTime: DayTimeFragment | CalendarDayTime;
	facilityName: string;
	facilitySlug: string;
	holes: RoundHolesOption;
	logo?: string;
};

export function TeeTimeFacilityHeader(props: Props) {
	const numberFormatter = createNumberFormatter();

	return (
		<div>
			<div class="relative mb-4 flex items-center justify-between gap-8 border-b border-neutral pb-4">
				<div class="flex grow flex-col gap-1">
					<p class="text-lg font-semibold">
						<Link
							href={`/course/${props.facilitySlug}/reserve-tee-time`}
							class="after:absolute after:inset-0 hover:text-brand hover:underline"
						>
							{props.facilityName}
						</Link>
					</p>
					<p>{formatDateTime(dayTimeToDate(props.dayTime as CalendarDayTime))}</p>
				</div>

				<div class="shrink-0 overflow-hidden rounded border border-neutral p-2">
					<Picture
						preload
						src={props.logo}
						width={200}
						height={200}
						sizes="5rem"
						mode="contain"
						class="w-16 rounded object-cover md:w-20"
					/>
				</div>
			</div>

			<ul class="flex flex-row flex-wrap gap-4 text-sm">
				<li class="flex items-center gap-1">
					<Icon name="map-pin" class="size-6 text-brand" />
					<span>{props.courseName}</span>
				</li>
				<li class="flex items-center gap-1">
					<Icon name="flag" class="size-6 text-brand" />
					<span>{holesFormatter(numberFormatter(), props.holes)} holes</span>
				</li>
				<Show when={props.cartIncluded}>
					<li class="flex items-center gap-1">
						<Icon name="golf-cart" class="size-6 text-brand" />
						<span>Cart included</span>
					</li>
				</Show>
			</ul>
		</div>
	);
}
