import { createMemo, For, Show } from 'solid-js';
import { Icon } from '@troon/icons';
import { LinkButton } from './link';

type Props = {
	currentPage: number;
	lastPage: number;
	url: (page: number) => string;
	nextTitle?: string;
	prevTitle?: string;
};

export function Pagination(props: Props) {
	const data = createMemo(() => {
		const pages = generatePagination({ batch: 3, max: props.lastPage, current: props.currentPage });

		return {
			pages,
			previous: props.currentPage - 1 >= 1 ? props.currentPage - 1 : undefined,
			next: props.currentPage + 1 <= props.lastPage ? props.currentPage + 1 : undefined,
		};
	});

	return (
		<div class="flex w-full flex-wrap items-center justify-between gap-4">
			<div>
				<Show when={data()?.previous}>
					{(page) => (
						<LinkButton href={props.url(page())}>
							<Icon name="arrow-left-md" /> {props.prevTitle ?? 'Previous'}
						</LinkButton>
					)}
				</Show>
			</div>
			<ul class="flex grow flex-wrap items-center justify-center gap-2">
				<For each={data()?.pages}>
					{(pageNum) => (
						<Show
							when={typeof pageNum === 'number' && (pageNum as number)}
							fallback={
								<li class="px-6 text-neutral-700" aria-hidden>
									…
								</li>
							}
						>
							{(pageNum) => (
								<li>
									<LinkButton
										href={props.url(pageNum())}
										appearance="current"
										class="inline-flex min-w-8 hover:bg-brand-100 hover:text-brand-600 aria-current-page:bg-brand-100"
									>
										{pageNum()}
									</LinkButton>
								</li>
							)}
						</Show>
					)}
				</For>
			</ul>
			<div>
				<Show when={data()?.next}>
					{(page) => (
						<LinkButton href={props.url(page())}>
							{props.nextTitle ?? 'Next'} <Icon name="arrow-right-md" />
						</LinkButton>
					)}
				</Show>
			</div>
		</div>
	);
}

function generatePagination(opts: { batch: number; current: number; max: number }): Array<number | typeof sep> {
	const result: Array<number | typeof sep> = [...Array(Math.min(opts.batch, opts.max)).keys()].map((i) => i + 1);

	if (opts.max > opts.batch) {
		// Middle batch
		const middleStart = Math.max(opts.current - Math.floor(opts.batch / 2), opts.batch + 1);
		const middleEnd =
			opts.current <= opts.batch + Math.floor(opts.batch / 2)
				? opts.current + Math.floor(opts.batch / 2)
				: Math.min(middleStart + opts.batch - 1, opts.max - opts.batch);

		if (middleStart > opts.batch + 1) {
			result.push(sep);
		}

		for (let i = middleStart; i <= middleEnd; i++) {
			result.push(i);
		}

		if (middleEnd < opts.max - opts.batch) {
			result.push(sep);
		}

		// End batch
		for (let i = Math.max(opts.max - opts.batch + 1, middleEnd + 1); i <= opts.max; i++) {
			result.push(i);
		}
	}

	return result;
}

const sep = Symbol.for('sep');
