import { Dialog, DialogTrigger, LinkButton } from '@troon/ui';
import { Show, createSignal, onMount, createEffect } from 'solid-js';
import { useParams } from '@solidjs/router';
import { Icon } from '@troon/icons';
import { useTrackEvent } from '@troon/analytics';
import { TroonCardSubscriptionProductType } from '../../../../../../graphql';
import { AccessProductDialogContent } from '../../../../../../components/upsells/dialog-content';
import type { CourseTeeTimeRate } from '../../../../../../graphql';
import type { JSXElement } from 'solid-js';

type Props = {
	rate: CourseTeeTimeRate;
	discounts: Record<TroonCardSubscriptionProductType, string | null | undefined>;
};

const trackingDetails = { productType: 'troonAccess', location: 'tee time checkout payment info' };

export function AccessUpsellPayment(props: Props) {
	const params = useParams<{ facilityId: string; teeTimeId: string }>();
	const [cardUpsellOpen, setCardUpsellOpen] = createSignal(false);
	const trackEvent = useTrackEvent();

	onMount(() => {
		trackEvent('upsellVisible', trackingDetails);
	});

	createEffect(() => {
		if (cardUpsellOpen()) {
			trackEvent('upsellClicked', trackingDetails);
		}
	});

	return (
		<Show when={props.discounts[TroonCardSubscriptionProductType.TroonAccessPlus]}>
			{(discount) => (
				<Dialog key="access-products" open={cardUpsellOpen()} onOpenChange={setCardUpsellOpen}>
					<DialogTrigger
						as={LinkButton}
						size="sm"
						class="-ms-2 w-fit flex-nowrap items-start justify-start gap-1 text-start"
					>
						<Icon name="info" class="shrink-0" />
						<span>
							Save <b>{discount()}</b> on this tee time when you join{' '}
							<span class="whitespace-nowrap">{props.rate.name}</span>
						</span>
					</DialogTrigger>
					<AccessProductDialogContent
						onCancel={() => setCardUpsellOpen(false)}
						redirect={`/course/${params.facilityId}/reserve-tee-time/${params.teeTimeId}`}
						banners={Object.entries(props.discounts).reduce(
							(memo, [key, val]) => {
								if (!val) {
									return memo;
								}
								memo[key as TroonCardSubscriptionProductType] = (
									<>
										Save <b>{val}</b> on your tee time.
									</>
								);
								return memo;
							},
							{} as Record<TroonCardSubscriptionProductType, JSXElement>,
						)}
					/>
				</Dialog>
			)}
		</Show>
	);
}
